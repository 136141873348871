import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Platform} from '@ionic/angular';
import {DefaultUtil} from '../../utils/default.util';
import {EmitEventService} from "../../services/emit-event.service";

@Component({
  selector: 'app-custom-textarea',
  templateUrl: './custom-textarea.component.html',
  styleUrls: ['./custom-textarea.component.scss'],
})
export class CustomTextareaComponent implements OnInit {

  @Output()
  onChange = new EventEmitter();

  public text = '';

  @Input()
  placeholder = '';

  @Input()
  focusInput = false;

  @Input()
  fullBorderRadius = true;

  @Input()
  index = '';

  constructor( private emitEventService: EmitEventService) {}

  ngOnInit() {
    setTimeout(()=>{
      if (this.focusInput) {
        this.focus();
      }
      this.onSubmitTextArea();
    },200);
  }

  setOnChange(text) {
    this.onChange.emit(text);
  }

  onKeyUp(e) {
    this.placeholder = '';
    const text = e?.target?.innerText;
    const scrollHeight = e?.target?.scrollHeight;
    this.fullBorderRadius = !(scrollHeight > 19);
    this.placeholder = text ? '' : 'Add comment';
    this.setOnChange(text);
  }

  focus() {
    const textareaElement = document.getElementById(`textarea_component_${this.index}`);
    setTimeout(() => {
      textareaElement.focus();
    }, 0);
  }

  onSubmitTextArea() {
    this.emitEventService.getObservableSubmitComment()
      .subscribe(async (data) => {
        this.placeholder = 'Add comment';
        const textareaElement = document.getElementById(`textarea_component_${this.index}`);
        textareaElement.textContent = null;
      });
  }
}
