import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { environment } from 'src/environments/environment';
import 'firebase/auth';
import firebase from 'firebase/app';
import {ColorToast} from "../enums/color-toast";
import { DefaultUtil } from '../utils/default.util';
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient,
              private auth: AngularFireAuth,
              private defaultUtils:DefaultUtil) {}

  login(email, password) {
    return this.auth.signInWithEmailAndPassword(email, password);
  }

  verifyUserLogged() {
    return this.auth;
  }

  logout() {
    return this.auth.signOut();
  }

  resetPassword(email: string) {
    return this.auth.sendPasswordResetEmail(email);
  }

  getToken(uid) {
    return this.http
      .post(`${environment.cloudFunctionUrl}/user/create-custom-token`, {
        uid,
      })
      .toPromise();
  }

  validateToken(token) {
    return this.http
      .post(`${environment.cloudFunctionUrl}/user/verify-token`, {
        token,
      })
      .toPromise();
  }

  signWithCustomToken(token) {
    return this.auth.signInWithCustomToken(token);
  }

  signWithCredential(credential) {
    return this.auth.signInWithCredential(credential);
  }

  signWithPhoneNumber(phoneNumber,element, size): Promise<any> {
    return new Promise((resolve)=>{
      const recaptchaVerifier = new firebase.auth.RecaptchaVerifier(element, {
        size,
        callback: (response) => {
          console.log('callback>>>>>>>>>>>>>>>>>>',response);
        },
        'expired-callback': () => {
          console.log('expired-callback>>>>>>>>>>>>>>>>>>');
        }
      });

      firebase.auth().signInWithPhoneNumber(phoneNumber, recaptchaVerifier)
        .then((confirmationResult) =>{
          console.log('confirmationResult>>>>>>>>>>>>>>>>>>',confirmationResult);
          resolve({confirmationResult,recaptchaVerifier});
        }).catch((e)=> {
        this.defaultUtils.globalToast(
          e?.message,
          ColorToast.danger,
          5000
        );
        resolve(undefined);
      });
    });
  }

  linkWithPhoneNumber(phoneNumber, element): Promise<firebase.auth.ConfirmationResult | undefined>{
    return new Promise((resolve)=>{
      const recaptchaVerifier = new firebase.auth.RecaptchaVerifier(element, {
        size: 'normal',
        callback: (response) => {},
        'expired-callback': () => {
          recaptchaVerifier.clear();
          resolve(undefined);
        }
      });

      firebase.auth().currentUser.linkWithPhoneNumber(phoneNumber, recaptchaVerifier)
        .then((confirmationResult) =>{
          recaptchaVerifier.clear();
          resolve(confirmationResult);
        }).catch(()=> {
        resolve(undefined);
      });
    });

  }

  signInWithEmailLink(email,emailLink) {
    return firebase.auth().signInWithEmailLink(email,emailLink);
  }

}
