import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';

export interface Follow {
  followedById: string;
  ownerId: string;
  createdAt: any;
  id?: any;
}

@Injectable({
  providedIn: 'root',
})
export class FollowsService {

  collectionName = 'follows';

  constructor(private firestore: AngularFirestore) {}

  getExistFollow(id,followedById): any {
    return this.firestore.collection(this.collectionName).ref
      .where('ownerId', '==', id)
      .where('followedById', '==', followedById)
      .limit(1)
      .get();
  }

  getFollowers(id): any {
    return this.firestore.collection(this.collectionName).ref
      .where('ownerId', '==', id)
      .get();
  }

  getFollowings(id): any {
    return this.firestore.collection(this.collectionName).ref
      .where('followedById', '==', id)
      .get();
  }

  saveFollow(follow) {
    return this.firestore.collection(this.collectionName).add(follow);
  }

  deleteFollow(id) {
    return this.firestore.collection(this.collectionName).doc(id).delete();
  }

}
