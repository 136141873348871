import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class WalletService {

  collectionName = 'wallets';
  subCollectionName = 'transactions';

  constructor(private firestore: AngularFirestore, private http: HttpClient) {

  }

  getWalletByUser(userId) {
    return this.firestore.collection(this.collectionName).doc(userId).ref.get();
  }

  subscribeWallet(userId): Observable<any> {
    return Observable.create((observer) => {
      this.firestore
        .collection(this.collectionName)
        .doc(userId)
        .ref.onSnapshot((value: any) => {
        observer.next({ ...value.data(),});
      });
    });
  }

  async addWithdrawalTransaction(data, docId) {
    return this.firestore
      .collection(this.collectionName)
      .doc(docId)
      .collection(this.subCollectionName)
      .add(data);
  }


  async updateWallet(data, docId) {
    return this.firestore
      .collection(this.collectionName)
      .doc(docId)
      .update(data);
  }

  setDefaultWallet(userId) {
    return this.firestore.collection('wallets').doc(userId).set({
      winningBalance: 0,
      depositBalance: 0,
    });
  }


  createTransferTip(data: any): any {
    return new Promise((resolve) => {
      this.http
        .post(`${environment.cloudFunctionUrl}/payment/set/transfer`, data)
        // eslint-disable-next-line @typescript-eslint/no-shadow
        .subscribe((data) => resolve(data));
    });
  }
}
