<ion-content>
  <div class="container">
    <div class="header">Add Video
      <div></div>
      <div class="label" (click)="close()" >Close</div>
    </div>
    <div class="wrapper">
      <div class="ctr-button" *ngIf="!isNative">
        <div class="filter-option" (click)="modalCameraApp()">
          <div class="label-option">
            Record
          </div>
          <div class="label-option left">
            <ion-icon class="icon-check" name="videocam-outline"></ion-icon>
          </div>
        </div>
        <div class="warn-text" *ngIf="warnCamera">Camera may not be supported on your browser*</div>
        <div style="margin-top: 16px"></div>
        <div class="filter-option" (click)="getNativeFile()">
          <div class="label-option" style="width: 80%;">
            Upload
          </div>
          <div class="label-option left">
            <ion-icon class="icon-check" name="cloud-upload-outline"></ion-icon>
          </div>
        </div>
      </div>
      <div class="ctr-button" *ngIf="isNative">
        <div class="filter-option" (click)="getNativeCamera()">
<!--        <div class="filter-option" (click)="modalCameraApp()">-->
          <div class="label-option">
            Open Camera
          </div>
          <div class="label-option left">
            <ion-icon class="icon-check" name="videocam-outline"></ion-icon>
          </div>
        </div>
        <div style="margin-top: 16px"></div>
        <div class="filter-option" (click)="getNativeGallery()">
          <div class="label-option" style="width: 80%;">
            Open Gallery
          </div>
          <div class="label-option left">
            <ion-icon class="icon-check" name="image-outline"></ion-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</ion-content>
