import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-thumb-video-call',
  templateUrl: './thumb-video-call.component.html',
  styleUrls: ['./thumb-video-call.component.scss'],
})
export class ThumbVideoCallComponent implements OnInit {

  @Input()
  hasTopStatus: boolean;

  @Input()
  enableBlurText: boolean;

  @Input()
  sendBy: boolean;

  @Input()
  showButton: boolean;

  @Input()
  normalizedInfo: any;

  @Input()
  message: any;

  @Input()
  showConfirmMessage: boolean;

  @Input()
  showAmountChip: boolean;


  @Output()
  public executeAction = new EventEmitter();

  @Output()
  public onClickInternalLink = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  execute(action,data){
    this.executeAction.emit({action,data});
  }

}
