<div class="user-photo-background" [ngClass]="{'list-mode':adminList}"
     [style.background-image]="'url('+validBackground()+')'">
  <div class="container-icon-top" [ngClass]="{'ios':isIos}">
    <div>
      <ion-icon *ngIf="customBack" (click)="goBack()" class="icon" name="arrow-back-outline"></ion-icon>
    </div>
    <div class="flex-icon">
      <div class="ctr-icon relative" (click)="clickEditBackgroud()" *ngIf="isProfileOwner && enableEdit">
        <ion-icon class="icon2" name="pencil-sharp"></ion-icon>
      </div>
      <div class="ctr-icon" (click)="clickShareProfile()" *ngIf="enableShare">
        <ion-icon class="icon2" [name]="isIos ? 'share-outline':'share-social-outline'"
                  (click)="clickShareProfile()"></ion-icon>
      </div>
      <!--      <ion-icon *ngIf="isProfileOwner && enableEdit" (click)="clickEditBackgroud()" class="icon" name="pencil-sharp"></ion-icon>-->
      <!--      <ion-icon *ngIf="enableShare"  class="icon" [name]="isIos ? 'share-outline':'share-social-outline'" (click)="clickShareProfile()"></ion-icon>-->
    </div>
  </div>
</div>
<div class="wrapper list-mode" [ngClass]="{'list-mode':adminList}">
  <div class="container-photo">
    <app-profile-photo [border]="false"
                       [isVerified]="user?.isVerified"
                       [size]="{width: '96px', height: '96px'}"
                       [photoUrl]="user?.photoURL"
                       [userName]="user?.name"></app-profile-photo>
    <div class="container-edit">
      <div class="btn-raw" [class.follow]="!followInfo" [class.following]="followInfo" 
        *ngIf="!isLoadInfo && !isProfileOwner" (click)="clickFollow()">
         {{ followInfo ? 'Following' : 'Follow' }}
      </div>
      <div class="btn-raw" *ngIf="!isLoadInfo && isProfileOwner  && user?.isVerified"
           (click)="onCoachSettingsClick()">
        Edit Profile
      </div>
      <div class="btn-raw" *ngIf="!isLoadInfo && isProfileOwner && !user?.isVerified"
           (click)="clickEditProfile()">
        Edit Profile
      </div>
    </div>
  </div>
  <div class="name">
    <span>{{ this.user?.name }}</span>
    <div *ngIf="user?.isVerified" class="stamp"></div>
  </div>
  <div class="container-link" (click)="clickShareProfile()">
    <div class="user-title" *ngIf="user?.userTitle?.length > 0"> {{ user?.userTitle }}</div> 
  </div>
  <!-- <div class="container-link" (click)="clickShareProfile()">
    <div class="link">
      {{host}}/{{user?.name}}
    </div>
    <ion-icon class="icon-link" name="arrow-redo-outline"></ion-icon>
  </div> -->
  <div class="bio" *ngIf="textOptions?.largeText?.length > 0">
    {{textOptions?.largeText}}
  </div>
  <!-- <div class="bio" *ngIf="isProfileOwner && !(textOptions?.largeText?.length > 0)">
    Your bio - ✍️ Edit
  </div> -->
  <div class="grid-followers">
    <ion-row class="flex-row">
      <div class="container-followers" (click)="clickShareProfile()">
        <div class="followers">
          <span class="followers-count">{{quantityFollowers}}</span> <span class="followers-label"> Followers</span>
        </div>
      </div>
      <div class="ctr-stars" *ngIf="!isLoadInfo" (click)="clickAvaliations()">
        <app-star-review [mode]="'TEXT'" [avaliation]="rating?.valueAvaliation || 0"
                         [textAvaliation]="rating?.quantityAvaliation?.toString()"></app-star-review>
      </div>
    </ion-row>
  </div>
  <div class="ctr-stars" *ngIf="isLoadInfo">
    <ion-skeleton-text animated class="load shimmer"></ion-skeleton-text>
  </div>
</div>
<div class="ctn-button">
  <div class="profile-wrapper-btn">
    <ion-button class="button large" *ngIf="isProfileOwner && showButton" (click)="clickPostVideo()">
      <ion-label>Post Video</ion-label>
    </ion-button>

    <ion-button class="button large" *ngIf="!isProfileOwner && showButton" (click)="clickAsk()">
      <!-- <ion-label>Ask Me Anything</ion-label> -->
      <ion-label>Ask me a question</ion-label>
    </ion-button>
  </div>
</div>

