import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {
  ActionSheetController,
  IonContent,
  LoadingController,
  ModalController,
  NavController,
  PopoverController,
} from '@ionic/angular';
import {Tag} from 'src/app/models/tag';
import {User} from 'src/app/models/user';
import {FeedService} from 'src/app/services/feed.service';
import {TagService} from 'src/app/services/tag.service';
import {UserService} from 'src/app/services/user.service';
import {NormalizeDataUtil} from 'src/app/utils/normalize-data.util';
import {SendAskPage} from '../send-ask/send-ask.page';
import {DefaultUtil} from '../../utils/default.util';
import {RatingsService} from '../../services/ratings.service';
import {PreviousRouteService} from 'src/app/services/previous-route.service';
import {ReportService} from 'src/app/services/report.service';
import {StripeService} from '../../services/stripe.service';
import {ColorToast} from '../../enums/color-toast';
import {AnalyticsUtil} from '../../utils/analytics.util';
import {AnalyticsEvents} from '../../enums/events';
import {environment} from '../../../environments/environment';
import {PlatformUtil} from '../../utils/platform.util';
import {Feed} from '../../models/feed.model';
import {Follow, FollowsService} from '../../services/follows.service';
import {PathStorage} from '../../enums/path-storage';
import {MediaUtil} from '../../utils/media.util';
import {MediaService} from '../../services/media.service';
import {EmitEventService} from '../../services/emit-event.service';
import {ModalSendRequestSessionPage} from '../modal-send-request-session/modal-send-request-session.page';

declare let jwplayer;

@Component({
  selector: 'app-profile',
  templateUrl: './profile.page.html',
  styleUrls: ['./profile.page.scss'],
})
export class ProfilePage implements OnInit {

  @ViewChild('contentProfile', {static: false}) contentProfile: IonContent;

  userId: string;

  userLogged = {} as User;
  user = {} as User;
  feed: Feed[] = [];
  tags: Tag[] = [];
  isLoading = true;
  targets: any = [];
  chunkedtags: any = [];
  ratingValue = '0.0';
  ratingQuantity = 0;
  isBack = false;
  previousUrl;
  enableFade = false;
  openSendAskModal = false;
  openSendRequestModal = false;
  sendAskModalMessage = null;
  followInfo: Follow = null;
  isSafariDesktop = false;
  pagination = {start: 1, end: 10, limit: 10, enable: true};
  feedToPaginate: Feed[] = [];
  showFab = false;
  isMobile = false;
  hideHeader = true;
  hasValue: any;
  unSubscribeRequestModal: any;

  constructor(
    private actRouter: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private navCtrl: NavController,
    private feedService: FeedService,
    private tagService: TagService,
    private normalizeDataUtil: NormalizeDataUtil,
    private modalController: ModalController,
    public defaultUtils: DefaultUtil,
    public popoverController: PopoverController,
    public actionSheetController: ActionSheetController,
    public ratingsService: RatingsService,
    private previousRouteService: PreviousRouteService,
    private reportService: ReportService,
    private stripeService: StripeService,
    private analyticsUtil: AnalyticsUtil,
    private platformUtil: PlatformUtil,
    private followsService: FollowsService,
    private loadingCtrl: LoadingController,
    private mediaUtil: MediaUtil,
    private mediaService: MediaService,
    private emitEventService: EmitEventService,
  ) {
    this.isSafariDesktop = this.platformUtil.isSafariDesktop();
    this.isMobile = this.platformUtil.isMobile()
    this.actRouter.queryParams.subscribe((params) => {
      if (params && params.previousUrl) {
        this.previousUrl = params.previousUrl;
      }
      if (params?.open === 'send-ask') {
        this.openSendAskModal = true;
        this.sendAskModalMessage = params.message;
        if (this.platformUtil.isCordova()) {
          this.presentModal(this.sendAskModalMessage);
        }
        if (params?.value) {
          this.hasValue = params?.value;
        }
      }

      if (params?.open === 'send-request-session') {
        this.openSendRequestModal = true;
        if (this.platformUtil.isCordova()) {
          this.presentModalSendRequestSession();
        }
        if (params?.value) {
          this.hasValue = params?.value;
        }
      }
      if (params?.eventTrigger === 'roberta') {
        this.analyticsUtil.registerEvent(AnalyticsEvents.robertaPersonalLinkClicked, this.analyticsUtil.defaultAnalyticsContent());
      }

    });
  }

  async ngOnInit() {
    await this.defaultUtils.addJWPlayerScript();
    // this.defaultUtils.setScrollbarsContent('profile');
    this.userLogged = await this.defaultUtils.getUser();
    this.isLoading = true;
    if (this.userLogged) {
      await this.loadData();
      await this.onUpdateMyProfile();
    } else {
      await this.loadDataUnlogged();
    }
    this.isLoading = false;

    if (this.openSendAskModal) {
      this.presentModal(this.sendAskModalMessage);
    }
    if (this.openSendRequestModal) {
      this.presentModalSendRequestSession();
    }
    if (this.hasValue) {
      this.defaultUtils.globalToast(
        `$${this.hasValue} added to your account`,
        ColorToast.success,
        8000
      );
    }
  }


  onUpdateMyProfile() {
    this.emitEventService.getObservableUpdateMyprofile()
      .subscribe(async (data) => {
        const user = await this.defaultUtils.getUser();
        this.user.id = null;
        this.user.photoURL = null;
        await this.defaultUtils.timeout(100);
        this.user = user;
        this.tags = await this.loadTags(this.user.tags);
      });
  }

  ngOnDestroy() {
    this.defaultUtils.removeJWPlayerScript();
  }

  ionViewWillEnter() {
    this.isBack = false;
  }

  redirectToLogin() {
    if (this.previousRouteService?.getPreviousUrl() && !(this.openSendAskModal || this.openSendRequestModal)) {
      this.navCtrl.back();
      return;
    }
    this.router.navigate(['login/']);
  }

  async goBack() {
    if (this.userLogged?.id) {
      if (this.previousRouteService?.getPreviousUrl() && !(this.openSendAskModal || this.openSendRequestModal)) {
        this.navCtrl.back();
        return;
      }
      this.router.navigateByUrl('/tabs/home', {replaceUrl: true});
      return;
    }
    this.redirectToLogin();
  }

  goToReviewList() {
    this.router.navigate(['review-list'],{queryParams: {userId: this.user?.id}});
  }

  goToEditProfile() {
    this.router.navigate(['edit-profile']);
  }

  async loadData() {
    let params = this.defaultUtils.getLastElement(document.URL.split('/'));
    if (!params) {
      return;
    }
    params = params?.split('?')[0] || params;
    let user: any = await this.userService.getUserByUsername(decodeURI(params));
    user = user?.docs[0]?.data() || null;
    if (!user) {
      this.redirectToMain();
      return;
    }
    this.user = user;
    await this.getFollowInfo();
    this.defaultUtils.setTitlePage(`${this.user.name}'s profile | Truly App`);

    const [tags, feed] = await Promise.all([
      await this.loadTags(this.user.tags),
      await this.feedService.getPublicFeed(this.user.id, false),
    ]);
    this.tags = tags;
    this.tags = this.normalizeDataUtil.normalizeArrayData(this.tags);
    this.chunkedtags = this.chunkTags(this.tags);
    this.feedToPaginate = feed?.payload?.map(x => ({...x} as Feed)) || [];
    this.feedToPaginate = this.sortVideos(this.feedToPaginate).filter(a => a?.quantityReports === 0 || !a?.quantityReports);
    this.setLocalPagination();
    // await this.defaultUtils.timeout(1000);
  }

  chunkTags(tags) {
    let quantityGroup = 1;
    if (tags.length < 15) {
      quantityGroup = 1;
    } else if (tags.length < 40) {
      quantityGroup = 2;
    } else {
      quantityGroup = 3;
    }
    return this.defaultUtils.chunkArray(tags, quantityGroup);
  }

  async loadDataUnlogged() {
    try {
      let params = this.defaultUtils.getLastElement(document.URL.split('/'));
      if (!params) {
        return;
      }
      if (params?.includes('?')) {
        params = params?.split('?')[0] || params;
      }
      const user: any = await this.userService.getUserByEmailorUsername(params);
      // console.log('user>>>>>>>>>>>>',user)
      if (!user?.payload) {
        this.redirectToMain();
        return;
      }
      this.user = user?.payload;
      this.defaultUtils.setTitlePage(`${this.user.name}'s profile | Truly App`);
      const [tags, feed] = await Promise.all([
        await this.tagService.getTagsByArrayApi(this.user.tags),
        await this.feedService.getPublicFeed(this.user.id),
      ]);
      this.tags = tags?.payload || [];
      this.chunkedtags = this.chunkTags(this.tags);
      if (feed?.success) {
        this.feedToPaginate = feed?.payload?.map(x => ({...x} as Feed)) || [];
        this.feedToPaginate = this.sortVideos(this.feedToPaginate).filter(a => a?.quantityReports === 0 || !a?.quantityReports);
        this.setLocalPagination();
      }
      await this.defaultUtils.timeout(1000);
    } catch (error) {
    }
  }

  sortVideos(videos) {
    let pinnedVideos = videos;
    let allVideos = videos;
    allVideos = allVideos.filter(a => !a?.pinnedAt).map(a => {
      try {
        // eslint-disable-next-line no-underscore-dangle
        if (a?.createdAt._seconds && a.createdAt._nanoseconds) {
          // eslint-disable-next-line no-underscore-dangle
          a.createdAt = new Date(a.createdAt._seconds * 1000 + a.createdAt._nanoseconds / 1000000)?.getTime();
        } else {
          a.createdAt = new Date(a.createdAt?.toDate())?.getTime();
        }
        return a;
      } catch (e) {
        return a;
      }
    }).sort((a, b) => b.createdAt - a.createdAt);

    pinnedVideos = pinnedVideos.filter(a => a?.pinnedAt).map(a => {
      try {
        a.pinnedAt = new Date(a.pinnedAt?.toDate())?.getTime();
        return a;
      } catch (e) {
        return a;
      }
    }).sort((a, b) => b.pinnedAt - a.pinnedAt);
    return [...pinnedVideos, ...allVideos];
  }

  async presentOfflineModal() {
    const previous = this.previousUrl || '/tabs/home';

    let params = this.defaultUtils.getLastElement(document.URL.split('/'));
    if (!params) {
      return;
    }

    params = params.split('?')[0] || params;

    const modal = await this.modalController.create({
      component: SendAskPage,
      cssClass: 'small-modal',
      componentProps: {
        selectedUser: this.user,
        fromProfile: true,
        unloggedUser: true,
        redirectUrl: `/profile/${params}?open=send-request-session&previousUrl=${previous}`,

      },
      backdropDismiss: true,
    });
    return await modal.present();
  }

  async presentModal(message = null) {

    if (!this.userLogged?.id) {
      await this.presentOfflineModal();
      return;
    }
    this.userLogged = await this.defaultUtils.getUser();

    const previous = this.previousUrl || '/tabs/home';

    let params = this.defaultUtils.getLastElement(document.URL.split('/'));
    if (!params) {
      return;
    }

    params = params.split('?')[0] || params;

    if (this.userLogged) {
      if (this.userLogged.id !== this.user.id) {
        const modal = await this.modalController.create({
          component: SendAskPage,
          cssClass: 'small-modal',
          componentProps: {
            selectedUser: this.user,
            fromProfile: true,
            unloggedUser: false,
            question: message || '',
            redirectUrl: `/profile/${params}?open=send-ask&previousUrl=${previous}`,
          },
          backdropDismiss: true,
        });
        return await modal.present();
      }
    } else {
      window.location.href = 'https://jointruly.com/';
    }
  }

  async presentModalSendRequestSession(message = null) {
    if (!this.userLogged?.id) {
      return;
    }
    if (!this.user?.calendlyInfo?.organization) {
      const alertCalendlyInfo = {
        message: 'This coach has not integrated their calendar yet. Please ask them to complete this step.',
        buttons: [
          {
            text: 'OK',
            role: 'cancel',
          },
        ]
      };
      this.defaultUtils.alertDefault(alertCalendlyInfo);
      return;
    }


    this.userLogged = await this.defaultUtils.getUser();

    const previous = this.previousUrl || '/tabs/home';

    let params = this.defaultUtils.getLastElement(document.URL.split('/'));
    if (!params) {
      return;
    }

    params = params.split('?')[0] || params;

    if (this.userLogged) {
      if (this.userLogged.id !== this.user.id) {
        const modal = await this.modalController.create({
          component: ModalSendRequestSessionPage,
          cssClass: 'small-modal',
          componentProps: {
            selectedUser: this.user,
            fromProfile: true,
            unloggedUser: false,
            question: message || '',
            redirectUrl: `/profile/${params}?open=send-request-session&previousUrl=${previous}`,
          },
          backdropDismiss: true,
        });
        return await modal.present();
      }
    } else {
      window.location.href = 'https://jointruly.com/';
    }
  }

  redirectToMain() {
    window.location.href = window.location.origin;
  }

  loadTags(tags) {
    const tagsTemp = [];
    for (const tag of tags) {
      const tagData = this.tagService.getById(tag);
      tagsTemp.push(tagData);
    }
    return Promise.all(tagsTemp);
  }

  getThumbnailByVideo(hasThumb, url) {
    if (hasThumb) {
      return url.toString().replace('.mp4', '_thumb.jpg');
    } else {
      return url.toString().replace('.mp4', '.jpg');
    }
  }

  async timeout(time) {
    return new Promise((resolve) => setTimeout(() => resolve(true), time));
  }


  async shareProfile() {
    const defaultUrl = environment.type === 'dev' ? 'jointruly.app' : 'jointruly.com';
    const shareData = {
      text: 'Ask me on Truly!\n',
      url: `https://${defaultUrl}/${this.user?.name}`,
    };
    await this.defaultUtils.share(shareData, null, null, this.user);
  }

  async getIsBlock() {
    this.userLogged = await this.defaultUtils.getUser();
    return this?.userLogged?.blockedUsers?.some((a) => a === this.user.id);
  }

  showAlert() {
    window.location.href = window.location.origin;
  }

  setFade(enable) {
    this.enableFade = enable;
  };

  async getFollowInfo() {
    this.followInfo = await this.defaultUtils.validFollow(this.user?.id, this.userLogged?.id);
  };

  async actionFollow() {
    if (!this.userLogged?.id) {
      this.defaultUtils.modalInfoLogin();
      return;
    }
    const loading = await this.loadingCtrl.create({message: 'Loading...'});
    loading?.present();
    if (this.followInfo) {
      await this.followsService.deleteFollow(this.followInfo?.id);
    } else {
      await this.followsService.saveFollow({
        followedById: this.userLogged?.id,
        ownerId: this.user?.id,
        createdAt: this.defaultUtils.getDefaultDateFirebase()
      });
    }
    await this.getFollowInfo();
    this.defaultUtils.setUpdateHome();
    loading?.dismiss();

  };

  setLocalPagination() {
    const feed = this.feedToPaginate?.filter((a, index) =>
      index >= this.pagination.start - 1 &&
      index <= this.pagination.end - 1);

    this.feed = [...this.feed, ...feed];

    this.pagination.enable = (this.feed?.length < this.feedToPaginate?.length);
  }

  ionInfinite(event) {
    this.pagination.start += this.pagination.limit;
    this.pagination.end += this.pagination.limit;
    setTimeout(() => {
      event?.target.complete();
      this.setLocalPagination();
    }, 1000);
  }

  scrollToTop() {
    this.contentProfile.scrollToTop(500);
  }

  onScroll(event) {
    if (event?.detail?.scrollTop > 1000) {
      this.showFab = true;
    }else{
      this.showFab = false;
    }

    if (event?.detail?.scrollTop > 200) {
      this.hideHeader = false;
    }else{
      this.hideHeader = true;
    }
  }

  async editBackground() {
    let loading = null;
    let imageUrl = null;
    try {
      const image: any = await this.mediaUtil.cameraPicture();
      loading = await this.loadingCtrl.create({
        message: 'Saving image...',
      });
      loading.present();
      const user = await this.defaultUtils.getUser();
      imageUrl = await this.uploadImage(`${PathStorage.profilePics}/${user?.id}_background.jpg`, image.blob);
      await this.updateUser(user?.id, imageUrl);
      this.user = await this.defaultUtils.getUser();
    } catch (e) {
      await this.defaultUtils.globalToast(
        `An error has occured while uploading the photo:${e?.toString()}`,
        ColorToast.danger,
        5000,
      );
    } finally {
      loading?.dismiss();
    }
  }

  async uploadImage(name, file) {
    return await this.mediaService.uploadFileFirebase(name, file);
  }

  async updateUser(userId, photoBackground) {
    await this.userService.update(userId, {photoBackground});
  }

  modalGeneralSearch(tag) {
    this.emitEventService.publishTagsTrending({...tag,reset:true});
    this.router.navigate(['/trending'], {queryParams: {ref: tag.id}});
  }
}
