import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {ModalController, NavParams} from '@ionic/angular';
import {DefaultUtil} from '../../utils/default.util';
import {CameraPreview, CameraPreviewOptions} from '@awesome-cordova-plugins/camera-preview/ngx';
import {MediaUtil} from '../../utils/media.util';

type StepRecord = 'recordVideo' | 'choiceThumb' | 'confirmVideo';
type PlayPreviewStatus = 'play' | 'pause';

@Component({
  selector: 'app-native-camera',
  templateUrl: './native-camera.page.html',
  styleUrls: ['./native-camera.page.scss'],
})

export class NativeCameraPage implements OnInit {

  @Input()
  ask = '';

  @Input()
  duration = 180;

  @ViewChild('videoArea', {read: ElementRef}) videoArea: ElementRef;
  @ViewChild('canvas', {read: ElementRef}) canvas: ElementRef;
  isRecording = false;
  step: StepRecord = 'recordVideo';
  videoFile: File = null;
  playPreviewStatus: PlayPreviewStatus;
  originalDuration = 0;
  durationText = '';
  generalInterval: any;
  snapshotInterval: any;
  isLoad: boolean;
  openText: boolean;
  cameraFront = true;
  public thumbs: Array<any> = [];

  constructor(
    private modalCtrl: ModalController,
    private defaultUtil: DefaultUtil,
    private navParams: NavParams,
    private cameraPreview: CameraPreview,
    private mediaUtil: MediaUtil,
  ) {
    this.durationText = this.setCountDown(this.duration);
    this.originalDuration = this.duration;
  }

  ngOnInit() {
    this.isLoad = true;
    setTimeout(() => {
      this.startCamera();
    }, 500);
  }

  async validRecordPermission() {
    const firstAccessCam = this.defaultUtil.storage().get('firstAccessCam');
    if (!firstAccessCam) {
      this.defaultUtil.storage().set('firstAccessCam', true);
      await this.defaultUtil.timeout(400);
      await this.startRecording(true);
      await this.defaultUtil.timeout(400);
      await this.cameraPreview.stopRecordVideo();
    }
  }

  startCamera() {
    const elementTarget = this.videoArea.nativeElement.getBoundingClientRect();
    const cameraPreviewOpts: CameraPreviewOptions = {
      storeToFile: false,
      x: elementTarget?.x || 0,
      y: elementTarget?.y || 0,
      width: parseInt(elementTarget?.width, 10) || window.screen.width,
      height: parseInt(elementTarget?.height, 10) || window.screen.height,
      camera: this.cameraFront ? 'front' : 'rear',
      alpha: 1,
      previewDrag: true,
      tapPhoto: false,
    };
// start camera
    this.cameraPreview.startCamera(cameraPreviewOpts).then(
      (success) => {
        this.isLoad = false;
        this.validRecordPermission();
      },
      (err) => {
        console.log('err', err);
      });

  }

  stopCamera() {
    this.cameraPreview.stopCamera();
  }

  async startRecording(validation) {
    const options = {
      cameraDirection: this.cameraPreview.CAMERA_DIRECTION.FRONT,
      width: (window.screen.width / 2),
      height: (window.screen.height / 2),
      quality: 90,
      withFlash: false,
      storeToFile: true,
    };
    await this.cameraPreview.startRecordVideo(options);
    if (!validation) {
      this.timer();
      this.timerSnapshot(options);
    }
  }

  stopRecord() {
    this.isLoad = true;
    this.cameraPreview.stopRecordVideo().then(async (filePath) => {
      this.stopCamera();
      const file = await this.mediaUtil.getFileFromStorageSystem(`file://${filePath}`);
      if (file) {
        this.videoFile = file;
        this.videoArea.nativeElement.src = URL.createObjectURL(file);
        this.step = 'choiceThumb';
        this.playPreviewStatus = 'play';
        this.isLoad = false;
        clearInterval(this.generalInterval);
      }
    }).catch(error => {
      console.log('error stopRecord', error);
    });
  }

  actionRecord() {
    if (this.isRecording) {
      this.isRecording = false;
      this.stopRecord();
    } else {
      this.isRecording = true;
      this.startRecording(false);
    }
  }

  playPausePreview() {
    if (this.playPreviewStatus === 'play') {
      this.playPreviewStatus = 'pause';
      this.videoArea.nativeElement.play();
    } else if (this.playPreviewStatus === 'pause') {
      this.playPreviewStatus = 'play';
      this.videoArea.nativeElement.pause();
    }
  }

  timer() {
    let duration = 0;
    this.generalInterval = setInterval(() => {
      this.duration--;
      duration++;
      if (this.duration === 0 || !this.isRecording) {
        clearInterval(this.generalInterval);
        this.stopRecord();
      }
      this.durationText = this.setCountDown(this.duration);
    }, 1000);
  };

  timerSnapshot(options = null) {
    this.snapshotInterval = setInterval(() => {
      this.captureImageBySecond(options);
      if (!this.isRecording) {
        clearInterval(this.snapshotInterval);
      }
    }, 1500);
  };


  reTake() {
    this.playPreviewStatus = null;
    this.step = 'recordVideo';
    this.videoFile = null;
    this.videoArea.nativeElement.src = null;
    this.duration = this.originalDuration;
    this.durationText =  this.setCountDown(this.originalDuration);
    this.startCamera();
  }

  choiceThumb() {
    this.step = 'confirmVideo';
  }

  async setOpenText() {
    this.openText = !this.openText;
  }

  onBack() {
    this.stopCamera();
    this.modalCtrl.dismiss(null);
  }

  async confirmVideo() {
    const thumbSelected = this.thumbs.find(a => a.valid);
    const thumbBlob = await this.defaultUtil.base64ToBlob(thumbSelected.image, 'image/jpeg', 512);
    this.modalCtrl.dismiss({
      video: this.videoFile,
      image: thumbBlob,
    });
  }

  switchCamera() {
    this.stopCamera();
    this.cameraFront = !this.cameraFront;
    this.startCamera();
  }

  setThumb(thumb) {
    this.thumbs = this.thumbs.map(a => {
      a.valid = thumb.id === a.id;
      return a;
    });
    this.videoArea.nativeElement.setAttribute('poster', this.thumbs.find(a => a.valid).image);
  }

  async captureImageBySecond(options) {
    try {
      if (this.isRecording) {
        const filePath = await this.cameraPreview.takePicture({
          width: options?.width,
          height: options?.height,
          quality: options?.quality,
        });
        this.thumbs.push({
          valid: false,
          image: `data:image/jpeg;base64,${filePath}`,
          id: new Date().valueOf().toString()
        });
      }
    } catch (e) {
      console.log('captureImageBySecond error', e);
    }
  }
  setCountDown(duration) {
    const minutes = Math.floor(duration / 60);
    const seconds = duration % 60;
    return `${minutes}:${seconds < 10 ? 0 : ''}${seconds}`;
  }
}
