import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {User} from '../../models/user';
import {DefaultUtil} from '../../utils/default.util';
import {Comment} from '../../models/feed.model';
import {ButtonOption, OptionIconType} from '../../pages/modal-options-default/modal-options-default.page';

enum OptionModal {
  report = 'Report',
  delete = 'Delete',
  close = 'Close'
}

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
})
export class CommentComponent implements OnInit {

  @Input()
  userLogged: User = null;

  @Input()
  comment: Comment = null;

  @Input()
  postOwner = null;

  @Input()
  index = null;

  @Output()
  onClickProfilePhoto = new EventEmitter<any>();

  @Output()
  onClickOptions = new EventEmitter<any>();

  @Output()
  onDeleteComment = new EventEmitter<any>();

  @Output()
  onRepostComment = new EventEmitter<any>();

  showAllText = false;
  textDesc = 'more';
  textOptions = null;

  time = '';

  listButtonFilter: Array<ButtonOption>;

  constructor(private defaultUtils: DefaultUtil) {
  }

  ngOnInit() {
    this.textOptions = this.defaultUtils.validTextMore(this.comment?.text, 3, 18);
    this.time = this.defaultUtils.fromNow(this.comment.createdAt.toDate());
    this.mountButtonModal();
  }

  setTextMode() {
    if (this.showAllText) {
      this.textOptions.text = this.textOptions?.shortText;
      this.textDesc = 'more';
    } else {
      this.textDesc = 'less';
      this.textOptions.text = this.textOptions?.largeText;
    }
    if (!this.textOptions?.shortText) {
      this.textOptions.text = this.textOptions?.largeText;
    }
    this.showAllText = !this.showAllText;
  }

  clickProfilePhoto() {
    this.onClickProfilePhoto.emit(this.comment.user);
  }

  mountButtonModal() {
    this.listButtonFilter = [];
    if (this.postOwner) {
      this.listButtonFilter.push({
        text: OptionModal.delete,
        selected: false,
        icon: 'trash',
        disable: false,
        iconType: OptionIconType.ionicIcon,
      });
    }
  }

  async modalOptions() {
    try {
      const response = await this.defaultUtils.modalOptionsDefault({
        listButtons: this.listButtonFilter,
        titleCloseLabel: OptionModal.close,
        title: 'Options',
      });
      this.onSelectOption(response);
    } catch (e) {
      console.log('e', e);
    }
  }

  onSelectOption(response) {
    switch (response) {
      case OptionModal.delete:
        return this.alert('Are you sure?', () => this.onDeleteComment.emit({
          index: this.index,
          commentId: this.comment?.id
        }));
      case OptionModal.report:
        return this.reportComment();
      case OptionModal.close:
        return true;
    }
  }

  alert(message, action) {
    const alert = {
      message,
      buttons: [
        {
          text: 'NO',
        },
        {
          text: 'YES',
          handler: () => action()
        }
      ]
    };
    this.defaultUtils.alertDefault(alert);
  }

  reportComment() {
  }


}
