import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

@Component({
  selector: 'app-media-preview',
  templateUrl: './media-preview.component.html',
  styleUrls: ['./media-preview.component.scss'],
})
export class MediaPreviewComponent implements OnInit {

  @ViewChild('video', {read: ElementRef}) video: ElementRef;

  @Input()
  urlImage: any;

  @Input()
  urlVideo: any;

  @Input()
  urlFile: any;

  @Output()
  public onCancelMedia = new EventEmitter();

  @Output()
  public onClickPlayVideo = new EventEmitter();

  statusPlayPreview = 'pause';

  constructor() {
  }

  ngOnInit() {

  }

  cancel() {
    this.onCancelMedia.emit();
  }

  play(url) {
    this.onClickPlayVideo.emit(url);
  }


  playPausePreview() {
    try {
      if (this.statusPlayPreview === 'play') {
        this.statusPlayPreview = 'pause';
        this.video?.nativeElement?.pause();
      } else if (this.statusPlayPreview === 'pause') {
        this.statusPlayPreview = 'play';
        this.video?.nativeElement?.play();
      }
    } catch (e) {
      console.log('e', e);
    }
  }

}
