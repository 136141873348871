import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-thumb-tip',
  templateUrl: './thumb-tip.component.html',
  styleUrls: ['./thumb-tip.component.scss'],
})
export class ThumbTipComponent implements OnInit {


  @Input()
  sendBy: boolean;

  @Input()
  message: any;

  constructor() { }

  ngOnInit() {}

}
