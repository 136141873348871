import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StripeService {
  constructor(private http: HttpClient) {
  }

  getCheckoutSession(
    userId: string,
    productNumber: string,
    type: string,
    url: string
  ): any {
    return new Promise((resolve) => {
      this.http
        .post(`${environment.cloudFunctionUrl}/payment/session/create`, {
          userId,
          productNumber,
          type,
          url,
        })
        .subscribe(
          (data) => resolve(data),
          () => resolve(null)
        );
    });
  }

  getUnloggedCheckoutSession(
    userId: string,
    productNumber: string,
    type: string,
    url: string,
    ask: any
  ): any {
    return new Promise((resolve) => {
      this.http
        .post(`${environment.cloudFunctionUrl}/payment/session/unlogged/create`, {
          userId,
          productNumber,
          type,
          url,
          ask
        })
        .subscribe(
          (data) => resolve(data),
          () => resolve(null)
        );
    });
  }

  getPaymentSession(checkoutSessionId: string): any {
    return new Promise((resolve) => {
      this.http
        .post(`${environment.cloudFunctionUrl}/payment/session/retrieve`, {
           checkoutSessionId
        })
        .subscribe(
          (data) => resolve(data),
          () => resolve(null)
        );
    });
  }

  createRefund(paymentIntent: string): any {
    return new Promise((resolve) => {
      this.http
        .post(`${environment.cloudFunctionUrl}/payment/session/refund`, {
          paymentIntent
        })
        .subscribe(
          (data) => resolve(data),
          () => resolve(null)
        );
    });
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention
  getIdentitySession(user_id: string, url:string = ''): any {
    return new Promise((resolve) => {
      this.http
        .post(`${environment.cloudFunctionUrl}/stripe/get/sessionIdentity`, {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          user_id,
          url
        })
        .subscribe((data) => resolve(data));
    });
  }

  createNewPrice(prodId: string, amount: number): any {
    return new Promise((resolve) => {
      this.http
        .post(`${environment.cloudFunctionUrl}/stripe/price`, {
          prodId,
          amount,
        })
        .subscribe((data) => resolve(data));
    });
  }

  // async redirectToCheckout(checkoutId) {
  //   const stripeCore = await Stripe(environment.stripe.publicKey);
  //   return await stripeCore.redirectToCheckout({
  //     sessionId: checkoutId
  //   });
  // }

  async addBankAccount(userId, data) {
    return this.http
      .post(`${environment.cloudFunctionUrl}/stripe/bank-account`, {
        userId,
        data,
      })
      .toPromise();
  }

  async createExpressAccount(userId,countryCode) {
    return this.http
      .post(
        `${environment.cloudFunctionUrl}/stripe/create/stripe-express-account`,
        {
          userId,
          countryCode
        }
      )
      .toPromise();
  }

  async retrieveAccount(stripeAccountId) {
    return this.http
      .post(
        `${environment.cloudFunctionUrl}/stripe/stripe-retrieve-account`,
        {
          stripeAccountId
        }
      )
      .toPromise();
  }

  async retrieveAccountLink(stripeAccountId,url) {
    return this.http
      .post(
        `${environment.cloudFunctionUrl}/stripe/stripe-retrieve-account-link`,
        {
          stripeAccountId,
          url
        }
      )
      .toPromise();
  }

  async getStripeCountries() {
    return this.http
      .get(`${environment.cloudFunctionUrl}/stripe/countries`)
      .toPromise();
  }


}

