import { NgModule } from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ChatPageRoutingModule } from './chat-routing.module';

import { ChatPage } from './chat.page';
import {ComponentsModule} from "../../components/components.module";
import {ThumbAskComponent} from "../../components/ask/thumb-ask/thumb-ask.component";
import {ThumbAskVideoComponent} from "../../components/ask/thumb-ask-video/thumb-ask-video.component";
import {ThumbMessageComponent} from "../../components/ask/thumb-message/thumb-message.component";
import {ThumbMediaComponent} from "../../components/ask/thumb-media/thumb-media.component";
import {MediaPreviewComponent} from "../../components/ask/media-preview/media-preview.component";
import {ThumbUserWithUrlComponent} from "../../components/ask/thumb-user-with-url/thumb-user-with-url.component";
import {MyProfilePageModule} from "../my-profile/my-profile.module";
import {ProfilePageRoutingModule} from "../profile/profile-routing.module";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        ChatPageRoutingModule,
        ReactiveFormsModule,
        ComponentsModule,
        MyProfilePageModule,
        ProfilePageRoutingModule,
        NgOptimizedImage
    ],
    declarations: [ChatPage, ThumbAskComponent, ThumbAskVideoComponent, ThumbMessageComponent, ThumbMediaComponent, MediaPreviewComponent, ThumbUserWithUrlComponent]
})
export class ChatPageModule {}
