import {Injectable} from '@angular/core';
import { HTTP } from '@ionic-native/http/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import {PlatformUtil} from '../utils/platform.util';
import {FileOpener} from '@ionic-native/file-opener/ngx';
import {DefaultUtil} from '../utils/default.util';
import {ColorToast} from '../enums/color-toast';

@Injectable({
  providedIn: 'root',
})

export class HttpHelper {

  constructor(private nativeHTTP: HTTP,
              private file: File,
              private platoformUtil: PlatformUtil,
              private fileOpener: FileOpener,
              private defaultUtils: DefaultUtil) {}

   downloadFileAndStore(url,name,extension) {
    return new Promise((resolve)=>{
      const fileName =  `${name?.replaceAll(' ', '_')}.${extension}`;
      const filePath = this.platoformUtil.isAndroid() ?
        this.file.dataDirectory + fileName :
        this.file.documentsDirectory + fileName;

      this.nativeHTTP.downloadFile(url, {}, {}, filePath).then(response => {
        // prints 200
        console.log('success block...', response);
        this.fileOpener.open(response?.nativeURL, `application/${extension}`)
          .then(() =>   resolve(true))
          .catch(e => {
            console.log('err fileOpener.>>>>>>>>',e);
            this.defaultUtils.globalToast('Error opening file!', ColorToast.danger);
            resolve(false);
          });
      }).catch(err => {
        console.log('err downloadFile.>>>>>>>>',err);
        this.defaultUtils.globalToast('Error opening file!', ColorToast.danger);
        resolve(false);
      });
    });

  }
}
