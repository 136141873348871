import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NavController} from '@ionic/angular';
import {DefaultUtil} from '../../utils/default.util';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {PlatformUtil} from '../../utils/platform.util';
import {User} from '../../models/user';

@Component({
  selector: 'app-header-default',
  templateUrl: './header-default.component.html',
  styleUrls: ['./header-default.component.scss'],
})
export class HeaderDefaultComponent implements OnInit {
  @Input()
  public showIconShare: boolean;

  @Input()
  public showIconOptions: boolean;

  @Input()
  public colorIcon: string;

  @Input()
  public customBack: boolean;

  @Input()
  public showIconEdit: boolean;

  @Input()
  public showIcon: boolean;

  @Input()
  public showIconInfo: boolean;

  @Input()
  public showIconBack: boolean;

  @Input()
  public showContainerLeft: boolean;

  @Input()
  public showContainerRight: boolean;

  @Input()
  public user: User;

  @Output()
  public onBack = new EventEmitter();

  @Output()
  public onOptions = new EventEmitter();

  @Input()
  public showTitle: boolean;

  @Input()
  public showLabel: boolean;

  @Input()
  public removeFixed: boolean;

  @Input()
  public title: string;

  @Input()
  public grayButton: boolean;

  @Input()
  public hideAnimated: boolean = false;

  @Input()
  public headerHeight = '56px';

  @Input()
  public marginHeader = false;

  @Input()
  public sizeLogo = {
    width: 78,
    height: 36,
  };

  @Input()
  public backgroundColor: string;

  @Output()
  public onInfo = new EventEmitter();

  public isSafariDesktop: boolean;

  public isIos: boolean;

  public isCordovaIos: boolean;

  constructor(private navCtrl: NavController,
              private defaultUtils: DefaultUtil,
              private router: Router,
              private platformUtil: PlatformUtil,

  ) {
    this.showIconShare = false;
    this.showIconOptions = false;
    this.showContainerRight = true;
    this.showContainerLeft = true;
    this.colorIcon = '#fff';
    this.customBack = false;
    this.showIcon = false;
    this.showTitle = false;
    this.showIconInfo = false;
    this.showIconBack = true;
    this.title = '';
    this.showIconEdit = false;
    this.showLabel = false;
    this.removeFixed = false;
    this.isSafariDesktop = this.platformUtil.isSafariDesktop();
    this.isIos = this.platformUtil.isSafariDesktop() || this.platformUtil.isIos() || this.platformUtil.isCordovaIos();
    this.isCordovaIos = this.platformUtil.isCordovaIos();
  }


  ngOnInit() {
  }

  // async goBack() {
  //   this.navCtrl.back();
  // }
  async goBack() {
    if (this.customBack) {
      this.onCustomBack();
    } else {
      this.navCtrl.back();
    }
  }


  async navigateToSignUp() {
    const user = await this.defaultUtils.getUser();
      this.router.navigate([`signup`]);
  }

  async navigateToLogin() {
      this.router.navigate([`login`]);
  }

  onCustomBack() {
    this.onBack.emit();
  }

  clickOptions() {
    this.onOptions.emit();
  }

  clickInfo() {
    this.onInfo.emit();
  }

  async share() {
    let defaultUrl = window.location.href.split('/')[2];
    if(this.platformUtil.isCordova()){
      defaultUrl = environment.type === 'dev' ? 'jointruly.app' : 'jointruly.com' ;
    }
    const shareData = {
      text: 'Ask me on Truly!\n',
      url: `https://${defaultUrl}/${this.title}`,
    };
    this.defaultUtils.share(shareData, null, null, null);
  }
}
