import { Injectable } from '@angular/core';
import { AngularFirestore, DocumentReference } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root',
})
export class ValueOptionService {
  collectionName: string = 'valueOptions';

  constructor(private firestore: AngularFirestore) {}

  getAll() {
    return this.firestore.collection(this.collectionName).ref.get();
  }
}
