import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  collectionName: string = 'reports';

  constructor(private firestore: AngularFirestore) {}

  createReport(report) {
    return this.firestore.collection(this.collectionName).add(report);
  }

  updateReport(id, report) {
    return this.firestore.collection(this.collectionName).doc(id).update(report);
  }

  verifyExistsReport(proofId, userId) {
    return this.firestore
      .collection(this.collectionName)
      .ref.where('feed.id', '==', proofId)
      .where('reportedBy.id', '==', userId)
      .limit(1)
      .get();
  }

  getAllReport(proofId) {
    return this.firestore
      .collection(this.collectionName)
      .ref.where('feed.id', '==', proofId)
      .get();
  }
}
