<ion-content #contentSendAsk>
  <ion-grid id="grid" class="header-grid ion-align-items-center ion-align-self-center">
    <ion-row>
      <ion-col class="header-column flex">
        <app-profile-photo style="margin-right: 6px"  (focus)="onfocus()" fontSize="12px" [border]="true" [size]="{width: '82px', height: '82px'}" [isVerified]="selectedUser?.isVerified"
                           [photoUrl]="selectedUser?.photoURL" [userName]="selectedUser?.name"></app-profile-photo>
        <div class="modal-title">{{ selectedUser.name }}</div>
        <div class="modal-title2">{{ selectedUser.userTitle }}</div>
      </ion-col>
      <ion-col class="header-column ion-align-items-center ion-align-self-center" size="2">
        <ion-icon (click)="closeModal()" class="modal-close-button" name="close"></ion-icon>
      </ion-col>
    </ion-row>
  </ion-grid>

  <!-- <div lines="none" class="info-box">
    <div class="info-text">
      Get a video response in 24hrs. Ask away!
    </div>
  </div> -->

  <div lines="none" class="item-text-area " [ngClass]="{ 'highlight': enableFade && currentFade === 'fade_ask', 'no-min-height':isCordova}">
    <textarea #ask maxlength="280"
              [ngClass]="{ 'no-min-height':isCordova}"
              [(ngModel)]="question"
              style="color: black !important; padding-top: 4px;resize: none;"
              rows="4"
              (keypress)="checkKey($event)"
              placeholder="Get a video response in 24hrs. Ask away!"></textarea>
  </div>

  <div *ngIf="unloggedUser || externalAsk" lines="none" class="item-text-area container-email"
       [ngClass]="{ 'highlight': enableFade && currentFade === 'fade_email' }">
    <div class="label">No account? Deliver response to your inbox.</div>
    <ion-input [(ngModel)]="email" type="email" autocomplete="off"
               style="color: black !important; --padding-start: 5px !important;"
               placeholder="Your email..."></ion-input>
  </div>

  <!-- <ion-item lines="none" class="select-value" *ngIf="!unloggedUser">
    <ion-label style="margin-left: 0px" class="text-click" (click)="goToDeposit()" slot="start">
      Price: {{selectedUser?.priceAsk && selectedUser?.priceAsk?.value > 0 ? '$' + selectedUser?.priceAsk?.value : 'FREE'  }} </ion-label>
    <ion-label class="text-click" (click)="goToDeposit()" style="text-align: right;" slot="end">+Credits</ion-label>
  </ion-item>

  <div *ngIf="unloggedUser" class="offline-infos"
       [ngClass]="{ 'highlight': enableFade && currentFade === 'fade_price' }">
    <div class="container-price">
      Price: {{ selectedUser?.priceAsk && selectedUser?.priceAsk?.value > 0 ? '$' + selectedUser?.priceAsk?.value : 'FREE'  }}</div>
    <div class="container-price" style="cursor: pointer" (click)="goToSignup()">
      Signup for full experience
    </div>
  </div> -->

  <div>
    <app-slide-confirm-button [loading]="loading" [complete]="sended" (confirmEvent)="sendAsk()"
                              (onScroll)="removeFocus()">
    </app-slide-confirm-button>
  </div>

  <ion-item lines="none" class="select-value" *ngIf="!unloggedUser">
    <ion-label style="margin-left: 0px" class="text-click" (click)="goToDeposit()" slot="start">
      Price: {{selectedUser?.priceAsk && selectedUser?.priceAsk?.value > 0 ? '$' + selectedUser?.priceAsk?.value : 'FREE'  }} </ion-label>
    <ion-label class="text-click" (click)="goToDeposit()" style="text-align: right;" slot="end">Add Credits</ion-label>
  </ion-item>

  <div *ngIf="unloggedUser" class="offline-infos"
       [ngClass]="{ 'highlight': enableFade && currentFade === 'fade_price' }">
    <div class="container-price">
      Price: {{ selectedUser?.priceAsk && selectedUser?.priceAsk?.value > 0 ? '$' + selectedUser?.priceAsk?.value : 'FREE'  }}</div>
    <div class="container-price" style="cursor: pointer" (click)="goToSignup()">
      Signup for full experience
    </div>
  </div>

  <div class="block-bottom">
    <img width="50" src="../../../assets/images/stripe-logo.png" alt="Stripe Logo"/>
    <div>Powered by Stripe.</div>
  </div>

</ion-content>
