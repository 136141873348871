<div class="h-v__container">
  <div class="h-v__container-menu">
    <div class="h-v__container-menu__wrapper">
      <div class="h-v__container-menu__wrapper--logo" (click)="navigateToHome()"></div>
      <div *ngIf="showSideMenu">
        <div (click)="navigateToHome()">
          <div class="h-v__container-menu__wrapper--item" [ngClass]="{'enabled' : pageName === 'Home'}">
            <div class="h-v__container-menu__wrapper--item__container-icon">
              <svg class="h-v__container-menu__wrapper--item__container-icon--icon">
                <use [attr.xlink:href]="'assets/icon/home.svg#Layer_1'"></use>
              </svg>
            </div>
            <div class="h-v__container-menu__wrapper--item__text">Home</div>
          </div>
        </div>
        <div (click)="navigateToChatList()">
          <div class="h-v__container-menu__wrapper--item" [ngClass]="{'enabled' : pageName === 'Chat'}">
            <div class="h-v__container-menu__wrapper--item__container-icon">
              <svg class="h-v__container-menu__wrapper--item__container-icon--icon">
                <use [attr.xlink:href]="'assets/icon/sessions.svg#Layer_1'"></use>
              </svg>
            </div>
            <div class="h-v__container-menu__wrapper--item__text">Chat</div>
          </div>
        </div>
        <div (click)="navigateToMyProfile()">
          <div class="h-v__container-menu__wrapper--item" [ngClass]="{'enabled' : pageName === 'Profile'}">
            <div class="h-v__container-menu__wrapper--item__container-icon">
              <svg class="h-v__container-menu__wrapper--item__container-icon--icon">
                <use [attr.xlink:href]="'assets/icon/profile.svg#Layer_1'"></use>
              </svg>
            </div>
            <div class="h-v__container-menu__wrapper--item__text">Profile</div>
          </div>
        </div>
        <div (click)="navigateToVideoList()">
          <div class="h-v__container-menu__wrapper--item" [ngClass]="{'enabled' : pageName === 'Your Videos'}">
            <div class="h-v__container-menu__wrapper--item__container-icon">
              <svg class="h-v__container-menu__wrapper--item__container-icon--icon">
                <use [attr.xlink:href]="'assets/icon/videos.svg#Layer_1'"></use>
              </svg>
            </div>
            <div class="h-v__container-menu__wrapper--item__text">Videos</div>
          </div>
        </div>
        <div (click)="onCoachSettingsClick()">
          <div class="h-v__container-menu__wrapper--item" [ngClass]="{'enabled' : pageName === 'Coach Portal'}">
            <div class="h-v__container-menu__wrapper--item__container-icon">
              <svg class="h-v__container-menu__wrapper--item__container-icon--icon">
                <use [attr.xlink:href]="'assets/icon/coach-profile.svg#Layer_1'"></use>
              </svg>
            </div>
            <div class="h-v__container-menu__wrapper--item__text">Coach Portal</div>
          </div>
        </div>
        <div (click)="navigateToWithdraw()">
          <div class="h-v__container-menu__wrapper--item" [ngClass]="{'enabled' : pageName === 'Earnings'}">
            <div class="h-v__container-menu__wrapper--item__container-icon">
              <svg class="h-v__container-menu__wrapper--item__container-icon--icon">
                <use [attr.xlink:href]="'assets/icon/earnings.svg#Layer_1'"></use>
              </svg>
            </div>
            <div class="h-v__container-menu__wrapper--item__text">Earnings</div>
          </div>
        </div>
        <!-- <div (click)="navigateToMore()">
          <div class="h-v__container-menu__wrapper--item" [ngClass]="{'enabled' : pageName === 'More'}">
            <div class="h-v__container-menu__wrapper--item__container-icon">
              <svg class="h-v__container-menu__wrapper--item__container-icon--icon">
                <use [attr.xlink:href]="'assets/icon/edit.svg#Layer_1'"></use>
              </svg>
            </div>
            <div class="h-v__container-menu__wrapper--item__text">Settings</div>
          </div>
        </div> -->

        <div>
          <div class="h-v__container-menu__wrapper--item" (click)="shareProfile()">
            <div class="h-v__container-menu__wrapper--item__container-icon">
              <svg class="h-v__container-menu__wrapper--item__container-icon--icon">
                <use [attr.xlink:href]="'assets/icon/link.svg#Layer_1'"></use>
              </svg>
            </div>
            <div class="h-v__container-menu__wrapper--item__text">Get Your Link</div>
          </div>
          <div *ngIf="user?.id" class="h-v__container-menu__wrapper--sub-item" (click)="navigateToTerms()">
            <div class="h-v__container-menu__wrapper--sub-item__text-sub">FAQ</div>
          </div>
          <div class="h-v__container-menu__wrapper--sub-item" (click)="redirectToEmail()">
            <div class="h-v__container-menu__wrapper--sub-item__text-sub"> Contact</div>
          </div>
          <div *ngIf="user?.id" class="h-v__container-menu__wrapper--sub-item" (click)="actionLogout()">
            <div class="h-v__container-menu__wrapper--sub-item__text-sub">Logout</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="h-v__container-view">
    <div class="h-v__container-view__header" [ngClass]="{'margin':simpleHeader, 'hide':hideHeader}">
      <div [ngClass]="{'hide-all':simpleHeader}">
        <div class="h-v__container-view__header--ctn-input__logo"></div>
      </div>
      <div class="h-v__container-view__header--ctn-input" [ngClass]="{'hide-all':simpleHeader}">
        <div *ngIf="!simpleHeader" class="h-v__container-view__header--ctn-input__search-fake"
             (click)="onClickCateg(null)">
          <ion-icon class="h-v__container-view__header--ctn-input__search-fake--icon" name="search"
                    style="margin-right: 14px;"></ion-icon>
          <div class="h-v__container-view__header--ctn-input__search-fake--text">
            Find a coach
          </div>
        </div>
      </div>
      <div class="h-v__container-view__header--options" [ngClass]="{'fixed' : fixMenu}">
       <!-- //categories in home  -->
        <app-itens-menu-scroll
          *ngIf="!simpleHeader"
          [categories]="categories" (onClickCategory)="onClickCateg($event)"
        ></app-itens-menu-scroll>
        <div class="h-v__container-view__header-name" *ngIf="simpleHeader">
          <ion-icon *ngIf="backButtonMobile" (click)="goBack()" class="h-v__container-view__header-back"
                    name="arrow-back-outline"></ion-icon>
          {{pageName}}
        </div>
      </div>
    </div>
    <!--    <div class="h-v__container-view__header" *ngIf="simpleHeader">-->
    <!--      <div class="h-v__container-view__header&#45;&#45;ct-page-name">-->
    <!--        <ion-icon *ngIf="backButtonMobile" (click)="goBack()"  class="h-v__container-view__header&#45;&#45;icon-back" name="arrow-back-outline"></ion-icon>-->
    <!--        {{pageName}}-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="h-v__container-view__body" [ngClass]="{'chose-categ':hasCateg,
                      'no-border' :pageName === 'Profile' && hideHeader}">
      <ng-content></ng-content>
    </div>
  </div>

