<ion-content #modalOptionsBody>
  <div class="container">
    <div class="header">{{props?.title}}
      <div></div>
      <div class="label" (click)="close(props?.titleCloseLabel, true)">{{props?.titleCloseLabel}}</div>
    </div>
    <div class="wrapper">
      <div style="margin-top:26px"></div>
      <div class="ctr-button">
        <div *ngFor="let l of  props?.listButtons; let i = index"
             class="filter-option"
             (click)="close(l.text, l.disable)"
             #optionElement
             [ngClass]="{
             'top': i > 0,
             'enable':l?.selected,
             'disable': l?.disable
             }"
        >
          <div class="label-option">{{l.text}}</div>
          <div class="label-option right">
            <ion-icon class="icon-check" *ngIf="l?.iconType === 'ionicIcon'" [name]="l.icon"></ion-icon>
            <div class="icon-image" *ngIf="l?.iconType === 'image'"   [style.background-image]="'url('+l.icon+')'"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ion-content>
