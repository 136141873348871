<div class="post__container" *ngIf="this.userOwner">
  <div class="post__header" [ngClass]="{'post__no-border':removeFirstBorder}">
    <div class="post__header--container-user" (click)="clickProfile(feed?.user?.name)">
      <app-profile-photo fontSize="20px" [border]="false" [size]="{width: '40px', height: '40px'}"
                         [photoUrl]="feed?.user?.photo"
                         [userName]="feed?.user?.name"></app-profile-photo>
      <div class="post__header--container-user__title">
        <div style="display: flex;align-items: center;">
          <div class="post__header--container-user__title--title-user-name">{{feed?.user?.name}}</div>
          <div *ngIf="userOwner?.isVerified" class="post__header--container-user__title--title-stamp"></div>
        </div>
        <div class="post__header--container-user__title--time">{{time}}</div>
        <div class="post__flex_center" *ngIf="showPinnedLabel && feed?.pinnedAt">
          <div class="post__icon-pin"></div>
          <div class="post__header--container-user__title--time">
            Pinned video
          </div>
        </div>
      </div>
    </div>
    <ion-icon (click)="modalOptions()" *ngIf="isMyProfile" class="post__header--icon-header" name="create-outline"></ion-icon>
    <ion-icon (click)="modalOptions()" *ngIf="!isMyProfile" class="post__header--icon-header" name="ellipsis-horizontal"></ion-icon>
  </div>
  <ng-content></ng-content>
  <div class="post__title-desc post__padding" *ngIf="textOptions?.text?.length > 0">
    {{textOptions?.largeText}}
  </div>
  <!-- ****** NOTE: DELETED BELOW UNTIL PROPER FORMAT IS FIXED. ****** -->

  <!-- <div class="post__title-desc post__padding" *ngIf="textOptions?.text?.length > 0">
    {{textOptions?.text}}
    <span class="post__title-desc--more" *ngIf="textOptions?.shortText && textDesc === 'more'" (click)="setTextMode()">...{{textDesc}}
      </span>
    <span class="post__title-desc--more" *ngIf="textOptions?.largeText && textDesc === 'less'"
          (click)="setTextMode()">...{{textDesc}}</span>
  </div> -->
  <div class="post__button-post">
    <div class="post__button-post--info">
      <div class="post__button--text" style="">{{feed?.quantityLikes || 0}} Likes</div>
      <div class="post__button--dot"></div>
      <div class="post__button--text">{{ feed?.quantityComments || 0}} Comments</div>
    </div>
    <div class="post__button-post--separator-info"></div>
    <div class="post__button-post--info justify" [id]="customId" *ngIf="showActions">
      <div class="post__button-post--info--button-action" (click)="controlLike()">
        <svg class="post__button-post--info--button-action__icon" [ngClass]="{'animation-hand':enableAnimation, 'liked':likeId}">
          <use [attr.xlink:href]="'assets/icon/like_filled.svg#Layer_1'"></use>
        </svg>
        <div class="post__button-post--info--button-action__text-icon">Like</div>
      </div>
      <div class="post__button-post--info--button-action" (click)="setShowComment()">
        <svg class="post__button-post--info--button-action__icon">
          <use [attr.xlink:href]="'assets/icon/comment.svg#Layer_1'"></use>
        </svg>
        <div class="post__button-post--info--button-action__text-icon">Comments</div>
      </div>
      <div class="post__button-post--info--button-action" (click)="shareVideo()">
        <svg class="post__button-post--info--button-action__icon">
          <use [attr.xlink:href]="'assets/icon/send_post.svg#Layer_1'"></use>
        </svg>
        <div class="post__button-post--info--button-action__text-icon">Share</div>
      </div>
    </div>
  </div>
  <div class="post__container-comment" *ngIf="showComment && loggedUser?.id">
    <div class="post__container-comment--input-area">
      <app-profile-photo fontSize="20px"
                         style="margin-right: 10px"
                         [borderLightGray]="true"
                         [size]="{width: '40px', height: '40px'}"
                         [photoUrl]="loggedUser?.photoURL"
                         [userName]="loggedUser?.name"></app-profile-photo>
      <app-custom-textarea (onChange)="onChangeTextarea($event)"
                           [focusInput]="false"
                           [index]="this.index"
                           class="post__container-comment--input-area__size-textarea"
                           [placeholder]="'Add a comment'"></app-custom-textarea>
    </div>
    <div class="post__publsh-btn" (click)="addComment()" *ngIf="text">Publish</div>
    <div class="post__container-comment--comment-area">
      <div class="post__container-comment--comment-area--container-spinner" *ngIf="isLoadComments">
        <ion-spinner class="post__container-comment--comment-area--container-spinner__spinner"></ion-spinner>
      </div>
      <div *ngIf="!isLoadComments">
        <app-comment
          *ngFor="let c of comments;let i = index"
          [postOwner]="loggedUser?.id === feed?.user?.id"
          (onClickProfilePhoto)="navigateToProfile($event)"
          (onDeleteComment)="removeComment($event)"
          [index]="i"
          [comment]="c"
          [userLogged]="loggedUser"></app-comment>
        <div *ngIf="showMoreButton && this.feed.quantityComments > 3 " (click)="redirectToVideo()"
             class="post__container-comment--comment-area--load-more">Load more comments
        </div>
      </div>
    </div>
  </div>
</div>
