import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DefaultUtil} from '../../../utils/default.util';

@Component({
  selector: 'app-thumb-user-with-url',
  templateUrl: './thumb-user-with-url.component.html',
  styleUrls: ['./thumb-user-with-url.component.scss'],
})
export class ThumbUserWithUrlComponent implements OnInit {

  @Input()
  sendBy: boolean;

  @Input()
  message: any;

  @Output()
  public onClickInternalLink = new EventEmitter();

  constructor(public defaultUtils: DefaultUtil) {
  }

  ngOnInit() {
  }

  onClickLink(message) {
    if (message?.linkOptions?.userWithLink?.id) {
      this.onClickInternalLink.emit(message?.linkOptions?.userWithLink);
    }
  }

}
