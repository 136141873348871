import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Gesture, GestureController, ModalController, NavParams} from '@ionic/angular';
import {NavigationExtras, Router} from '@angular/router';
import {EmitEventService} from '../../services/emit-event.service';

@Component({
  selector: 'app-modal-push',
  templateUrl: './modal-push.page.html',
  styleUrls: ['./modal-push.page.scss'],
})
export class ModalPushPage implements OnInit {

  @ViewChild('dragElement', {read: ElementRef}) dragElement: ElementRef;

  public title: string;
  public message: string;
  public duration: number;
  public image: string;
  public id: string;
  public type: string;
  public time: any;
  public gesture: Gesture;


  constructor(private navParams: NavParams,
              private modalCtrl: ModalController,
              private gestureCtrl: GestureController,
              private router: Router,
              private emitService: EmitEventService
  ) {
    this.duration = this.navParams.get('duration');
    this.message = this.navParams.get('message');
    this.title = this.navParams.get('title');
    this.image = this.navParams.get('image');
    this.time = this.navParams.get('time');
    this.id = this.navParams.get('id');
    this.type = this.navParams.get('type');
  }

  ngOnInit() {
    this.dismissModal();
    setTimeout(() => {
      this.initDrag();
    }, 100);

  }

  initDrag() {
    this.gesture = this.gestureCtrl.create({
      el: this.dragElement.nativeElement,
      threshold: 1,
      gestureName: 'my-gesture',
      direction: 'y',
      onMove: ev => this.onMoveHandler(ev),
      onEnd: ev => this.onEnd(ev),

    }, true);
    this.gesture.enable();
  }

  onEnd(ev) {
    const direction: number = parseInt(ev.currentX);
    const acceptedLimit: number = (this.dragElement.nativeElement.clientHeight / 2) - 40;
    this.dragElement.nativeElement.style.transition = `all 0.3s`;
    if (direction > acceptedLimit) {
      this.dragElement.nativeElement.style.top = `-${direction * 2}px`;
      setTimeout(() => {
        this.close();
      }, 300);
      this.gesture.destroy();
    } else {
      this.dragElement.nativeElement.style.top = `0px`;
    }
  }

  onMoveHandler(ev) {
    const directionY: number = parseInt(ev.currentY) - parseInt(ev.startY);
    this.dragElement.nativeElement.style.top = `${directionY}px`;

    // const directionX: number = parseInt(ev.currentX) - parseInt(ev.startX);
    // this.dragElement.nativeElement.style.left = `${directionX}px`;

    this.dragElement.nativeElement.style.transition = `all 0s`;
  }

  dismissModal() {
    setTimeout(() => this.close(), this.duration);
  }

  close() {
    this.emitService.pusblishCloseModal(null);
  }

  navigateToChat(id) {
    const navigationExtras: NavigationExtras = {
      queryParams: {
        id,
      },
    };
    this.router.navigate(['chat'], navigationExtras);
  }

  navigateToWatch(id) {
    this.router.navigateByUrl(`watch?ref=${id}`);
  }
  onAction() {
    switch (this.type) {
      case 'chat':
        this.close();
        this.navigateToChat(this.id);
        break;
      case 'feed':
        this.close();
        this.navigateToWatch(this.id);
        break;
      default:
        this.close();
    }

  }
}
