import {Injectable} from '@angular/core';
import * as joi from 'joi';
import {DefaultUtil} from './default.util';

@Injectable({
  providedIn: 'root',
})
export class ValidatesUtil {

  constructor(private defaultUtils: DefaultUtil) {

  }

  validateEmailRegex(email) {
    // eslint-disable-next-line max-len
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
  }

  validateUserNameRegex(userName: string) {
    const validateUser = joi.string().required().alphanum().min(6).max(17);
    return validateUser.validate(userName);
  }

  validatePassword(password: string) {
    const validatePass = joi.string().required().min(6).pattern(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d\D]{6,}$/);
    return validatePass.validate(password);
  }

  validatePhoneNumber(number) {
    // eslint-disable-next-line max-len
    const regex = /([0-9\s\-]{7,})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;
    return regex.test(number);
  }


  maskOnlyLetters(value) {
    return value.replace(/[^a-zA-Z]+/g, '');
  }

  maskOnlyLettersNumbers(value) {
    return value?.replace(/\W+/g, '');
  }

  onlyNumbers(value) {
    const regex = /^\d+$/;
    return regex?.test(value);
  }

  validUrlRegex(url) {
    if (url.indexOf('...') !== -1 || url.indexOf('..') !== -1 || url.indexOf(' ') !== -1) {
      return false;
    }
    const res = url.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
    return (res !== null);
  }

  validDomainRegex(url) {
    if (url) {
      return url.match(/com|app|org|net|int|edu|gov|dev|apple|amazon|fit/g);
    }
    return false;
  }

  validInternalLinkProfile(url, defaultUtils) {
    const baseUrl = window.location.host;
    // console.log('window.location.host;',window.location.host);
    // const baseUrl = 'jointruly.app';
    if (url.match(baseUrl)) {
      url = url?.split('/');
      if (url?.length > 1) {
        const param = defaultUtils.getLastElement(url);
        if (param !== baseUrl) {
          return param;
        }
      }
      return null;
    }
  }

}
