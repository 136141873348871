import { Component, OnInit } from '@angular/core';
import {ModalController} from '@ionic/angular';
import {OptionIconType} from '../modal-options-default/modal-options-default.page';
import {PlatformUtil} from '../../utils/platform.util';

@Component({
  selector: 'app-modal-offline',
  templateUrl: './modal-offline.page.html',
  styleUrls: ['./modal-offline.page.scss'],
})

export class ModalOfflinePage implements OnInit {

  isLoading = false;

  constructor( private modalController: ModalController,
                private platformUtil: PlatformUtil ){}

  ngOnInit() {
  }

  clickLink() {
    this.isLoading = true;
    setTimeout(()=>{
      if(navigator.onLine){
        location.reload();
        this.modalController.dismiss();
      }
      this.isLoading = false;
    },500);
  }

  protected readonly OptionIconType = OptionIconType;
}
