import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';


@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
})
export class IconButtonComponent  implements OnInit {

  @Input()
  text = '';

  @Input()
  color = '';

  @Input()
  icon = '';

  @Output()
  private onClickButton = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {}

  clickButton(){
    this.onClickButton.emit(this.text);
  }

}
