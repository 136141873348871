import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {
  AngularFireAuthGuard,
  redirectLoggedInTo,
  redirectUnauthorizedTo,
} from '@angular/fire/auth-guard';
import {AuthGuard} from './auth.guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);
const redirectLoggedInToHome = () => redirectLoggedInTo(['tabs/home']);

export enum Pages {
  tabs = 'tabs',
  home = 'home',
  searchUsers = 'search-users',
  editProfile = 'edit-profile',
  editProfilePrice = 'edit-profile-price',
  editProfilePriceAsk = 'edit-profile-price-ask',
  editProfileTags = 'edit-profile-tags',
  editProfileTitle = 'edit-profile-title',
  proofs = 'proofs',
  profile = 'profile',
  sendAsk = 'send-ask',
  modalPaidAnimation = 'modal-paid-animation',
  customAmount = 'custom-amount',
  login = 'login',
  forgotPassword = 'forgot-password',
  chat = 'chat',
  addBankAccount = 'add-bank-account',
  camera = 'camera',
  trending = 'trending',
  terms = 'terms',
  deposit = 'deposit',
  withdraw = 'withdraw',
  player = 'player',
  myProfile = 'my-profile',
  onboarding = 'onboarding',
  signup = 'signup',
  modalInviteCode = 'modal-invite-code',
  modalContest = 'modal-contest',
  modalPush = 'modal-push',
  onboardingProfile = 'onboarding-profile',
  onboardingEditProfile = 'onboarding-edit-profile',
  modalInfoOnboarding = 'modal-info-onboarding',
  modalFilterChat = 'modal-filter-chat',
  confirmUnloggedAsk = 'confirm-unlogged-ask',
  modalChatOptions = 'modal-chat-options',
  photoViewer = 'photo-viewer',
  modalUploadProfileOptions = 'modal-upload-profile-options',
  nativeCamera = 'native-camera',
  watch = 'watch',
  watchList = 'watch-list',
  videoList = 'video-list',
  coachSettings = 'coach-settings',
  editVideo = 'edit-video',
  modalProfileOptions = ' modal-profile-options',
  chatList = 'chat-list',
  modalOptionsDefault = 'modal-options-default',
  modalGeneralSearch = 'modal-general-search',
  more = 'more',
  feedAdmin = 'feed-admin',
  videoCall = 'video-call',
  modalSendMoney = 'modal-send-money',
  modalSignUp = 'modal-signup',
  modalSendRequestSession = 'modal-send-request-session',
  resetPassword = 'reset-password',
  reviewList = 'review-list'
}

export const routes: Routes = [
  {
    path: '',
    //redirectTo: 'tabs/trending', //trending is now home
    redirectTo: 'tabs/home', //trending is now home
    pathMatch: 'full',
  },
  {
    path: Pages.tabs,
    loadChildren: () => import('./pages/tabs/tabs.module').then((m) => m.TabsPageModule),
    // canActivate: [AngularFireAuthGuard, AuthGuard],
    // data: {authGuardPipe: redirectUnauthorizedToLogin, preload: true},
  },
  {
    path: Pages.home,
    loadChildren: () => import('./pages/home/home.module').then((m) => m.HomePageModule),
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: {authGuardPipe: redirectUnauthorizedToLogin},
  },
  {
    path: Pages.chatList,
    loadChildren: () => import('./pages/chat-list/chat-list.module').then((m) => m.ChatListModule),
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: {authGuardPipe: redirectUnauthorizedToLogin},
  },
  {
    path: Pages.trending,
    loadChildren: () => import('./pages/trending/trending.module').then((m) => m.TrendingPageModule),
    // canActivate: [AngularFireAuthGuard, AuthGuard],
    // data: {authGuardPipe: redirectUnauthorizedToLogin},
  },
  {
    path: Pages.searchUsers,
    loadChildren: () =>
      import('./pages/search-users/search-users.module').then(
        (m) => m.SearchUsersPageModule
      ),
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: {authGuardPipe: redirectUnauthorizedToLogin},
  },
  {
    path: Pages.editProfile,
    loadChildren: () =>
      import('./pages/edit-profile/edit-profile.module').then(
        (m) => m.EditProfilePageModule
      ),
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: {authGuardPipe: redirectUnauthorizedToLogin, preload: true},
  },
  {
    path: Pages.editProfilePrice,
    loadChildren: () =>
      import('./pages/edit-profile-price/edit-profile-price.module').then(
        (m) => m.EditProfilePricePageModule
      ),
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: {authGuardPipe: redirectUnauthorizedToLogin, preload: true},
  },
  {
    path: Pages.editProfilePriceAsk,
    loadChildren: () =>
      import('./pages/edit-profile-price-ask/edit-profile-price-ask.module').then(
        (m) => m.EditProfilePriceAskPageModule
      ),
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: {authGuardPipe: redirectUnauthorizedToLogin, preload: true},
  },
  {
    path: Pages.editProfileTags,
    loadChildren: () =>
      import('./pages/edit-profile-tags/edit-profile-tags.module').then(
        (m) => m.EditProfileTagsPageModule
      ),
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: {authGuardPipe: redirectUnauthorizedToLogin, preload: true},
  },
  {
    path: Pages.editProfileTitle,
    loadChildren: () =>
      import('./pages/edit-profile-title/edit-profile-title.module').then(
        (m) => m.EditProfileTitlePageModule
      ),
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: {authGuardPipe: redirectUnauthorizedToLogin, preload: true},
  },
  {
    path: Pages.proofs,
    loadChildren: () =>
      import('./pages/proofs/proofs.module').then((m) => m.ProofsPageModule),
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: {authGuardPipe: redirectUnauthorizedToLogin},
  },
  {
    path: `${Pages.profile}/:name`,
    loadChildren: () => import('./pages/profile/profile.module').then((m) => m.ProfilePageModule),
    data: {noCache: true },
  },
  {
    path: Pages.sendAsk,
    loadChildren: () =>
      import('./pages/send-ask/send-ask.module').then(
        (m) => m.SendAskPageModule
      ),
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: {authGuardPipe: redirectUnauthorizedToLogin, preload: true},
  },
  {
    path: Pages.modalPaidAnimation,
    loadChildren: () =>
      import('./pages/modal-paid/modal-paid-animation.module').then(
        (m) => m.ModalPaidAnimationPageModule
      ),
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: {authGuardPipe: redirectUnauthorizedToLogin, preload: true},
  },
  {
    path: Pages.customAmount,
    loadChildren: () =>
      import('./pages/custom-amount/custom-amount.module').then(
        (m) => m.CustomAmountPageModule
      ),
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: {authGuardPipe: redirectUnauthorizedToLogin},
  },
  {
    path: Pages.login,
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: Pages.forgotPassword,
    loadChildren: () =>
      import('./pages/forgot-password/forgot-password.module').then(
        (m) => m.ForgotPasswordPageModule
      ),
  },
  {
    path: Pages.chat,
    loadChildren: () =>
      import('./pages/chat/chat.module').then((m) => m.ChatPageModule),
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: {authGuardPipe: redirectUnauthorizedToLogin, preload: true},
  },
  {
    path: Pages.addBankAccount,
    loadChildren: () =>
      import('./pages/add-bank/add-bank.module').then((m) => m.AddBankPageModule),
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: {authGuardPipe: redirectUnauthorizedToLogin, preload: true},
  },
  {
    path: Pages.camera,
    loadChildren: () =>
      import('./pages/camera/camera.module').then((m) => m.CameraPageModule),
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: {authGuardPipe: redirectUnauthorizedToLogin},
  },
  {
    path: Pages.terms,
    loadChildren: () =>
      import('./pages/terms/terms.module').then((m) => m.TermsPageModule),
  },
  {
    path: Pages.deposit,
    loadChildren: () =>
      import('./pages/deposit/deposit.module').then((m) => m.DepositPageModule),
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: {authGuardPipe: redirectUnauthorizedToLogin},
  },
  {
    path: Pages.withdraw,
    loadChildren: () =>
      import('./pages/withdraw/withdraw.module').then(
        (m) => m.WithdrawPageModule
      ),
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: {authGuardPipe: redirectUnauthorizedToLogin},
  },
  {
    path: Pages.player,
    loadChildren: () =>
      import('./pages/player/player.module').then((m) => m.PlayerPageModule),
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: {authGuardPipe: redirectUnauthorizedToLogin},
  },
  {
    path: Pages.myProfile,
    loadChildren: () =>
      import('./pages/my-profile/my-profile.module').then(
        (m) => m.MyProfilePageModule
      ),
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: {authGuardPipe: redirectUnauthorizedToLogin},
  },
  {
    path: Pages.onboarding,
    loadChildren: () =>
      import('./pages/onboarding/onboarding.module').then(
        (m) => m.OnboardingPageModule
      ),
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: {authGuardPipe: redirectUnauthorizedToLogin},
  },
  {
    path: Pages.signup,
    loadChildren: () =>
      import('./pages/signup/signup.module').then((m) => m.SignupPageModule),
  },
  {
    path: Pages.modalInviteCode,
    loadChildren: () =>
      import('./pages/modal-invite-code/modal-invite-code.module').then(
        (m) => m.ModalInviteCodePageModule
      ),
  },
  {
    path: Pages.modalContest,
    loadChildren: () =>
      import('./pages/modal-contest/modal-contest.module').then(
        (m) => m.ModalContestPageModule
      ),
  },
  {
    path: Pages.modalPush,
    loadChildren: () =>
      import('./pages/modal-push/modal-push.module').then(
        (m) => m.ModalPushPageModule
      ),
  },
  {
    path: Pages.onboardingProfile,
    loadChildren: () =>
      import('./pages/onboarding-profile/onboarding-profile.module').then(
        (m) => m.OnboardingProfilePageModule
      ),
  },
  {
    path: Pages.onboardingEditProfile,
    loadChildren: () =>
      import(
        './pages/onboarding-edit-profile/onboarding-edit-profile.module'
        ).then((m) => m.OnboardingEditProfilePageModule),
  },
  {
    path: Pages.modalInfoOnboarding,
    loadChildren: () =>
      import('./pages/modal-info-onboarding/modal-info-onboarding.module').then(
        (m) => m.ModalInfoOnboardingPageModule
      ),
  },
  {
    path: Pages.confirmUnloggedAsk,
    loadChildren: () => import('./pages/confirm-unlogged-ask/confirm-unlogged-ask.module').then(m => m.ConfirmUnloggedAskPageModule)
  },
  {
    path: Pages.modalChatOptions,
    loadChildren: () => import('./pages/modal-chat-options/modal-chat-options.module').then(m => m.ModalChatOptionsPageModule)
  },
  {
    path: Pages.photoViewer,
    loadChildren: () => import('./pages/photo-viewer/photo-viewer.module').then(m => m.PhotoViewerPageModule)
  },
  {
    path: Pages.modalUploadProfileOptions,
    loadChildren: () =>
      import('./pages/modal-upload-profile-options/modal-upload-profile-options.module').then(m => m.ModalUploadProfileOptionsPageModule)
  },
  {
    path: Pages.nativeCamera,
    loadChildren: () => import('./pages/native-camera/native-camera.module').then(m => m.NativeCameraPageModule)
  },
  {
    path: Pages.watch,
    loadChildren: () => import('./pages/watch/watch.module').then(m => m.WatchPageModule)
  },
  {
    path: Pages.watchList,
    loadChildren: () => import('./pages/watch-list/watch-list.module').then(m => m.WatchListPageModule)
  },
  {
    path: Pages.modalProfileOptions,
    loadChildren: () => import('./pages/modal-profile-options/modal-profile-options.module').then(m => m.ModalProfileOptionsPageModule)
  },
  {
    path: Pages.videoList,
    loadChildren: () => import('./pages/video-list/video-list.module').then(m => m.VideoListPageModule),
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: {authGuardPipe: redirectUnauthorizedToLogin},
  },
  {
    path: Pages.coachSettings,
    loadChildren: () => import('./pages/coach-settings/coach-settings.module').then(m => m.CoachSettingsPageModule),
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: {authGuardPipe: redirectUnauthorizedToLogin},
  },
  {
    path: Pages.editVideo,
    loadChildren: () => import('./pages/edit-video/edit-video.module').then(m => m.EditVideoPageModule),
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: {authGuardPipe: redirectUnauthorizedToLogin},

  },
  {
    path: Pages.modalOptionsDefault,
    loadChildren: () => import('./pages/modal-options-default/modal-options-default.module').then(m => m.ModalOptionsDefaultPageModule),
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: {authGuardPipe: redirectUnauthorizedToLogin},
  },
  {
    path: Pages.modalGeneralSearch,
    loadChildren: () => import('./pages/modal-general-search/modal-general-search.module').then(m => m.ModalGeneralSearchPageModule),
    // canActivate: [AngularFireAuthGuard, AuthGuard],
    // data: {authGuardPipe: redirectUnauthorizedToLogin},
  },
  {
    path: Pages.more,
    loadChildren: () => import('./pages/more/more.module').then(m => m.MorePageModule),
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: {authGuardPipe: redirectUnauthorizedToLogin},
  },
  {
    path: Pages.feedAdmin,
    loadChildren: () => import('./pages/feed-admin/feed-admin.module').then(m => m.FeedAdminPageModule),
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: {authGuardPipe: redirectUnauthorizedToLogin},
  },
  {
    path: Pages.videoCall,
    loadChildren: () => import('./pages/video-call/video-call.module').then(m => m.VideoCallPageModule),
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: {authGuardPipe: redirectUnauthorizedToLogin},
  },
  {
    path: Pages.modalSendMoney,
    loadChildren: () => import('./pages/modal-send-money/modal-send-money.module').then(m => m.ModalSendMoneyPageModule),
    canActivate: [AngularFireAuthGuard, AuthGuard],
    data: {authGuardPipe: redirectUnauthorizedToLogin},
  },
  {
    path: Pages.modalSignUp,
    loadChildren: () => import('./pages/modal-signup/modal-signup.module').then( m => m.ModalSignupPageModule)
  },
  {
    path: Pages.modalSendRequestSession,
    loadChildren: () =>
      import('./pages/modal-send-request-session/modal-send-request-session.module').then( m => m.ModalSendRequestSessionPageModule)
  },
  {
    path: Pages.resetPassword,
    loadChildren: () => import('./pages/reset-password/reset-password.module').then(m => m.ResetPasswordModule)
  },
  {
    path: Pages.reviewList,
    loadChildren: () => import('./pages/review-list/review-list.module').then( m => m.ReviewListPageModule),
  },
  {
    path: 'modal-offline',
    loadChildren: () => import('./pages/modal-offline/modal-offline.module').then( m => m.ModalOfflinePageModule)
  },
  {
    path: 'more-settings',
    loadChildren: () => import('./pages/more-settings/more-settings.module').then( m => m.MoreSettingsPageModule)
  },
  {
    path: 'more-account',
    loadChildren: () => import('./pages/more-account/more-account.module').then( m => m.MoreAccountPageModule)
  },
  {
    path: 'link-phone-number',
    loadChildren: () => import('./pages/link-phone-number/link-phone-number.module').then( m => m.LinkPhoneNumberPageModule)
  },
  {
    path: 'valid-recaptcha',
    loadChildren: () => import('./pages/valid-recaptcha/valid-recaptcha.module').then( m => m.ValidRecaptchaPageModule)
  },
  {
    path: 'login-code',
    loadChildren: () => import('./pages/login-code/login-code.module').then( m => m.LoginCodePageModule)
  },
  {
    path: 'modal-input-email',
    loadChildren: () => import('./pages/modal-input-email/modal-input-email.module').then( m => m.ModalInputEmailPageModule)
  },
  {
    path: 'modal-info-login',
    loadChildren: () => import('./pages/modal-info-login/modal-info-login.module').then( m => m.ModalInfoLoginPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
