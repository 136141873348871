<ion-content>
  <app-header-default [customBack]="true" (onBack)="goBack()"  [colorIcon]="'#333'" [showIconOptions]="false"></app-header-default>
  <ion-grid>
    <ion-row>
      <ion-col class="text-center">
        <ion-label>Type Custom Amount</ion-label>
        <br />
        <div class="container-elements">
          <div class="wrapper">
            <img  style="margin-left: 50px;" src="assets/icon/currency.svg">
            <input   type="tel"  maxlength="3"  class="remove input-number" [(ngModel)]="value" >
          </div>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>

  <div class="wrapper-btn" >
    <div class="ctr-button">
      <ion-button class="button" (click)="onConfirmValue()">
        <ion-label style="color: #fff" >Confirm</ion-label>
      </ion-button>
    </div>
  </div>
</ion-content>
