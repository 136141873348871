import {Component, EventEmitter, HostListener, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {User} from '../../models/user';
import {Avaliation, DefaultUtil} from '../../utils/default.util';
import {PlatformUtil} from '../../utils/platform.util';
import {NavController} from '@ionic/angular';
import {FollowsService} from '../../services/follows.service';
import {ChatService} from '../../services/chat.service';
import {MessageService} from '../../services/message.service';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import { EmitEventService } from 'src/app/services/emit-event.service';

@Component({
  selector: 'app-profile-info',
  templateUrl: './profile-info.component.html',
  styleUrls: ['./profile-info.component.scss'],
})
export class ProfileInfoComponent implements OnInit {

  showAllText = false;
  textDesc = 'more';
  textOptions = null;

  isIos = false;
  isLoadInfo = true;
  quantityFollowers = 0;
  host = '';

  rating: Avaliation;

  showButton  = true;


  @Input()
  public user: User;

  @Input()
  public isProfileOwner: boolean;

  @Input()
  public enableEdit = true;

  @Input()
  public enableShare;

  @Input()
  customBack = false;

  @Input()
  followInfo = null;

  @Input()
  adminList = false;

  @Output()
  private onclickEditBackgroud = new EventEmitter<any>();

  @Output()
  private onClickShare = new EventEmitter<any>();

  @Output()
  private onClickSetupPrice = new EventEmitter<any>();

  @Output()
  private onClickSetupPriceAsk = new EventEmitter<any>();

  @Output()
  private onClickPostVideo = new EventEmitter<any>();

  @Output()
  private onClickEditProfile = new EventEmitter<any>();

  @Output()
  private onClickAsk = new EventEmitter<any>();

  @Output()
  private onClickBook = new EventEmitter<any>();

  @Output()
  private onClickFollow = new EventEmitter<any>();

  @Output()
  private onClickVerify = new EventEmitter<any>();

  @Output()
  private onClickBack = new EventEmitter<any>();

  @Output()
  private onClickAvaliations = new EventEmitter<any>();


  constructor(private defaultUtils: DefaultUtil,
              private platformUtil: PlatformUtil,
              private navCtrl: NavController,
              private chatService: ChatService,
              private followService: FollowsService,
              private messageService: MessageService,
              private router: Router,
              private emitEventService: EmitEventService
  ) {
    this.isIos = (this.platformUtil?.isIos() || this.platformUtil?.isCordovaIos());
  }

  ngOnInit() {
    console.log('this user>>>>>>>>>',this.user)
    this.host = environment.type === 'dev' ? 'jointruly.app' : 'jointruly.com';
    // this.host = this.platformUtil.isCordovaIos() ? '' : window.location.hostname;
    const maxLetters = this.platformUtil.isDesktop() ? 28 : 22;
    this.textOptions = this.defaultUtils.validTextMore(this.user?.bio, 3, maxLetters);
    this.getAditionalInfo();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.user && changes.user.currentValue) {
      const maxLetters = this.platformUtil.isDesktop() ? 28 : 22;
      this.textOptions = this.defaultUtils.validTextMore(this.user?.bio, 3, maxLetters);
    }
  }

  onUpdateReview() {
    this.emitEventService.getUpdateReviewProfile()
      .subscribe(async (data) => {
        await this.getRatings();
      });
  }

  setBioMode() {
    if (this.showAllText) {
      this.textOptions.text = this.textOptions?.shortText;
    } else {
      this.textDesc = '';
      this.textOptions.text = this.textOptions?.largeText;
    }
    if (!this.textOptions?.shortText) {
      this.textOptions.text = this.textOptions?.largeText;
    }
    this.showAllText = !this.showAllText;
  }

  async getFollowers() {
    this.quantityFollowers = (await this.followService.getFollowers(this.user?.id))?.size || 0;
  }

  formatPrice() {
    const price = this.user?.price?.value;
    if (price) {
      return `$${price}`;
    }
    return 'FREE';
  }

  formatPriceAsk() {
    const priceAsk = this.user?.priceAsk?.value;
    if (priceAsk) {
      return `$${priceAsk}`;
    }
    return 'FREE';
  }

  async getAditionalInfo() {
    await this.getFollowers();
    await this.getRatings();
    this.isLoadInfo = false;
    if(this.isProfileOwner){
      this.onUpdateReview()
    }
  }

  async getRatings() {
    try {
      this.rating = await this.defaultUtils.getInfoRating(this.user.id);
    } catch {}
  }

  async onCoachSettingsClick() {
    if (this.user?.isVerified) {
      this.router.navigate(['/coach-settings']);
    }
  }

  async validAvaliationFromBot() {
    let chatsReference: any = (await this.chatService.getByUserId(this.user?.id)).docs.map(a => ({id: a.id, ...a?.data() as any}));
    chatsReference = chatsReference?.find(a => a?.chatId.match('admin'));
    return (await this.messageService.getSuccessAsk(chatsReference?.chatId, this.user?.id)).size;
  }

  // async scriptSeassions() {
  //   const users: any = await (await this.userService.getAll())?.docs?.map(a=>a?.data());
  //   for(const u of users ){
  //     const sessions = await this.getCompletedChats(u?.id);
  //     // await this.userService.update(u?.id, {sessions});
  //     console.log(`sessions of ${u?.name} >>>>>>>>`, sessions);
  //   }
  // }

  async getCompletedChats(userId) {
    let numberOfSessions = 0;
    const chats: any = await (await this.chatService.getByUserId(userId))?.docs?.map(a => a?.data());
    for (const c of chats) {
      const result = await (await this.messageService.getSuccessAsk(c?.chatId, userId))?.size;
      numberOfSessions += result;
    }
    return numberOfSessions;
  }

  clickEditBackgroud() {
    this.onclickEditBackgroud.emit();
  }

  clickPostVideo() {
    this.onClickPostVideo.emit();
  }

  clickShareProfile() {
    this.onClickShare.emit();
  }

  clickEditProfile() {
    this.onClickEditProfile.emit();
  }

  clickFollow() {
    this.onClickFollow.emit();
  }

  clickAsk() {
    this.onClickAsk.emit();
  }

  clickBook() {
    this.onClickBook.emit();
  }

  goBack() {
    if (this.customBack) {
      this.onClickBack.emit(true);
      return;
    }
    this.navCtrl.back();
  }

  clickVerify() {
    this.onClickVerify.emit();
  }

  validBackground() {
    return this.user?.photoBackground;
  }

  navigateToProfile() {
    if (this.adminList) {
      this.router.navigate(['/profile/' + this.user.name]);
    }
  }

  clickAvaliations() {
    this.onClickAvaliations.emit();
  }

  @HostListener('window:focusin', ['$event'])
  onFocus() {
    if(this.platformUtil.isMobile()){
      // this.showButton = false;
    }
  }

  @HostListener('window:focusout', ['$event'])
  onBlur() {
    if(this.platformUtil.isMobile()){
      // this.showButton = true;
    }
  }



}
