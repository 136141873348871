import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  collectionName = 'messagesTest';
  subCollectionName = 'chatMessages';

  constructor(private firestore: AngularFirestore) {
  }

  getActionDare(chatId) {
    return this.firestore
      .collection(this.collectionName)
      .doc(chatId)
      .collection(this.subCollectionName)
      .ref.where('type', '==', 'ask')
      .where('dare.status', 'in', ['sent', 'accepted', 'contested', 'confirm'])
      .orderBy('updatedAt', 'desc')
      .limit(1)
      .get();
  }

  getActionDareSendTo(chatId, userId) {
    return this.firestore
      .collection(this.collectionName)
      .doc(chatId)
      .collection(this.subCollectionName)
      .ref.where('type', '==', 'ask')
      .where('dare.status', 'in', ['sent', 'accepted'])
      .where('sendTo.id', '==', userId)
      .orderBy('updatedAt', 'desc')
      .limit(1)
      .get();
  }

  getActionDareSendBy(chatId, userId) {
    return this.firestore
      .collection(this.collectionName)
      .doc(chatId)
      .collection(this.subCollectionName)
      .ref.where('type', '==', 'ask')
      .where('dare.status', 'in', ['contested', 'confirm'])
      .where('sendBy.id', '==', userId)
      .orderBy('updatedAt', 'desc')
      .limit(1)
      .get();
  }

  getLastMessageByChatId(chatId: string) {
    return (
      this.firestore
        .collection(this.collectionName)
        .doc(chatId)
        .collection(this.subCollectionName)
        // .ref.where('type', '==', 'ask')
        .ref.orderBy('updatedAt', 'desc')
        .limit(1)
        .get()
    );
  }

  getUnreadMessagesByChatId(chatId: string, sendTo: string) {
    return this.firestore
      .collection(this.collectionName)
      .doc(chatId)
      .collection(this.subCollectionName)
      .ref.where('sendTo.id', '==', sendTo)
      .where('readed', '==', false)
      .get();
  }

  save(chatId: string, data: any) {
    return this.firestore
      .collection(this.collectionName)
      .doc(chatId)
      .collection(this.subCollectionName)
      .add(data);
  }

  saveWithId(chatId: string, data: any) {
    return this.firestore
      .collection(this.collectionName)
      .doc(chatId)
      .collection(this.subCollectionName)
      .doc(data?.messageId)
      .set(data);
  }

  subscribeChat(chatId): Observable<any[]> {
    return Observable.create((observer) => {
      this.firestore
        .collection(this.collectionName)
        .doc(chatId)
        .collection(this.subCollectionName)
        .ref.limit(30)
        .orderBy('updatedAt', 'desc')
        .onSnapshot((values) => {
          const result = [];

          for (const value of values.docChanges()) {
            if (['added', 'modified'].some((a) => a == value.type)) {
              const object = {
                id: value.doc.id,
                ...value.doc.data(),
              };
              result.push(object);
            }
          }

          observer.next(result);
        });
    });
  }

  subscribeTyping(chatId): Observable<any> {
    return Observable.create((observer) => {
      this.firestore
        .collection(this.collectionName)
        .doc(chatId)
        .ref.onSnapshot((value) => {
        observer.next(value.data());
      });
    });
  }

  updateMessage(chatId: string, id: string, data: any) {
    return this.firestore
      .collection(this.collectionName)
      .doc(chatId)
      .collection(this.subCollectionName)
      .doc(id)
      .update(data);
  }

  updateTyping(chatId: string, data: any) {
    return this.firestore
      .collection(this.collectionName)
      .doc(chatId)
      .update(data);
  }

  getMessagesByChatId(chatId: string) {
    return this.firestore
      .collection(this.collectionName)
      .doc(chatId)
      .collection(this.subCollectionName)
      .ref.orderBy('updatedAt', 'asc')
      .limit(50)
      .get();
  }

  getMessagesByChatId2(chatId: string, limit: number, lastVisible: any) {
    if (lastVisible) {
      return this.messagesWithPagination(chatId, limit, lastVisible);
    } else {
      return this.messages(chatId, limit);
    }
  }

  messages(chatId: string, limit: number) {
    return this.firestore
      .collection(this.collectionName)
      .doc(chatId)
      .collection(this.subCollectionName)
      .ref.orderBy('updatedAt', 'desc')
      .get();
  }

  messagesWithPagination(chatId: string, limit: number, lastVisible: any) {
    return this.firestore
      .collection(this.collectionName)
      .doc(chatId)
      .collection(this.subCollectionName)
      .ref.orderBy('updatedAt', 'desc')
      .startAfter(lastVisible)
      .limit(limit)
      .get();
  }

  messagesByMediaUrl(chatId: string, url: any) {
    return this.firestore
      .collection(this.collectionName)
      .doc(chatId)
      .collection(this.subCollectionName)
      .ref.where('dare.dareMedia.videoUrl', '==', url)
      .get();
  }

  searchMessages(chatId, text) {
    return this.firestore
      .collection(this.collectionName)
      .doc(chatId)
      .collection(this.subCollectionName)
      .ref
      .where('text', '>=', text.toLowerCase())
      .where('text', '<=', text.toLowerCase() + '\uf8ff')
      .limit(10)
      .get();
  }

  getOnlyMessages(chatId: string) {
    return this.firestore
      .collection(this.collectionName)
      .doc(chatId)
      .collection(this.subCollectionName)
      .ref
      .where('text', '!=', '')
      .get();
  }

  getSuccessAsk(chatId: string,userId: string) {
    return this.firestore
      .collection(this.collectionName)
      .doc(chatId)
      .collection(this.subCollectionName)
      .ref
      .where('dare.status', 'in', ['confirmed', 'autoconfirmed', 'trialconfirmwon'])
      .where('sendTo.id', '==', userId)
      .get();
  }

}
