import { CommonModule } from '@angular/common';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { HeaderComponent } from './header/header.component';
import { PopoverComponent } from './popover/popover.component';
import { SearchTagComponent } from './search-tag/search-tag.component';
import { InputTagsComponent } from './input-tags/input-tags.component';
import { HeaderCameraComponent } from './header-camera/header-camera.component';
import { ProgressVideoBarComponent } from './progress-video-bar/progress-video-bar.component';
import { HeaderDefaultComponent } from './header-default/header-default.component';
import { SearchUserComponent } from './search-user/search-user.component';
import {EmptyStatusComponent} from './empty-status/empty-status.component';
import {StarRatingComponent} from './star-rating/star-rating.component';
import {CropPictureComponent} from './crop-picture/crop-picture.component';
import {ProfileTagsComponent} from './profile-tags/profile-tags.component';
import {LoadVideoProfileComponent} from './load-video-profile/load-video-profile.component';
import {ThumbUserComponent} from './thumb-user/thumb-user.component';
import {ThumbVideoComponent} from './watch/thumb-video/thumb-video.component';
import {TimeVideoComponent} from './time-video/time-video.component';
import {SlideSectionComponent} from './slide-section/slide-section.component';
import {StarReviewComponent} from './star-review/star-review.component';
import {UserChatMessagesComponent} from './user-chat-messages/user-chat-messages.component';
import {ResultUserEmptyComponent} from './result-user-empty/result-user-empty.component';
import {EmptyMessageContentComponent} from './empty-message-content/empty-message-content.component';
import {StoriesListComponent} from './stories-list/stories-list.component';
import {SectionComponent} from './section/section.component';
import {ProfilePhotoComponent} from './profile-photo/profile-photo.component';
import {PostComponent} from './post/post.component';
import {CustomTextareaComponent} from './custom-textarea/custom-textarea.component';
import {CommentComponent} from './comment/comment.component';
import {VideoComponent} from './video/video.component';
import {FabToTopComponent} from './fab-to-top/fab-to-top.component';
import {UserDetailTrendingComponent} from './user-detail-trending/user-detail-trending.component';
import {ThumbVideoCallComponent} from "./ask/thumb-video-call/thumb-video-call.component";
import {ThumbTipComponent} from "./ask/thumb-tip/thumb-tip.component";
import {BackgroundUsersAnimationComponent} from "./background-users-animation/background-users-animation.component";
import {CropBackgroundComponent} from "./crop-background/crop-background.component";
import {ProfileMoreInfoComponent} from "./profile-more-info/profile-more-info.component";
import {IconButtonComponent} from "./icon-button/icon-button.component";
import {HomeViewComponent} from "./home-view/home-view.component";
import {ItemsMenuScrollComponent} from "./menu/items-menu-scroll/items-menu-scroll.component";
import {ItemMenuComponent} from "./item-menu/item-menu.component";
import {ReviewUserComponent} from "./review-user/review-user.component";

@NgModule({
  declarations: [
    HeaderComponent,
    SearchTagComponent,
    SearchUserComponent,
    PopoverComponent,
    InputTagsComponent,
    HeaderCameraComponent,
    ProgressVideoBarComponent,
    HeaderDefaultComponent,
    EmptyStatusComponent,
    StarRatingComponent,
    CropPictureComponent,
    ProfileTagsComponent,
    LoadVideoProfileComponent,
    ThumbUserComponent,
    ThumbVideoComponent,
    TimeVideoComponent,
    SlideSectionComponent,
    StarReviewComponent,
    UserChatMessagesComponent,
    ResultUserEmptyComponent,
    EmptyMessageContentComponent,
    StoriesListComponent,
    SectionComponent,
    ProfilePhotoComponent,
    PostComponent,
    CustomTextareaComponent,
    CommentComponent,
    VideoComponent,
    FabToTopComponent,
    UserDetailTrendingComponent,
    ThumbVideoCallComponent,
    ThumbTipComponent,
    BackgroundUsersAnimationComponent,
    CropBackgroundComponent,
    ProfileMoreInfoComponent,
    IconButtonComponent,
    HomeViewComponent,
    ItemsMenuScrollComponent,
    ItemMenuComponent,
    ReviewUserComponent
  ],
  imports: [FormsModule, IonicModule, CommonModule],
  exports: [
    HeaderComponent,
    SearchTagComponent,
    SearchUserComponent,
    InputTagsComponent,
    HeaderCameraComponent,
    ProgressVideoBarComponent,
    HeaderDefaultComponent,
    EmptyStatusComponent,
    StarRatingComponent,
    CropPictureComponent,
    ProfileTagsComponent,
    LoadVideoProfileComponent,
    ThumbUserComponent,
    ThumbVideoComponent,
    TimeVideoComponent,
    SlideSectionComponent,
    StarReviewComponent,
    UserChatMessagesComponent,
    ResultUserEmptyComponent,
    EmptyMessageContentComponent,
    StoriesListComponent,
    SectionComponent,
    ProfilePhotoComponent,
    PostComponent,
    VideoComponent,
    FabToTopComponent,
    UserDetailTrendingComponent,
    ThumbVideoCallComponent,
    ThumbTipComponent,
    BackgroundUsersAnimationComponent,
    CropBackgroundComponent,
    ProfileMoreInfoComponent,
    IconButtonComponent,
    HomeViewComponent,
    ItemsMenuScrollComponent,
    ItemMenuComponent,
    ReviewUserComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class ComponentsModule { }
