import {Injectable} from '@angular/core';
import {Platform} from '@ionic/angular';


@Injectable({
  providedIn: 'root',
})
export class PlatformUtil {

  constructor(private plr: Platform,) {
  }

  isMobile() {
    return this.plr.is('ios') || this.plr.is('android');
  }

  isIos() {
    return this.plr.is('ios');
  }

  isAndroid() {
    return this.plr.is('android');
  }

  isDesktop() {
    return this.plr.is('desktop');
  }

  isWeb() {
    return this.plr.is('desktop') || this.plr.is('mobileweb');
  }

  isCordova() {
    return this.plr.is('cordova');
  }

  isCordovaAndroid() {
    return this.plr.is('cordova') && this.plr.is('android');
  }

  isCordovaIos() {
    return this.plr.is('cordova') && this.plr.is('ios');
  }

  isPwa() {
    return this.plr.is('pwa');
  }

  isSafariDesktop() {
    if (!this.isMobile()) {
      const win: any = window;
      return win?.safari !== undefined;
    }
    return false;
  }

  getPlatform() {
    if (this.isCordovaAndroid()) {
      return `native android`;
    } else if (this.isCordovaIos()) {
      return `native ios`;
    } else if (this.isAndroid()) {
      return `android`;
    } else if (this.isIos()) {
      return `ios`;
    } else if (this.isDesktop()) {
      return `desktop`;
    }
  }

  getIosVersion() {
    if (/iP(hone|od|ad)/.test(navigator.platform)) {
      const version = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
      return parseInt(version[1], 10);
    }
    return [0];
  }

  getDeviceSize() {
    return {height: this.plr.height(), width: this.plr.width()};
  }
}
