<ion-content>
  <div class="main-wrapper">
    <div class="container">
      <ion-icon (click)="close()"  class="icon" name="close-outline"></ion-icon>
      <div class="wrapper">
        <div class="title">
          Please Log In
        </div>
        <div class="subTitle">
          To continue, you need to be logged<br/> in to your account.
        </div>
        <div class="ctr-button">
          <ion-button (click)="navigateToLogin()" class="button secundary-color">
            <ion-label style="font-size: 22px;padding: 0px 24px;">
              Log In
            </ion-label>
          </ion-button>
        </div>
      </div>
    </div>
  </div>
</ion-content>
