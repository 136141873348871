<div style="overflow: hidden;"  [ngClass]="{'sended-message-container': sendBy, 'received-message-container':  !sendBy}">
  <ion-item lines="none" class="chat-list-item font-ask"
            [ngClass]="{
        'loading-ask': message?.loading || false,
        'hasButtons':showButton,
        'confirmed-message': showConfirmMessage
        }">
    <ion-skeleton-text *ngIf="message?.loading || false" animated class="shimmer"></ion-skeleton-text>
    <ion-grid class="ask-grid ion-align-items-center ion-align-self-center">

      <div *ngIf="!sendBy && hasTopStatus" class="label-status flex margin">
        <div [innerText]="normalizedInfo?.home?.to?.text || ''"></div>
        <div [innerText]="message?.dare?.amount ? '$' + message?.dare?.amount+' USD' : 'FREE'"></div>
      </div>
      <div *ngIf="sendBy && hasTopStatus" class="label-status flex margin">
        <div [innerText]="normalizedInfo?.home?.by?.text  || ''"></div>
        <div [innerText]="message?.dare?.amount ? '$' + message?.dare?.amount+' USD' : 'FREE'"></div>
      </div>

      <ion-row>
        <ion-col style="display: flex;align-items: center;">
          <ion-label *ngIf="message?.linkOptions?.hasLink" class="ion-text-wrap font-ask" (click)="onClickLink($event,message)" [innerHTML]="message?.linkOptions?.linkText"></ion-label>
          <ion-label  *ngIf="!message?.linkOptions?.hasLink"  class="ion-text-wrap font-ask" (click)="onClickLink($event,message)" [innerText]="message?.dare?.name"></ion-label>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-item>
  <div class="font-ask"
       [ngClass]="{
       'preview-upload':  isPending,
       'sended-message-video-preview':  sendBy,
       'received-message-video-preview': !sendBy,
        'hasButtons': showButton }"
       [style.background]="'url(' + message?.dare?.dareMedia?.imageUrl + ')'"
       [style.background-size]="this.orientation"
       [style.background-position]="'center'"
       [style.background-repeat]="'no-repeat'"
       [style.background-color]="'#f0f8ff!important'">
    <img *ngIf="!isPending" (click)="play(message)" style="position: relative; top: 8%; padding: 40px; cursor: pointer"
         src="assets/icon/play-button.svg">
  </div>
  <ion-item lines="none"
            *ngIf="normalizedInfo?.actions && (normalizedInfo?.allowedActionUser === (sendBy ? 'by' : 'to')) || normalizedInfo?.allowedActionUser === 'both'"
            class="actions-item separator-ask font-ask" [ngClass]="{
        'action-sended':sendBy,
        'action-received':!sendBy,
        'loading-ask': message?.loading
        }">
    <ion-skeleton-text *ngIf="message?.loading" animated class="shimmer"></ion-skeleton-text>
    <ion-grid class="grid-actions" [ngClass]="{'center':message?.actions?.length === 1}">
      <div>
        <div class="label-status-rule margin" *ngIf="sendBy && normalizedInfo?.home?.by?.ruleText || false">
          {{normalizedInfo?.home?.by?.ruleText || ''}}
        </div>
        <div class="label-status-rule margin" *ngIf="!sendBy && normalizedInfo?.home?.to?.ruleText || false">
          {{normalizedInfo?.home?.to?.ruleText || ''}}
        </div>
        <div class="label-status-contest margin"
             *ngIf="!sendBy && message?.dare?.contestReason && (message?.dare?.status === 'trial' || message?.dare?.status === 'contested')">
          "{{message?.dare?.contestReason}}"
        </div>
      </div>
      <div style="margin: 10px 0px" *ngIf="validateRating(message?.dare?.status)">
        <app-star-rating (onClick)="execute({status:'rating',value:$event}, message)"
                         [rated]="!!message?.rated"
                         [avaliation]="message?.rated?.value"></app-star-rating>
      </div>
      <div *ngIf="normalizedInfo?.home?.by?.requestTimer || normalizedInfo?.home?.to?.requestTimer " class="control-chip ion-align-items-center ion-align-self-center">
        <ion-chip [ngClass]="{'sended-ask': sendBy , 'receiver-ask':!sendBy}">
          <ion-label style="color: #fff" *ngIf="!sendBy && normalizedInfo?.home?.to?.requestTimer">
            {{ normalizedInfo?.home?.to?.badge }}
            <span [id]="'ask-countdown-' + message?.messageId"></span>
          </ion-label>

          <ion-label style="color: #fff" *ngIf="sendBy && normalizedInfo?.home?.by?.requestTimer">
            {{ normalizedInfo?.home?.by?.badge }}
            <span [id]="'ask-countdown-' + message?.messageId"></span>
          </ion-label>
        </ion-chip>
      </div>
      <ion-row class="flex-buttons">
        <div *ngFor="let action of message?.actions"
             [ngClass]="{'action-button-small': action.size === 'small','action-button-large': action.size === 'large' ,'action-button-big-large':action.size === 'big-large'}">
          <ion-button class="action-button"
                      [disabled]="action?.disabled"
                      [ngClass]="{'action-button-solid': action.fill === 'solid', 'action-button-outline': action.fill === 'outline'}"
                      [fill]="action.fill"
                      (click)="execute(action, message)"
                      *ngIf="(action?.user === 'to' && !sendBy) || (action?.user === 'by' && sendBy) || (message?.sendBy?.name === 'Truly' && action.user === 'to')">
            <ion-label class="ion-text-wrap" *ngIf="action.text">{{ action.text }}</ion-label>
            <ion-icon style="font-size: 21px" *ngIf="action?.icon" slot="end" [name]="action?.icon"></ion-icon>
          </ion-button>
        </div>
      </ion-row>
      <div class="label-status-link margin"
           (click)="execute(normalizedInfo?.link, message)" *ngIf="( normalizedInfo?.link?.user === 'to' && !sendBy) || ( normalizedInfo?.link?.user === 'by' && sendBy) && normalizedInfo?.link?.text">
        {{normalizedInfo?.link?.text || ''}}
      </div>
    </ion-grid>
  </ion-item>
</div>
