<ion-content
  #contentProfile
  [scrollEvents]="true"
  id="profile"
  (ionScroll)="onScroll($event)"
>
  <app-home-view
    pageName="Profile"
    [backButtonMobile]="true"
    [simpleHeader]="true"
    [hideHeader]="isMobile"
    [showSideMenu]="false"
    [hasCateg]="false">
    <div style="max-width: 894px;width: 100%">
      <app-header-default
        backgroundColor="#fff"
        colorIcon="#000"
        [user]="user"
        headerHeight="72px"
        [hideAnimated]="hideHeader"
        [marginHeader]="true"
        [showIconShare]="true"
        [showIcon]="false"
        [showTitle]="true"></app-header-default>
      <app-profile-info *ngIf="user?.id"
                        [user]="user"
                        [isProfileOwner]="user?.id === userLogged?.id"
                        [customBack]="isMobile"
                        [enableShare]="true"
                        [followInfo]="this.followInfo"
                        (onClickBack)="goBack()"
                        (onClickFollow)="actionFollow()"
                        (onClickShare)="shareProfile()"
                        (onClickAsk)="presentModal()"
                        (onClickBook)="presentModalSendRequestSession()"
                        (onClickEditProfile)="goToEditProfile()"
                        (onclickEditBackgroud)="editBackground()"
                        (onClickAvaliations)="goToReviewList()"
                        [adminList]="true"
      ></app-profile-info>
      <app-profile-tags *ngIf="user?.tags?.length || isLoading" [isLoading]="isLoading" [grayTag]="true"
                        [chunkedtags]="chunkedtags"
                        (onClickTag)="modalGeneralSearch($event)"
      ></app-profile-tags>
      <div style="margin-bottom: 30px"></div>
      <app-post [showPinnedLabel]="true" [loggedUser]="userLogged" [index]="i+'profile'" [optionAsk]="true"
                *ngFor="let f of  this.feed; let i = index" [feed]="f" [removeFirstBorder]="i === 0">
        <app-video
          [isLoggedUser]="!!this.userLogged?.id"
          [index]="i"
          [allowedReupload]="false"
          [feed]="f"></app-video>
      </app-post>
      <app-empty-message-content
        icon="play-circle"
        title="No Results"
        [subTitle]="''+user?.name+' has not posted yet'"
        style="width: 100%"
        *ngIf="feed?.length === 0 && !isLoading"
      ></app-empty-message-content>
      <app-load-video-profile *ngIf="isLoading"></app-load-video-profile>
      <ion-infinite-scroll *ngIf="this.pagination.enable" threshold="10px" (ionInfinite)="ionInfinite($event)"
                           style="margin-top: 72px">
        <ion-infinite-scroll-content class="loading-spinner" loadingSpinner="lines">
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
      <div style="margin-bottom: 300px"></div>
    </div>
  </app-home-view>

</ion-content>
<app-fab-to-top [showFab]="showFab" (onClickFab)="scrollToTop()"></app-fab-to-top>

