<div class="ctn-video-info">
  <div class="video-thumb" [style.background-image]="'url('+validImageThumbVideo()+')'">
    <ion-icon (click)="navigateToWatch(feed?.id)" class="icon-play" name="play"></ion-icon>
    <app-time-video style="height: 100%;width: 100%" [duration]="this.feed?.media?.duration"
                    [size]="12"></app-time-video>
  </div>
  <div class="video-info">
    <div class="title-uploaded">
      <span [innerText]="feed?.isPrivate ? 'HIDDEN' : 'VISIBLE'"></span>
      <!-- <ion-icon class="icon" [name]=" feed?.isPrivate ? 'eye-off-outline' : 'eye-outline'"></ion-icon> -->
      <div *ngIf="feed?.pinnedAt" class="icon-pin"></div>
    </div>
    <div class="title" (click)="navigateToWatch(feed?.id)" [ngClass]="{'italic':this.feed?.media?.name}"
    [innerText]="this.feed?.media?.name || 'Add a title to your video'">
    </div>
    <div class="user-info" (click)="navigateToProfile(feed?.user)">
      <ion-thumbnail class="thumbnail-user">
        <img alt="user-name" [src]="this.defaultUtils.validUserImage(this.feed?.user?.photo)">
      </ion-thumbnail>
      <div class="title-user">{{this.feed?.user?.name}}</div>
      <!-- <div class="stamp" *ngIf="this.feed?.user?.isVerified"></div> -->
    </div>
    <div class="stars">
      <app-star-rating *ngIf="this.feed?.rating" [rated]="true" [avaliation]="this.feed?.rating"></app-star-rating>
    </div>
  </div>
  <div class="menu" (click)="modalOptionsProfile()">
    <ion-icon class="icon" name="ellipsis-vertical"></ion-icon>
  </div>
</div>
