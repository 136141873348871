import {Component, OnInit} from '@angular/core';
import {LoadingController, ModalController} from '@ionic/angular';
import {DefaultUtil} from '../../utils/default.util';
import {CameraPage} from '../camera/camera.page';
import {ColorToast} from '../../enums/color-toast';
import {PlatformUtil} from '../../utils/platform.util';
import {MediaUtil} from '../../utils/media.util';

// import {CaptureVideoOptions} from '@awesome-cordova-plugins/media-capture';

@Component({
  selector: 'app-modal-upload-profile-options',
  templateUrl: './modal-upload-profile-options.page.html',
  styleUrls: ['./modal-upload-profile-options.page.scss'],
})
export class ModalUploadProfileOptionsPage implements OnInit {

  public warnCamera: boolean;
  public hasCamera: any;
  public isNative: boolean;

  constructor(private modalCtrl: ModalController,
              public defaultUtils: DefaultUtil,
              public platformUtil: PlatformUtil,
              private mediaUtil: MediaUtil,
              private loadingCtrl: LoadingController) {
  }

  async ngOnInit() {
    this.isNative = this.platformUtil.isCordova();
    this.hasCamera = await this.defaultUtils.cameraExist();
    this.warnCamera = this.platformUtil.isSafariDesktop() || this.platformUtil.isIos();
  }

  close() {
    this.modalCtrl.dismiss();
  }

  async modalCameraApp() {
    if (!this.hasCamera) {
      this.defaultUtils.globalToast(
        'Camera not found!',
        ColorToast.primary,
        2000
      );
      return;
    }
    const modal = await this.modalCtrl.create({
      component: CameraPage,
      swipeToClose: true,
      backdropDismiss: false,
    });
    await modal.present();
    const response: any = await modal.onDidDismiss();
    await this.defaultUtils.timeout(200);
    await this.modalCtrl.dismiss(response?.data);
  }

  async getNativeFile() {
    const file: any = await this.mediaUtil.cameraVideoImage(true);
    if (file) {
      if (file.type.includes('video')) {
        this.modalCtrl.dismiss({video: file, image: null});
      } else {
        this.defaultUtils.globalToast('Error: Invalid file!', ColorToast.danger);
      }
    }
  }

  async getNativeCamera() {
    const loading = await this.loadingCtrl.create({message: 'Loading...'});
    try {
      setTimeout(() => {
        loading.present();
      }, 1000);
      const data = await this.mediaUtil.getNativeCameraVideo({
        limit: 1,
        duration: 180,
        quality: 80,
      });
      loading.dismiss();
      await this.modalCtrl.dismiss(data);
    } catch (e) {
      alert(e?.toString());
      loading.dismiss();
    }
  }

  async getNativeGallery() {
    const data: any = await this.mediaUtil.getNativeGalleryVideo();
    // const file = await this.mediaUtil.nativeGallery('video/*');
    await this.modalCtrl.dismiss(data);
  }

}
