import {Component, Input, OnInit} from '@angular/core';
import {DefaultUtil} from '../../utils/default.util';
import {Feed} from '../../models/feed.model';
import {User} from '../../models/user';
import {NavigationExtras, Router} from '@angular/router';
import {ModalController} from '@ionic/angular';
import {ButtonOption, OptionIconType} from '../../pages/modal-options-default/modal-options-default.page';
import {PlatformUtil} from '../../utils/platform.util';
import {environment} from '../../../environments/environment';
import {ColorToast} from '../../enums/color-toast';
import {EmitEventService} from '../../services/emit-event.service';
import {FeedService} from '../../services/feed.service';

export enum OptionVideo {
  edit = 'Edit thumbnail',
  hideVideo = 'Hide',
  makeVideoVisible = 'Add to your profile',
  pinVideo = 'Pin to the top',
  unPinVideo = 'Unpin',
  share = 'Share link',
  close = 'Close'
}


@Component({
  selector: 'app-thumb-video-list',
  templateUrl: './thumb-video-list.component.html',
  styleUrls: ['./thumb-video-list.component.scss'],
})
export class ThumbVideoListComponent implements OnInit {

  @Input()
  public feed: Feed;

  @Input()
  public user: User;

  @Input()
  public index: number;

  public listButtonFilter: Array<ButtonOption>;

  public isIos: boolean;

  constructor(
    public defaultUtils: DefaultUtil,
    public router: Router,
    public platformUtil: PlatformUtil,
    private emitEventService: EmitEventService,
    private feedService: FeedService,
    private modalCtrl: ModalController
  ) {
    this.isIos = (this.platformUtil.isIos() || this.platformUtil.isCordovaIos());
  }

  ngOnInit() {
  }

  navigateToProfile(user) {
    this.router.navigate([`profile/${user?.name}`]);
  }

  navigateToWatch(feedId: string) {
    this.router.navigateByUrl(`watch?ref=${feedId}`);
  }

  async modalOptionsProfile() {
    try {
      this.listButtonFilter = [
        {
          text: OptionVideo.share,
          selected: false,
          icon: this.isIos ? 'link' : 'link',
          iconType: OptionIconType.ionicIcon,
        },
      ];
      if (this.feed?.owner) {
        this.listButtonFilter = [
          {
            text: OptionVideo.edit,
            selected: false,
            icon: 'create-outline',
            iconType: OptionIconType.ionicIcon,
          },
          // {
          //   text: this.feed?.pinnedAt ? OptionVideo.unPinVideo : OptionVideo.pinVideo,
          //   selected: false,
          //   icon: '../../../assets/images/pin_filled.png',
          //   iconType: OptionIconType.image,
          // },
          {
            text: this.feed.isPrivate ? OptionVideo.makeVideoVisible : OptionVideo.hideVideo,
            selected: false,
            icon: this.feed.isPrivate ? 'person-add-outline' : 'person-remove-outline',
            iconType: OptionIconType.ionicIcon,
          },
          ...this.listButtonFilter
        ];
      }


      const response = await this.defaultUtils.modalOptionsDefault({
        listButtons: this.listButtonFilter,
        titleCloseLabel: OptionVideo.close,
        title: 'Options',
      });
      this.onSelectOption(response);
    } catch (e) {
      console.log('e', e);
    }
  }


  onSelectOption(option) {
    switch (option) {
      case OptionVideo.share:
        this.share();
        break;
      case OptionVideo.hideVideo:
      case  OptionVideo.makeVideoVisible:
        this.shareProofs();
        break;
      case  OptionVideo.edit:
        this.navigateToEdit();
        break;
      case OptionVideo.pinVideo:
      case OptionVideo.unPinVideo:
        this.pinVideoAction();
        break;
    }
  }

  validImageThumbVideo() {
    return this.feed?.media?.imageUrl || this.feed?.media?.url?.toString()?.replace('.mp4', '.jpg');
  }

  async validPrivateVideo() {
    return new Promise(async resolve => {
      const alert = {
        header: 'Private Video',
        message: 'This video is currently set to private. Would you like to make it visible in your feed?',
        buttons: [
          {
            text: 'NO',
            handler: () => resolve(false)
          },
          {
            text: 'YES',
            handler: () => resolve(true)
          }
        ]
      };
      await this.defaultUtils.alertDefault(alert);
    });
  }

  async pinVideoAction() {
    if (!this.feed?.owner) {
      return;
    }
    if (this.feed.isPrivate && !this.feed?.pinnedAt) {
      const valid = await this.validPrivateVideo();
      if (valid) {
        this.feed.isPrivate = !this.feed.isPrivate;
      }
    }

    const message = this.feed?.pinnedAt ? 'The video has been unpinned and may no longer appear in the top position of your feed.' :
      'Great news! This video has now secured the top spot in your feed.';

    const result = (await this.feedService.getPinFeeds(this.user?.id))?.docs?.map(a => ({id: a?.id, ...a?.data() as any}));
    for (const r of result) {
      await this.feedService.updatePostFeed({
        pinnedAt: this.defaultUtils.deleteFieldFirestore(),
      }, r.id);
    }
    if (!this.feed?.pinnedAt) {
      await this.feedService.updatePostFeed({
        pinnedAt: this.defaultUtils.getDefaultDateFirebase(),
        updatedAt: this.defaultUtils.getDefaultDateFirebase(),
      }, this.feed.id);
    }
    this.defaultUtils.setUpdateMyProfile();
    this.emitEventService.publishDataUpdateFeedData({page: 'videoList', value: {feed: this.feed, index: this.index}});
    this.defaultUtils.globalToast(message, ColorToast.primary, 4000);
  }


  async shareProofs() {
    if (!this.feed?.owner) {
      return;
    }
    this.feed.isPrivate = !this.feed.isPrivate;
    this.feed.updatedAt = this.defaultUtils.getDefaultDateFirebase();
    this.feedService.updatePostFeed({
      isPrivate: this.feed.isPrivate,
      updatedAt: this.feed.updatedAt
    }, this.feed.id);
    this.emitEventService.publishDataUpdateFeedData({page: 'videoList', value: {feed: this.feed, index: this.index}});
    this.defaultUtils.setUpdateMyProfile();
  }

  share() {
    const defaultUrl = environment.type === 'dev' ? 'jointruly.app' : 'jointruly.com';
    const shareData = {
      text: 'Watch this video!\n',
      url: `https://${defaultUrl}/watch?ref=${this.feed.id}`,
    };
    this.defaultUtils.share(shareData, null, null, null, null);
    this.defaultUtils.globalToast('Video link copied.<br>TIP: Anyone can watch without an account', ColorToast.primary);
  }


  navigateToEdit() {
    if (!this.feed?.owner) {
      return;
    }
    const navigationExtras: NavigationExtras = {
      queryParams: {
        id: this.feed.id,
      },
    };

    this.router.navigate(['edit-video'], navigationExtras);
  }

}
