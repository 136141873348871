<ion-content>
  <div class="container" #dragElement (click)="onAction()">
    <div class="container-fake">
      <div class="info">
        <div class="title">
          <div class="image" [style.background-image]="'url(' + this.image + ')'"></div>
          <div class="text">{{title}}</div>
        </div>
        <div class="message">{{message}}</div>
      </div>
      <div class="date">
        {{ this.time }}
      </div>
    </div>
  </div>
</ion-content>
