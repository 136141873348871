import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-slide-confirm-button',
  templateUrl: './slide-confirm-button.component.html',
  styleUrls: ['./slide-confirm-button.component.scss'],
})
export class SlideConfirmButtonComponent implements OnInit {
  @Output()
  confirmEvent = new EventEmitter();

  @Output()
  onScroll = new EventEmitter();

  @Input()
  loading = false;

  @Input()
  complete = false;

  @Input()
  disabled: boolean;
  range = 0;

  constructor() {
  }

  ngOnInit() {
  }

  slideButton() {
    if (this.range === 100) {
      this.confirmEvent.emit(true);
    } else {
      this.onScroll.emit(true);
    }
  }
}
