
<ion-content [fullscreen]="true">
  <app-empty-message-content
    title="Connect to the Internet"
    [subTitle]="'You are offline. Check your connection.'"
    [linkText]="isLoading ? '' : 'Try again!'"
    [isLoading]="isLoading"
    (onClickLink)="clickLink()"
    style="width: 100%"
    class="break-line"
  ></app-empty-message-content>
</ion-content>
