import {Injectable} from '@angular/core';
import {AngularFirestore, DocumentReference} from '@angular/fire/firestore';
import {environment} from '../../environments/environment';
import {HttpClient} from '@angular/common/http';
import firebase from 'firebase/app';
import 'firebase/firestore';

@Injectable({
  providedIn: 'root',
})

export class FeedService {
  collectionName = 'feedz';
  subCollectionLikes = 'likes';
  subCollectionComments = 'comments';

  constructor(private firestore: AngularFirestore, private http: HttpClient) {
  }

  async getByPrivateAndSendTo(data) {
    return this.http
      .post(`${environment.cloudFunctionUrl}/feed/getByPrivateAndSendTo`, data)
      .toPromise();
  }

  savePost(post) {
    return this.firestore.collection(this.collectionName).add(post);
  }

  async getPostFeed(userId, messageId) {
    return this.firestore
      .collection(this.collectionName)
      .ref.where('sendTo.id', '==', userId)
      .where('media.id', '==', messageId)
      .where('owner', '==', true)
      .limit(1)
      .get();
  }

  async updatePostFeed(data, docId) {
    return this.firestore
      .collection(this.collectionName)
      .doc(docId)
      .update(data);
  }

  async getShortVideo(userId) {
    return this.firestore
      .collection(this.collectionName)
      .ref.where('user.id', '==', userId)
      .where('media.type', '==', 'shortVideo')
      .get();
  }

  async getVideoProof(userId) {
    return this.firestore
      .collection(this.collectionName)
      .ref.where('sendBy.id', '==', userId)
      .where('media.type', '==', 'video')
      .where('owner', '==', false)
      .get();
  }

  async getVideoProofOwner(userId) {
    return (
      this.firestore
        .collection(this.collectionName)
        .ref.where('sendTo.id', '==', userId)
        .where('media.type', '==', 'video')
        .where('owner', '==', true)
        .get()
    );
  }

  getPublicFeed(userId: any, isPrivate = false): any {
    return new Promise((resolve) => {
      this.http
        .post(`${environment.cloudFunctionUrl}/feed/get/publicFeed`, {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          userId,
          isPrivate
        })
        .subscribe((data) => resolve(data));
    });
  }

  addProof(data, owner, isPrivate): any {
    return this.http
      .post(`${environment.cloudFunctionUrl}/feed/add`, {
        data,
        owner,
        isPrivate,
      })
      .toPromise();
  }

  async getFeedById(docId) {
    return this.firestore
      .collection(this.collectionName)
      .doc(docId)
      .ref.get();
  }

  getPublicVideo(feedId): any {
    return new Promise((resolve) => {
      this.http
        .post(`${environment.cloudFunctionUrl}/feed/get/publicVideo`, {
          feedId,
        })
        .subscribe((data) => resolve(data));
    });
  }

  getFeedUsers(userIds) {
    return this.firestore
      .collection(this.collectionName)
      .ref.where('sendTo.id', 'in', userIds)
      .where('isPrivate', '==', false)
      .orderBy('updatedAt', 'desc')
      .limit(100)
      .get();
  }

  getFeedUserByID(id, limit = 50) {
    return this.firestore
      .collection(this.collectionName)
      .ref.where('sendTo.id', '==', id)
      .where('isPrivate', '==', false)
      .orderBy('updatedAt', 'desc')
      .limit(limit)
      .get();
  }

  saveLike(feedId, likeData) {
    return this.firestore
      .collection(this.collectionName)
      .doc(feedId)
      .collection(this.subCollectionLikes)
      .add(likeData);
  }

  saveComment(feedId, commentData) {
    return this.firestore
      .collection(this.collectionName)
      .doc(feedId)
      .collection(this.subCollectionComments)
      .add(commentData);
  }

  updateCountComment(feedId, count) {
    const feedReef = this.firestore.collection(this.collectionName).doc(feedId);
    feedReef.update({
      quantityComments: firebase.firestore.FieldValue.increment(count)
    });
  }

  updateCountLikes(feedId, count) {
    const feedReef = this.firestore.collection(this.collectionName).doc(feedId);
    feedReef.update({
      quantityLikes: firebase.firestore.FieldValue.increment(count)
    });
  }

  deleteLike(feedId, likeId) {
    return this.firestore
      .collection(this.collectionName)
      .doc(feedId)
      .collection(this.subCollectionLikes)
      .doc(likeId)
      .delete();
  }

  deleteComment(feedId, commentId) {
    return this.firestore
      .collection(this.collectionName)
      .doc(feedId)
      .collection(this.subCollectionComments)
      .doc(commentId)
      .delete();
  }


  getComments(feedId, limit) {
    return this.firestore
      .collection(this.collectionName)
      .doc(feedId)
      .collection(this.subCollectionComments)
      .ref
      .where('enable', '==', true)
      .limit(limit)
      .orderBy('createdAt', 'desc')
      .get();
  }

  async getLikes(feedId) {
    return this.firestore
      .collection(this.collectionName)
      .doc(feedId)
      .collection(this.subCollectionLikes)
      .ref
      .get();
  }

  async getAllFeeds() {
    return this.firestore
      .collection(this.collectionName)
      .ref
      .where('owner', '==', true)
      .orderBy('createdAt', 'desc')
      .get();
  }

  async getPinFeeds(userId) {
    return this.firestore
      .collection(this.collectionName)
      .ref
      .where('sendTo.id', '==', userId)
      .orderBy('pinnedAt', 'desc')
      .get();
  }

  async getPublicFeedIternal(userId) {
    return this.firestore
      .collection(this.collectionName)
      .ref
      .where('sendTo.id', '==', userId)
      .where('isPrivate', '==', false)
      .limit(2)
      .get();
  }

}
