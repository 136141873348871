import {EventEmitter, Injectable} from '@angular/core';
import {
  AlertController,
  LoadingController,
  MenuController,
  ModalController,
  Platform,
  ToastController,
} from '@ionic/angular';
import {MediaService} from '../services/media.service';
import {UserService} from '../services/user.service';
import {environment} from '../../environments/environment';
import {PathStorage} from '../enums/path-storage';
import {TypeFeed} from '../enums/type-feed';
import {AddPipeService} from '../services/add-pipe.service';
import {FeedService} from '../services/feed.service';
import firebase from 'firebase/app';
import 'firebase/firestore';
import {ModalPushPage} from '../pages/modal-push/modal-push.page';
import {Router} from '@angular/router';
import {ModalPaidAnimationPage} from '../pages/modal-paid/modal-paid-animation.page';
import {ChatService} from '../services/chat.service';
import {MessageService} from '../services/message.service';
import {ModalInfoOnboardingPage} from '../pages/modal-info-onboarding/modal-info-onboarding.page';
import {StripeService} from '../services/stripe.service';
import {ValidatesUtil} from './validates.util';
import {ColorToast} from '../enums/color-toast';
import {EmitEventService} from '../services/emit-event.service';
import {ModalUploadProfileOptionsPage} from '../pages/modal-upload-profile-options/modal-upload-profile-options.page';
import {DomSanitizer} from '@angular/platform-browser';
import {PlatformUtil} from './platform.util';
import {SocialSharing} from '@awesome-cordova-plugins/social-sharing/ngx';
import {NotificationService} from '../services/notification.service';
import {Title} from '@angular/platform-browser';
import {Button, ModalProfileOptionsPage} from '../pages/modal-profile-options/modal-profile-options.page';
import {Feed} from '../models/feed.model';
import getBlobDuration from 'get-blob-duration';
import {ModalOptionsDefaultPage, PropsModalDefault} from '../pages/modal-options-default/modal-options-default.page';
import {Follow, FollowsService} from '../services/follows.service';
import {InAppBrowser, InAppBrowserObject} from '@awesome-cordova-plugins/in-app-browser/ngx';
import {MediaUtil} from './media.util';
import {RatingsService} from '../services/ratings.service';
import {ModalInfoLoginPage} from "../pages/modal-info-login/modal-info-login.page";

export interface Avaliation {
  valueAvaliation: number;
  quantityAvaliation: number;
}

@Injectable({
  providedIn: 'root',
})
export class DefaultUtil {

  progressPaidAnimation = false;
  unreadMessagesEvent = new EventEmitter();
  hideTabs = new EventEmitter();
  changeTabSelected = new EventEmitter();
  public modalPush = null;

  constructor(
    private plr: Platform,
    private mediaService: MediaService,
    private userService: UserService,
    public toastController: ToastController,
    public menu: MenuController,
    public modalCtrl: ModalController,
    public addpipeService: AddPipeService,
    public feedService: FeedService,
    public router: Router,
    public alertController: AlertController,
    private chatService: ChatService,
    private messageService: MessageService,
    private stripeService: StripeService,
    private validateUtils: ValidatesUtil,
    private emitEventService: EmitEventService,
    private sanitizer: DomSanitizer,
    private platformUtil: PlatformUtil,
    private socialSharing: SocialSharing,
    private notificationService: NotificationService,
    private title: Title,
    private followsService: FollowsService,
    private iab: InAppBrowser,
    private mediaUtil: MediaUtil,
    private loadingCtrl: LoadingController,
    private ratingService: RatingsService
  ) {
    this.onCloseModal();
  }

  async validFileExist(url) {
    try {
      const response = await this.mediaService.fileExist(url);
      return response === 200;
    } catch (e) {
      console.log('e', e);
      return false;
    }
  }

  removeDuplicatedArray(array) {
    return array.filter((value, index, self) => self.indexOf(value) === index);
  }

  removeDuplicatedObject(array) {
    return array.filter((obj, index, self) =>
      index === self.findIndex(t => JSON.stringify(t) === JSON.stringify(obj))
    );
  }

  removeDuplicatedObjectId(arr) {
    return arr.filter((v, i, a) => a.findIndex(v2 => (v2.id === v.id)) === i);
  }

  removeDuplicatedObjectEspecificParam(arr,param) {
    return arr.filter((v, i, a) => a.findIndex(v2 => (v2[param] === v[param])) === i);
  }


  // <script async="" defer="" src=""></script>

  addJWPlayerScript(scriptUrl = 'https://cdn.jwplayer.com/libraries/G8eKR6DW.js') {
    this.removeJWPlayerScript();
    const jwplayer = document.createElement('script');
    jwplayer.setAttribute('async', '');
    jwplayer.setAttribute('defer', '');
    jwplayer.setAttribute('id', 'jw');
    jwplayer.setAttribute(
      'src',
      scriptUrl
    );
    document.body.append(jwplayer);
  }

  removeJWPlayerScript() {
    const jwplayer: any = document.getElementById('jw');
    jwplayer?.remove();
  }

  getFirstElement(a) {
    const l = a?.length;
    if (l === 0) {
      return null;
    }
    return a[0];
  }

  removeOriginObject(value) {
    if (value) {
      return JSON.parse(JSON.stringify(value));
    }
    return null;
  }

  getLastElement(a) {
    const l = a?.length;
    if (l === 0) {
      return null;
    }
    return a[l - 1];
  }


  isValidDate(d) {
    const date: any = new Date(d);
    return date?.isValid() || false;
  }


  validCamera() {
    if (this.platformUtil.isIos()) {
      // if (this.getIosVersion() < 14) {
      //   return false;
      // }
      return false;
    }
    return true;
  }

  async base64ToBlob(base64, contentType, sliceSize) {
    const byteCharacters = atob(base64.split(',')[1]);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, {type: contentType});
  }

  chunkArray(array, parts) {
    array = this.removeOriginObject(array);
    const result = [];
    if (array?.length > 0) {
      for (let i = parts; i > 0; i--) {
        result.push(array.splice(0, Math.ceil(array.length / i)));
      }
    }
    return result;
  }

  chunkArrayLimitedItems(array, size) {
    array = this.removeOriginObject(array);
    const result = [];
    if (array?.length > 0) {
      for (let i = 0; i < array.length; i += size) {
        result[Math.floor(i / size)] = array.slice(i, i + size);
      }
    }
    return result;
  }

  async timeout(time) {
    await new Promise((resolve) => setTimeout(() => resolve(true), time));
  }

  cameraExist() {
    return new Promise((resolve) => {
      const media = navigator.mediaDevices;
      if (!media || !media.enumerateDevices) {
        return resolve(false);
      }
      media.enumerateDevices().then((devices) => {
        resolve(devices.some((device) => 'videoinput' === device.kind));
      });
    });
  }

  setGlobalLoad(show: boolean) {
    const globalLoadApp: any = document.getElementById('global-load-app');
    if (show) {
      globalLoadApp.style.display = 'block';
    } else {
      globalLoadApp.style.display = 'none';
    }
  }

  fadeSlideOpts() {
    return {
      initialSlide: 0,
      slidesPerView: 1,
      autoplay: true,
      on: {
        beforeInit() {
          const swiper = this;
          swiper.classNames.push(`${swiper.params.containerModifierClass}fade`);
          const overwriteParams = {
            slidesPerView: 1,
            slidesPerColumn: 1,
            slidesPerGroup: 1,
            watchSlidesProgress: true,
            spaceBetween: 0,
            virtualTranslate: true,
            initialSlide: 0,
          };
          swiper.params = Object.assign(swiper.params, overwriteParams);
          swiper.params = Object.assign(swiper.originalParams, overwriteParams);
        },
        setTranslate() {
          const swiper = this;
          const {slides} = swiper;
          for (let i = 0; i < slides.length; i += 1) {
            const $slideEl = swiper.slides.eq(i);
            const offset$$1 = $slideEl[0].swiperSlideOffset;
            let tx = -offset$$1;
            if (!swiper.params.virtualTranslate) {
              tx -= swiper.translate;
            }
            let ty = 0;
            if (!swiper.isHorizontal()) {
              ty = tx;
              tx = 0;
            }
            const slideOpacity = swiper.params.fadeEffect.crossFade
              ? Math.max(1 - Math.abs($slideEl[0].progress), 0)
              : 1 + Math.min(Math.max($slideEl[0].progress, -1), 0);
            $slideEl
              .css({
                opacity: slideOpacity,
              })
              .transform(`translate3d(${tx}px, ${ty}px, 0px)`);
          }
        },
        setTransition(duration) {
          const swiper = this;
          const {slides, $wrapperEl} = swiper;
          slides.transition(duration);
          if (swiper.params.virtualTranslate && duration !== 0) {
            let eventTriggered = false;
            slides.transitionEnd(() => {
              if (eventTriggered) {
                return;
              }
              if (!swiper || swiper.destroyed) {
                return;
              }
              eventTriggered = true;
              swiper.animating = false;
              const triggerEvents = ['webkitTransitionEnd', 'transitionend'];
              // eslint-disable-next-line @typescript-eslint/prefer-for-of
              for (let i = 0; i < triggerEvents.length; i += 1) {
                $wrapperEl.trigger(triggerEvents[i]);
              }
            });
          }
        },
      },
    };
  }

  openInNewTab(url) {
    const win = window.open(url, '_blank');
    win.focus();
  }


  async getUser() {
    const coreUser: any = await this.userService.getCurrentUser();
    if (coreUser) {
      return coreUser?.docs[0]?.data();
    }
    return null;
  }

  async share(params, userId = null, callback = null, user = null, showToast = true, messageToast = 'Direct link copied. Share it with your friends.') {
    // 'Your direct link was copied. Invite people on social media to consult with you.'
    const shareData = {
      url: params?.url || document.URL,
      text: params?.text || '',
    };
    if (this.platformUtil.isCordova()) {
      this.socialSharing.share(shareData?.text, '', null, shareData?.url).then(() => {
        callback();
      });
    } else if (navigator?.share) {
      navigator
        .share({...shareData})
        .then(async () => {
          if (callback) {
            await this.userService.update(userId, {shared: true});
            callback();
          }
        })
        .catch((error) => console.log('Error sharing', error));

    } else {
      navigator.clipboard.writeText(shareData.url);
      if (showToast) {
        const toast = await this.toastController.create({
          message: messageToast,
          // 'Profile link copied.<br>TIP: People dont need an acount to ask you.',
          duration: 4000,
          color: 'primary',
        });
        await toast.present();
      }
    }
  }

  storage() {
    return {
      get: (key) => JSON.parse(localStorage.getItem(key)),
      set: (key, value) => localStorage.setItem(key, JSON.stringify(value)),
      delete: (key) => localStorage.removeItem(key),
    };
  }

  async blockUser(id) {
    const mainUser = await this.getUser();
    let blockedUsers = [];
    if (mainUser.blockeduser) {
      blockedUsers = [id, ...mainUser.blockeduser];
    } else {
      blockedUsers = [id];
    }
    blockedUsers = this.removeDuplicatedArray(blockedUsers);
    await this.userService.update(mainUser.id, {blockedUsers});
    this.globalToast('Blocked successfully');
  }

  async unBlockUser(id) {
    const mainUser = await this.getUser();
    const findIndex = mainUser.blockedUsers.findIndex((a) => a === id);
    mainUser.blockedUsers.splice(findIndex, 1);
    const blockedUsers = mainUser.blockedUsers;
    await this.userService.update(mainUser.id, {blockedUsers});
    this.globalToast('Unblocked successfully');
  }

  async globalToast(message, color: ColorToast = ColorToast.dark, duration = 2000) {
    const toast = await this.toastController.create({
      message,
      color,
      duration,
    });
    await toast.present();
    return toast;
  }

  validUserImage(userImage) {
    if (userImage) {
      return userImage;
    }
    return '../../../assets/images/user.png';
  }

  async openSideMenu() {
    await this.menu.enable(true, 'first');
    await this.menu.open('first');
  }

  async closeSideMenu() {
    await this.menu.close();
  }

  async presentModalUploadProfile() {
    const modal = await this.modalCtrl.create({
      component: ModalUploadProfileOptionsPage,
      swipeToClose: true,
      backdropDismiss: true,
      showBackdrop: true,
      mode: 'md',
      cssClass: 'option-media-chat-modal',
    });
    await modal.present();
    const response: any = await modal.onDidDismiss();
    return response?.data;
  }

  async cameraComponent(callback = null, messageAlert = '', feed?: Feed) {
    try {
      let file: any = {video: null, image: null};
      if (!this.platformUtil.isIos() && !this.platformUtil.isSafariDesktop()) {
        file = await this.presentModalUploadProfile();
      } else if (this.platformUtil.isCordovaIos()) {
        file = await this.presentModalUploadProfile();
      } else {
        file = {video: await this.mediaUtil.cameraVideoImage(true)};
      }

      if (file?.video) {
        if (!file?.video?.type.includes('video')) {
          this.globalToast('Error: invalid file', ColorToast.danger);
          return;
        }
        if (!this.validFileSize(file?.video, 150)) {
          return;
        }
        const loading = await this.loadingCtrl.create({message: 'Uploading'});
        loading.present();
        await this.uploadVideoFeedWithoutLoad(file, feed);
        loading.dismiss();
        if (callback) {
          callback();
        }
      }
    } catch (e) {
      console.log('e >>>>>>>>>', e);
    }
  }

  async getDurationVideo(file) {
    const duration = await getBlobDuration(file);
    return Math.round(duration);
  }

  async alertDefault({message, buttons, header = null, subHeader = null, backdropDismiss = true}) {
    const alert = await this.alertController.create({
      message,
      header,
      subHeader,
      buttons,
      backdropDismiss,
    });
    await alert.present();
  }


  async uploadVideoFeedWithoutLoad(data, feedToUpdate?: Feed) {
    if (data) {
      const {video, image} = data;
      let feed = {} as Feed;
      // const mainToast = await this.globalToast('Upload in progress...', ColorToast.dark, 30000);
      const duration = await this.getDurationVideo(video);
      if (feedToUpdate?.id) {
        feed = feedToUpdate;
        feed.media.url = false;
        feed.updatedAt = this.getDefaultDateFirebase();
        await this.feedService.updatePostFeed(feed, feed?.id);
      } else {
        feed = await this.save(false);
      }
      const {html5Server} = await this.addpipeService.getAddpipeValues(
        environment.addpipe.eid,
        environment.addpipe.accountHash
      );
      const uploadType = 'deskUp';
      let fileName = null;
      let file = new File([video], `${new Date().valueOf().toString()}.MP4`);
      if (this.platformUtil.isCordova()) {
        file = video;
        fileName = `${new Date().valueOf().toString()}.MP4`;
      }
      const upload = await this.addpipeService.postVideoAddPipe(
        html5Server,
        environment.addpipe.eid,
        environment.addpipe.accountHash,
        file,
        uploadType,
        fileName,
      );
      const url = `https://storage.googleapis.com/${environment.firebaseConfig.storageBucket}/${PathStorage.dareProofVideos}/${upload.f}`;
      if (image) {
        const [name, type] = upload.f.split('.');
        const pathImage = `${PathStorage.dareProofVideos}/${name}_thumb.jpg`;
        feed.media.imageUrl = await this.mediaService.uploadFileFirebase(
          pathImage,
          image
        );
      }
      feed.media.url = url;
      feed.media.duration = duration;
      this.feedService.updatePostFeed(feed, feed?.id);
      // mainToast?.dismiss();
      this.globalToast('Upload completed', ColorToast.success, 3000);

    }
  }

  async save(url) {
    const coreUser: any = await this.userService.getCurrentUser();
    const user = coreUser?.docs[0]?.data();
    if (user) {
      const post: Feed = {
        id: '',
        updatedAt: this.getDefaultDateFirebase(),
        createdAt: this.getDefaultDateFirebase(),
        media: {
          duration: 0,
          url,
          id: `${user.id}X${new Date().getTime()}`,
          name: '',
          type: TypeFeed.shortVideo,
          amount: '',
        },
        isPrivate: false,
        sendBy: {},
        sendTo: {
          id: user.id,
          name: user.name,
          photo: user.photoURL,
          email: user.email,
        },
        owner: true,
        user: {id: user.id, name: user.name, photo: user.photoURL}
      };
      const response = await this.feedService.savePost(post);
      post.id = response.id;
      return post;
    }
  }

  async updateUserOnline(setOnline = false) {
    const online = setOnline || !document.hidden;
    console.log('updateUserOnline>>>>>',online);
    const user = await this.getUser();
    if (user?.id) {
      const updatedAt: any = online
        ? this.getDefaultDateFirebase()
        : user.updatedAt;
      const lastDeviceInfo = this.platformUtil.getPlatform();
      await this.userService.update(user.id, {
        online,
        updatedAt,
        lastDeviceInfo,
        timezone: `${Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone}`,
      });
    }
  }

  async showPaidAnimation(values) {
    let showAnimation = false;

    const currentUser = await this.getUser();

    for (const value of values) {
      if (
        value.type === 'ask' &&
        value.sendTo.id === currentUser.id &&
        (value.dare.status === 'confirmed' ||
          value.dare.status === 'autoconfirmed') &&
        !value.confirmedAnimation
      ) {
        this.chatService.updateChat(
          {confirmedAnimation: true},
          value.chatId,
          value.id
        );
        showAnimation = true;
      }
    }

    if (!showAnimation) {
      return;
    }

    const modal = await this.modalCtrl.create({
      component: ModalPaidAnimationPage,
      backdropDismiss: true,
      cssClass: 'paid-animation-modal',
    });
    if (!this.progressPaidAnimation) {
      modal.present();
    }

    this.progressPaidAnimation = true;
    this.setUpdateMyProfile();
    setTimeout(() => {
      modal.dismiss();
    }, 3500);

    await modal.onDidDismiss();
    this.progressPaidAnimation = false;
  }

  async modalPushComponent(data) {
    const currentChatId = this.storage().get('chatId');
    if (!data?.id || currentChatId === data?.id) {
      return;
    }
    if (this.modalPush) {
      this.emitEventService.pusblishCloseModal(null);
    }
    let id = '';
    if(data?.type === 'chat') {
        id = data?.sendBy?.id === 'admin' ? 'truly-admin' : data?.sendBy?.id;
     }
    if(data?.type === 'feed') {
      id = data?.ref;
    }
    try {
      this.modalPush = await this.modalCtrl.create({
        component: ModalPushPage,
        swipeToClose: false,
        backdropDismiss: false,
        showBackdrop: false,
        mode: 'md',
        cssClass: 'push-modal',
        componentProps: {
          duration: 2000,
          title: this.getNameFromUnloggedAsk(data?.username?.toUpperCase() || ''),
          message: data?.msg || 'push',
          image: this.validUserImage(data?.avatar),
          time: this.fromNow(data?.timestamp.toDate()),
          id,
          type: data?.type,
        },
      });
      localStorage.setItem('modalPush', JSON.stringify(this.modalPush));
      await this.modalPush.present();
    } catch (e) {
      console.log('e', e);
    }
  }


  async modalPushComponentProt() {
    try {
      this.modalPush = await this.modalCtrl.create({
        component: ModalPushPage,
        swipeToClose: false,
        backdropDismiss: false,
        showBackdrop: false,
        mode: 'md',
        cssClass: 'push-modal',
        componentProps: {
          duration: 1000000,
          title: 'Mensagem',
          message: 'oi',
          image: '',
          time: '2 dias',
          id: '',
          type: '',
        },
      });
      localStorage.setItem('modalPush', JSON.stringify(this.modalPush));
      await this.modalPush.present();
    } catch (e) {
      console.log('e', e);
    }
  }

  getBrowser() {
    const dc: any = document;
    if (
      (navigator.userAgent.indexOf('Opera') ||
        // eslint-disable-next-line eqeqeq
        navigator.userAgent.indexOf('OPR')) != -1
    ) {
      return 'opera';
    } else if (navigator.userAgent.indexOf('Edg') !== -1) {
      return 'edge';
    } else if (navigator.userAgent.indexOf('Chrome') !== -1) {
      return 'chrome';
    } else if (navigator.userAgent.indexOf('Safari') !== -1) {
      return 'safari';
    } else if (navigator.userAgent.indexOf('Firefox') !== -1) {
      return 'firefox';
    } else if (
      navigator.userAgent.indexOf('MSIE') !== -1 ||
      !!dc.documentMode === true
    ) {
      return 'IE';
    } else {
      return 'unknown';
    }
  }


  async getUnreadMessages() {
    const currentUser = await this.getUser();
    const chatsReference = (
      await this.chatService.getByUserId(currentUser?.id)
    ).docs.map((x) => {
      const chatData: any = x.data();
      return {
        id: x.id,
        ...chatData,
      };
    });
    let unread = false;
    for (const c of chatsReference) {
      const unreadMessages =
        await this.messageService.getUnreadMessagesByChatId(
          c?.chatId,
          currentUser.id
        );

      c.unreadMessages = unreadMessages.size;

      if (unreadMessages.size === 0) {
        let doAction = await this.messageService.getActionDareSendBy(
          c?.chatId,
          currentUser.id
        );

        if (doAction.size > 0) {
          return;
        } else {
          doAction = await this.messageService.getActionDareSendTo(
            c?.chatId,
            currentUser.id
          );

          if (doAction.size > 0) {
            unread = true;
            break;
          }
        }
      }

      if (unreadMessages.size > 0) {
        unread = true;
        break;
      }
    }
    this.unreadMessagesEvent.emit(unread);
  }

  async modalInfoOnboarding() {
    try {
      return new Promise(async (resolve) => {
        const modal = await this.modalCtrl.create({
          component: ModalInfoOnboardingPage,
          swipeToClose: true,
          backdropDismiss: false,
          showBackdrop: false,
          mode: 'md',
          cssClass: 'modal-info-onboarding',
        });
        modal.onDidDismiss().then((response) => resolve(response?.data));
        await modal.present();
      });
    } catch (e) {
      console.log('erro onboarding profile');
    }
  }

  callInfoOnboarding() {
    const fromSignup = this.storage().get('clickModalProfile');
    if (fromSignup) {
      this.storage().delete('clickModalProfile');
      this.modalInfoOnboarding();
    }
  }

  getDefaultDateFirebase() {
    return firebase.firestore.Timestamp.fromDate(new Date());
  }

  getDefaultDateFirebaseFromDate(date) {
    return firebase.firestore.Timestamp.fromDate(new Date(date));
  }

  deleteFieldFirestore() {
    return firebase.firestore.FieldValue.delete();
  }

  fromNow(time) {
    const unixTime = new Date(time).getTime();
    if (!unixTime) {
      return '';
    }
    const now = new Date().getTime();
    let timeValue: any = '';
    let plural: any = '';
    // Calculate difference
    let difference = unixTime / 1000 - now / 1000;
    // Calculate time unit
    difference = Math.abs(difference);
    if (difference / (60 * 60 * 24 * 365) > 1) {
      // Years
      timeValue = Math.floor(difference / (60 * 60 * 24 * 365));
      plural = timeValue > 1 ? 's' : '';
      return `${timeValue} year${plural} ago`;
    } else if (difference / (60 * 60 * 24 * 45) > 1) {
      // Months
      timeValue = Math.floor(difference / (60 * 60 * 24 * 45));
      plural = timeValue > 1 ? 's' : '';
      return `${timeValue} month${plural} ago`;
    } else if (difference / (60 * 60 * 24) > 1) {
      // Days
      timeValue = Math.floor(difference / (60 * 60 * 24));
      plural = timeValue > 1 ? 's' : '';
      return `${timeValue} day${plural} ago`;
    } else if (difference / (60 * 60) > 1) {
      // Hours
      timeValue = Math.floor(difference / (60 * 60));
      plural = timeValue > 1 ? 's' : '';
      return `${timeValue} hour${plural} ago`;
    } else {
      const hours = difference / (60 * 60);
      timeValue = Math.round(hours * 100);
      if (timeValue) {
        plural = timeValue > 1 ? 's' : '';
        return `${timeValue} minute${plural} ago`;
      }
      return `few seconds ago`;
    }
  }


  async verifyIdentity(urlRedirect:string = '') {
    const coreUser: any = await this.userService.getCurrentUser();
    const user = coreUser?.docs[0]?.data();
    const response = await this.stripeService.getIdentitySession(user?.id,urlRedirect);
    if (response?.success) {
      const url = response.payload.url;
      if (this.platformUtil.isCordova()) {
        this.initInAppBrowser(url);
      } else {
        window.location.href = url;
      }
    }
  }

  getNameFromUnloggedAsk(name) {
    if (this.validateUtils.validateEmailRegex(name)) {
      const [newName] = name.split('@');
      return newName;
    }
    return name;
  }

  async validBlockedUser(currentUserId, userId) {
    let chatUser: any = await this.userService.getById(userId);
    chatUser = {id: chatUser.id, ...chatUser.data()};

    let mainUser: any = await this.userService.getById(currentUserId);
    mainUser = {id: mainUser.id, ...mainUser.data()};

    return chatUser?.blockedUsers?.some((a) => a === currentUserId) || mainUser?.blockedUsers?.some((a) => a === userId);
  }

  async uploadVideo(data, callbackUploadProgress = null) {
    if (data) {
      const {video, image} = data;
      const {html5Server} = await this.addpipeService.getAddpipeValues(
        environment.addpipe.eid,
        environment.addpipe.accountHash
      );
      const uploadType = this.platformUtil.isDesktop() ? 'deskUp' : 'mobUp';

      const upload = await this.addpipeService.postVideoAddPipe(
        html5Server,
        environment.addpipe.eid,
        environment.addpipe.accountHash,
        new File([video], `${new Date().valueOf().toString()}.MP4`),
        uploadType,
      );
      const videoUrl =
        `https://storage.googleapis.com/${environment.firebaseConfig.storageBucket}/${PathStorage.dareProofVideos}/${upload.f}`;

      let imageUrl = null;

      if (image) {
        const [name, type] = upload.f.split('.');
        const pathImage = `${PathStorage.dareProofVideos}/${name}_thumb.jpg`;
        imageUrl = await this.mediaService.uploadFileFirebase(
          pathImage,
          image
        );
      }
      return {videoUrl, imageUrl: imageUrl ?? videoUrl.toString().replace('.mp4', '.jpg')};
    }
  }

  async uploadImage(file, name) {
    const pathImage = `${PathStorage.dareProofVideos}/${name}.jpg`;
    const imageUrl = await this.mediaService.uploadFileFirebase(
      pathImage,
      file
    );
    return {imageUrl, videoUrl: null};
  }

  async uploadFile(file, name) {
    const pathImage = `${PathStorage.dareProofVideos}/${name}`;
    const url = await this.mediaService.uploadFileFirebase(
      pathImage,
      file
    );
    return {url};
  }

  isBase64(str) {
    if (str === '' || str.trim() === '') {
      return false;
    }
    try {
      // eslint-disable-next-line eqeqeq
      return btoa(atob(str)) == str;
    } catch (err) {
      return false;
    }
  }

  async uploadImageFromChat(message, chatId) {
    const file = message?.imagePreview?.blob;
    const nameFile = `${new Date().valueOf()}_${file?.name}`;
    const mediaResponse = await this.uploadImage(file, nameFile);

    message.type = 'image';
    message.media = {
      ...mediaResponse,
      verifyImage: true,
    };
    delete message.videoPreview;
    delete message.imagePreview;
    delete message.filePreview;
    delete message.chatId;
    await this.messageService.saveWithId(chatId, message);
    this.emitEventService.publishDataUploadProgress(null);
    this.createNotificationOneSignal(message);
    if (message.sendTo.id === 'admin') {
      return;
    }
    // if (this.validateUtils.validateEmailRegex(message.sendTo.id)) {
    this.userService.sendEmail({
      to: message.sendTo.email,
      subject: ``,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      template_id: 'd-ca4debf9f2ba4b64a85276e553a9c217',
      hideWarning: true,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      dynamic_template_data: {
        title: `${message.sendBy.name} sent you a image!`,
        image: message?.media?.imageUrl,
        video: message?.media?.imageUrl,
        subtitle: `${message.text}`,
        username: message.sendBy.name,
        redirectLink: this.validateUtils.validateEmailRegex(message.sendTo.id) ?
          `${window.location.origin}/signup?email=${encodeURIComponent(message.sendTo.email)}` :
          window.location.origin,
      }
    });
    // }
  }

  async uploadVideoFromChat(message, video, image, chatId) {
    if (message) {
      const {html5Server} = await this.addpipeService.getAddpipeValues(
        environment.addpipe.eid,
        environment.addpipe.accountHash
      );
      const uploadType = this.platformUtil.isDesktop() ? 'deskUp' : 'deskUp';

      let fileName = null;
      let file = new File([video], `${new Date().valueOf().toString()}.MP4`);
      if (this.platformUtil.isCordova()) {
        file = video;
        fileName = `${new Date().valueOf().toString()}.MP4`;
      }

      const upload = await this.addpipeService.postVideoAddPipe(
        html5Server,
        environment.addpipe.eid,
        environment.addpipe.accountHash,
        file,
        uploadType,
        fileName
      );
      const videoUrl =
        `https://storage.googleapis.com/${environment.firebaseConfig.storageBucket}/${PathStorage.dareProofVideos}/${upload.f}`;

      let imageUrl = null;

      if (image) {
        const [name, type] = upload.f.split('.');
        const pathImage = `${PathStorage.dareProofVideos}/${name}_thumb.jpg`;
        imageUrl = await this.mediaService.uploadFileFirebase(
          pathImage,
          image
        );
      }

      message.type = 'video';
      message.media = {
        videoUrl,
        imageUrl: imageUrl ?? videoUrl.toString().replace('.mp4', '.jpg'),
        verifyImage: true,
      };
      delete message.videoPreview;
      delete message.imagePreview;
      delete message.filePreview;
      delete message.chatId;
      await this.messageService.saveWithId(chatId, message);
      this.emitEventService.publishDataUploadProgress(null);
      this.createNotificationOneSignal(message);
      // if (this.validateUtils.validateEmailRegex(message.sendTo.id)) {
      if (message.sendTo.id === 'admin') {
        return;
      }
      this.userService.sendEmail({
        to: message.sendTo.email,
        subject: ``,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        template_id: 'd-bac774ba46ae45e78db74680c1e9ef31',
        hideWarning: true,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        dynamic_template_data: {
          title: `${message.sendBy.name} sent you a video!`,
          image: message?.media?.imageUrl,
          video: message?.media?.videoUrl,
          subtitle: `${message.text}`,
          username: message.sendBy.name,
          redirectLink: this.validateUtils.validateEmailRegex(message.sendTo.id) ?
            `${window.location.origin}/signup?email=${encodeURIComponent(message.sendTo.email)}` :
            window.location.origin,
        }
      });
      // }
    }
  }

  async uploadFileFromChat(message, chatId) {
    const file = message?.filePreview?.blob;
    const nameFile = `${new Date().valueOf()}_${file?.name}`;
    const mediaResponse = await this.uploadFile(file, nameFile);
    message.type = 'file';
    message.media = {
      ...mediaResponse,
      name: file?.name,
      verifyImage: false,
    };
    delete message.videoPreview;
    delete message.imagePreview;
    delete message.filePreview;
    delete message.chatId;
    await this.messageService.saveWithId(chatId, message);
    this.emitEventService.publishDataUploadProgress(null);
    this.createNotificationOneSignal(message);
    // if (message.sendTo.id === 'admin') {
    //   return;
    // }
  }

  validTextMore(bio, minLetter = 3, maxLetter = 14) {
    const optionsBio = {
      largeText: bio,
      shortText: '',
      text: ''
    };
    const splitbio = bio?.split(' ');
    let newBio = '';
    let hasSplitBio = false;
    if (splitbio) {
      hasSplitBio = splitbio?.every((a, index) => {
        const spaces = a?.split('\n');
        if (spaces?.length > minLetter || index > maxLetter) {
          return false;
        } else {
          newBio += `${a} `;
          return true;
        }
      });
    }
    optionsBio.shortText = !hasSplitBio ? newBio : null;
    optionsBio.text = optionsBio.shortText || optionsBio.largeText;
    return optionsBio;
  }

  bytesToMegaBytes = (bytes) => bytes / (1024 ** 2);

  validFileSize(file, size = environment?.limitFileSizeUpload) {
    if (this.bytesToMegaBytes(file?.size) > size) {
      this.globalToast(`Video is too large. Max video size is ${size}MB.`, ColorToast.dark, 5000);
      return false;
    }
    return true;
  }

  setScrollbarsContent(id) {
    try {
      const elmt: any = document.querySelector(`#${id}`);
      const stylesheet = `
    @media only screen and (min-width: 500px) {
     ::-webkit-scrollbar-track {
      //-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      border-radius: 10px;
       background: #666677;
      // background-color: #eff7f8;
      // background-color: rgb(0, 0, 0, 0.1);
      overflow-x: auto;
      display: none;
      cursor:pointer;
    }

    ::-webkit-scrollbar {
      width: 8px;
      height: 8px;
      // background-color: transparent;
      background-color: #fff3;
      overflow-x: auto;
      display: block !important;
      cursor: pointer;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 10px;
      //-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
      background-color: #83b9d5;
      overflow-x: auto;
      cursor: e-resize;
    }
  }
    `;
      const styleElmt = elmt.shadowRoot.querySelector('style');
      if (styleElmt) {
        styleElmt?.append(stylesheet);
      } else {
        const barStyle = document.createElement('style');
        barStyle?.append(stylesheet);
        elmt?.shadowRoot?.appendChild(barStyle);
      }
    } catch (e) {
      console.log('e setScroll', e);
    }
  }

  createNewUrl(url) {
    url = url.replace('http://', '');
    url = url.replace('https://', '');
    return `https://${url}`;
  };

  createNotificationOneSignal(messageObject) {
    let message = '';
    if (messageObject?.type === 'video') {
      message = '🎬 Video';
    } else if (messageObject?.type === 'image') {
      message = '📷 Image';
    } else if (messageObject?.type === 'file') {
      message = '📂 File';
    }
    this.notificationService.createOnesignalNotification({
      title: messageObject?.sendBy?.name,
      subTitle: message,
      userName: messageObject?.sendTo?.name,
      large_icon: messageObject?.sendBy?.photo,
      big_picture: '',
      params: {page: 'chat', ref: messageObject?.sendBy?.id}
    });
  }

  setTitlePage(title: string = 'Truly App') {
    this.title.setTitle(title);
  }

  async modalOptionsDefault(props: PropsModalDefault): Promise<any> {
    return new Promise<any>(async resolve => {
      try {
        const isIosPwa = this.platformUtil.isPwa() && this.platformUtil.isIos();
        const cssClass = isIosPwa ? ['default-options-modal', 'margin-bottom-pwa'] : 'default-options-modal';
        const modal = await this.modalCtrl.create({
          component: ModalOptionsDefaultPage,
          backdropDismiss: true,
          showBackdrop: true,
          mode: 'md',
          cssClass,
          componentProps: {props},
        });
        modal.onDidDismiss().then((response) => resolve(response?.data));
        await modal.present();
      } catch (e) {
        console.log('e', e);
      }
    });
  }

  async validFollow(ownerId, followedById) {
    const follow = (await this.followsService?.getExistFollow(ownerId, followedById)).docs?.map(a => ({id: a?.id, ...a?.data() as Follow}));
    return this.getFirstElement(follow);
  };

  initInAppBrowser(url) {
    const browser: InAppBrowserObject = this.iab.create(
      url,
      '_blank',
      {location: 'no'}
    );
    browser.on('loadstart').subscribe(event => {
      if (event?.url.match('localhost') || event?.url.match('jointruly')) {
        browser.close();
        this.emitEventService.publishUpdateMyprofile(true);
        console.log('updateeeeeeee');
      }
    });
  }

  showAlertStripe() {
    this.alertDefault({
      header: 'Verify to Become a Coach',
      message: 'To start coaching on Truly, verify your account with Stripe, our secure payment platform. This is essential for identity verification and to enable you to receive payments from clients.',
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
        },
        {
          text: 'Verify Now',
          handler: () => this.loadVerifyIdentity(),
        }
      ]
    });
  }


  async loadVerifyIdentity() {
    let loading = null;
    try {
      loading = await this.loadingCtrl.create({
        message: 'Redirecting to Stripe...',
      });
      loading.present();
      await this.verifyIdentity();
    } catch (e) {
      console.log('verifyIdentity', e);
    } finally {
      loading?.dismiss();
    }
  }

  async getInfoRating(userId): Promise<Avaliation> {
    const ratings = (await this.ratingService.getChatRatingByUser(userId))?.docs?.map(a => (a?.data() as any));
    const quantityAvaliation = ratings?.length || 0;
    let valueAvaliation = 0;
    if (ratings?.length > 0) {
      const total = ratings?.map(a => a?.value)?.reduce((a, b) => a + b);
      valueAvaliation = Math.round(total / quantityAvaliation);
    }
    return {quantityAvaliation, valueAvaliation};
  }

  onCloseModal() {
    this.emitEventService.getObservableCloseModal()
      .subscribe(async (data) => {
        if (this.modalPush) {
          this.modalPush.dismiss(null);
        }
      });
  }

  setUpdateHome() {
    this.storage().set('hasUpdateHome', true);
  }

  setUpdateMyProfile() {
    this.storage().set('hasUpdateMyProfile', true);
  }

  formateTimeMinutes(time) {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? 0 : ''}${seconds}`;
  }

  async modalInfoLogin() {
    try {
      return new Promise(async (resolve) => {
        const modal = await this.modalCtrl.create({
          component: ModalInfoLoginPage,
          swipeToClose: true,
          backdropDismiss: false,
          showBackdrop: true,
          mode: 'md',
          cssClass: 'modal-info-login',
        });
        modal.onDidDismiss().then((response) => resolve(response?.data));
        await modal.present();
      });
    } catch (e) {
      console.log('erro onboarding profile');
    }
  }
}
