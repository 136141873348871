import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {OptionIconType} from '../../pages/modal-options-default/modal-options-default.page';

@Component({
  selector: 'app-empty-message-content',
  templateUrl: './empty-message-content.component.html',
  styleUrls: ['./empty-message-content.component.scss'],
})
export class EmptyMessageContentComponent implements OnInit {

  @Output()
  onClickLink = new EventEmitter();

  @Input()
  title: string;

  @Input()
  subTitle: string;

  @Input()
  icon: string;

  @Input()
  linkText: string;

  @Input()
  isLoading: boolean;

  @Input()
  iconType: OptionIconType  = OptionIconType.ionicIcon;

  constructor() { }

  ngOnInit() {}

  clickLink(){
    this.onClickLink.emit();
  }

}
