import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Tag} from '../../../models/tag';
import {NavigationExtras, Router} from '@angular/router';
import {TagService} from '../../../services/tag.service';

@Component({
  selector: 'app-itens-menu-scroll',
  templateUrl: './items-menu-scroll.component.html',
  styleUrls: ['./items-menu-scroll.component.scss'],
})
export class ItemsMenuScrollComponent implements OnInit {

  @Input()
  public chunkedCategories: Array<Array<Tag>>;

  @Input()
  categories: Array<any>;

  @Input()
  isTrending = false;

  @Input()
  isLoading = false;

  @Output()
  public onClickCategory = new EventEmitter();

  public loadChunkedCategories: Array<any>;

  // colors = ['#0095CF', '#00CF21', '#EF8100', '#CF003E', '#8000CF', '#0095CF', '#3CB5F3', '#00B6CF', '#0064A7', '#3365C6', '#0C899A', '#1F5579', '#8D8D8D'];
  // colors = ['#0095CF', '#00CF21', '#EF8100', '#CF003E', '#8000CF', '#0095CF', '#fff'];
  colors = ['#0095CF', '#3CB5F3', '#00B6CF', '#0064A7', '#1F5579', '#00C5E0', '#8D8D8D'];
  decorationColors = ['#ffffff1a', '#ffffff1a', '#ffffff1a', '#ffffff1a', '#ffffff1a', '#ffffff1a', '#ffffff1a'];
  iconColors = ['#fff', '#fff', '#fff', '#fff', '#fff', '#fff', '#fff'];

  constructor(private router: Router,
              private tagService: TagService) {
  }

  ngOnInit() {
  }

  goToCategory(tag: Tag, color: string, decorationColor: string) {
    this.onClickCategory.emit({...tag, color, decorationColor});
  }


  getBackgroundColor(index: number) {
    if (index === this.categories.length - 1) {
      return this.colors[this.colors.length - 1];
    } else {
      return this.colors[index % (this.colors.length - 1)];
    }
  }

  getBackDecoration(index: number) {
    if (index === this.categories.length - 1) {
      return this.decorationColors[this.decorationColors.length - 1];
    } else {
      return this.decorationColors[index % (this.decorationColors.length - 1)];
    }
  }

  getIconColor(index: number) {
    if (index === this.categories.length - 1) {
      return this.iconColors[this.iconColors.length - 1];
    } else {
      return this.iconColors[index % (this.iconColors.length - 1)];
    }
  }
}
