import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-load-video-profile',
  templateUrl: './load-video-profile.component.html',
  styleUrls: ['./load-video-profile.component.scss'],
})
export class LoadVideoProfileComponent implements OnInit {


  public arrayQuantity: any;
  constructor() {
    this.arrayQuantity = new Array( 5);
  }

  ngOnInit() {}

}
