import { Component, OnInit } from '@angular/core';
import {ModalController} from "@ionic/angular";
import {Router} from "@angular/router";
import {EmitEventService} from "../../services/emit-event.service";

@Component({
  selector: 'app-modal-info-login',
  templateUrl: './modal-info-login.page.html',
  styleUrls: ['./modal-info-login.page.scss'],
})
export class ModalInfoLoginPage implements OnInit {

  constructor(private modalCtrl: ModalController,
              private router: Router,) { }

  ngOnInit() {
  }

  navigateToLogin() {
    this.close();
    this.router.navigate(['login'], {replaceUrl: true});
  }

  close() {
    this.modalCtrl.dismiss();
  }

}
