<ion-content>
  <div class="main-wrapper">
    <div class="container">
      <ion-icon (click)="close()"  class="icon" name="close-outline"></ion-icon>
      <div class="wrapper">
        <div class="title">
          <!-- Are you a coach? -->
          <!-- Become a Coach -->
          <!-- Help Others & Earn -->
          <!-- Start Earning -->
          Let others know about you
        </div>
        <div class="subTitle">
          For Coaches and Coachees
        </div>
        <div class="ctr-button">
          <ion-button (click)="start()" class="button secundary-color">
            <ion-label style="font-size: 22px;padding: 0px 24px;">
              <!-- Setup -->
              <!-- Create Your Profile -->
              Edit Profile
            </ion-label>
          </ion-button>
        </div>
        <div (click)="close()" class="skip">Skip</div>
      </div>
    </div>
  </div>
</ion-content>
