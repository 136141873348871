import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, NavigationExtras, Router} from '@angular/router';
import {ActionSheetController, IonContent, LoadingController, ModalController, NavController,} from '@ionic/angular';
import {User} from 'src/app/models/user';
import {ChatService} from 'src/app/services/chat.service';
import {MessageService} from 'src/app/services/message.service';
import {UserService} from 'src/app/services/user.service';
import {NormalizeDataUtil} from 'src/app/utils/normalize-data.util';
import {SendAskPage} from '../send-ask/send-ask.page';
import {AskUtil} from 'src/app/utils/ask.util';
import {AskHelper} from 'src/app/helpers/ask.helper';
import {DefaultUtil} from '../../utils/default.util';
import {FeedService} from '../../services/feed.service';
import {AskService} from '../../services/ask.service';
import {environment} from 'src/environments/environment';
import {RatingsService} from '../../services/ratings.service';
import {ModalContestPage} from '../modal-contest/modal-contest.page';
import {NotificationService} from '../../services/notification.service';
import {ValidatesUtil} from '../../utils/validates.util';
import {ColorToast} from '../../enums/color-toast';
import {ModalChatOptionsPage} from '../modal-chat-options/modal-chat-options.page';
import {DomSanitizer} from '@angular/platform-browser';
import {AnalyticsEvents} from '../../enums/events';
import {AnalyticsUtil} from '../../utils/analytics.util';
import {MediaUtil} from '../../utils/media.util';
import {PlatformUtil} from '../../utils/platform.util';
import {OptionIconType} from '../modal-options-default/modal-options-default.page';
import {OptionModal} from '../../components/post/post.component';
import {ModalSendMoneyPage} from '../modal-send-money/modal-send-money.page';
import {EmitEventService} from '../../services/emit-event.service';
import {PreviousRouteService} from '../../services/previous-route.service';
import {ModalSendRequestSessionPage} from '../modal-send-request-session/modal-send-request-session.page';
import {Rating} from '../../models/rating.model';
import {HttpHelper} from '../../helpers/http.helper';

export enum OptionsChat {
  sendAsk = 'Ask Your Coach',
  sendFile = 'Send File',
  tipYourCoach = 'Tip Your Coach',
  resquestVideoCall = 'Request Video Call',
  close = 'Close',
}

export enum OptionsIconButton {
  resquestVideoCall = '1on1',
  sendAsk = 'Ask',
  tipYourCoach = 'Tip',
}

export enum OptionsChatUser {
  shareProfile = 'Share Profile',
  unblock = 'Unblock',
  block = 'Block',
  report = 'Report',
}


@Component({
  selector: 'app-chat',
  templateUrl: './chat.page.html',
  styleUrls: ['./chat.page.scss'],
})
export class ChatPage implements OnInit {
  @ViewChild('contentChat', {static: false}) contentChat: IonContent;
  @ViewChild('chatMessageInput', {read: ElementRef}) chatMessageInput: ElementRef;

  userId: string = null;
  chatId: string = null;
  messageId: string = null;

  enableFooter = false;
  user = {} as any;
  userLogged = {} as any;
  currentUser = {} as User;

  chat: any[] = [];

  imagePreview: any = null;
  videoPreview: any = null;
  filePreview: any = null;

  formGroup: FormGroup;

  showTodayLabel = false;
  scrollHeight = 0;
  showYesterdayLabel = false;
  lastVisible = null;

  unSubscribeChat = null;
  unSubscribeTyping = null;
  unSubscribeTipModal = null;
  unSubscribeAskModal = null;
  timeoutTyping = null;
  interval = [];
  isBlocked = null;
  labelTyping = '';
  enableInfinite = false;
  public isTyping: any;

  requestSendAsk = false;
  requestSendSession = false;
  paramsMessage: string = null;
  unloggedChat = false;
  message = '';
  openTipModalOnload = false;
  isPwaIos = false;
  isLoading = false;
  countFirstCall = 0;
  unseenMessages = [];
  scrollTopChat = 0;
  isAtTheBottom = false;
  firstLoad = true;
  firstUnseenMessages = [];
  iconButtons = [
    {
      name: OptionsIconButton?.resquestVideoCall,
      icon: 'people',
      color: '#00B6CF',
    },
    {
      name: OptionsIconButton?.sendAsk,
      icon: 'chatbubble',
      color: '#0095CF',
    },
    {
      name: OptionsIconButton?.tipYourCoach,
      icon: 'wallet',
      color: '#0064A7',
    },

  ];
  hideHeader = true

  expiredStatus: any = {
    accepted: 'taken_time_expired',
    sent: 'accept_expired',
    confirm: 'auto' + 'confirmed',
    contested: 'contestedcancel',
  };

  pagination = {start: 1, end: 40, limit: 40, enable: false};
  chatToPaginate = []

  constructor(
    private loadingCtrl: LoadingController,
    private route: ActivatedRoute,
    private router: Router,
    private askUtil: AskUtil,
    private askHelper: AskHelper,
    private askService: AskService,
    private normalizeDataUtil: NormalizeDataUtil,
    private navCtrl: NavController,
    private userService: UserService,
    private chatService: ChatService,
    private messageService: MessageService,
    private modalController: ModalController,
    private fb: FormBuilder,
    private changeDetector: ChangeDetectorRef,
    public actionSheetController: ActionSheetController,
    public defaultUtils: DefaultUtil,
    public feedService: FeedService,
    public ratingService: RatingsService,
    public modalCtrl: ModalController,
    private notificationService: NotificationService,
    private validateUtils: ValidatesUtil,
    private domSanitizer: DomSanitizer,
    private analyticsUtil: AnalyticsUtil,
    private mediaUtil: MediaUtil,
    private platformUtil: PlatformUtil,
    private loadinController: LoadingController,
    private emitEventService: EmitEventService,
    private previousRouteService: PreviousRouteService,
    private httpHelper: HttpHelper,
  ) {
    this.isTyping = false;
    this.route.queryParams.subscribe((params) => {
      if ('deposit' in params) {
        if (params.deposit === 'true') {
          this.defaultUtils.globalToast(
            ' Credit added to your account. ',
            ColorToast.success,
            10000
          );
        }

        if (params.deposit === 'false') {
          this.defaultUtils.globalToast(
            ' Something went wrong ',
            ColorToast.danger,
            10000
          );
        }
      }

      if (params) {
        this.userId = params?.id;
        this.messageId = params?.messageId;
        this.defaultUtils.storage().set('chatId', this.userId);
      }
      if (params && params.open && params.open === 'send-ask') {
        this.requestSendAsk = true;
        this.paramsMessage = params.message;
      }
      if (params && params.open && params.open === 'tip-modal') {
        this.openTipModalOnload = true;
      }
      if (params && params.open && params.open === 'send-session') {
        this.requestSendSession = true;
      }
    });
  }

  async ngOnInit() {
    this.currentUser = await this.defaultUtils.getUser();
    this.createFormGroup();
    await this.controlUsersBlocked();
    this.loadData();
    this.listenClipBoardEvent();
    // this.defaultUtils.setScrollbarsContent('chat');
    this.listernerOpenTipModal();
    this.listernerOpenSendAskModal();
    this.isPwaIos = this.platformUtil.isPwa() && this.platformUtil.isIos();

  }

  listenClipBoardEvent() {
    window.addEventListener('paste', async (e: ClipboardEvent) => {
      if (this.router.url.includes('/chat')) {
        const file = this.defaultUtils.getFirstElement(e.clipboardData.files);
        if (file) {
          this.setFile({
            url: await this.mediaUtil.blobToBase64(file),
            blob: file
          });
          navigator.clipboard.readText().then(text => {
            this.message = this.message.replace(text, '');
          });
        }
      }
    });
  }

  getScrollHeightChat() {
    const scrollElement = document.querySelector('.cont');
    return scrollElement?.getBoundingClientRect()?.height;
  }

  ionViewDidEnter() {
    const self = this;
    document.onkeypress = (e) => {
      if (e.charCode === 13) {
        this.message = this.message.replace(/\n/g, '');
        self.sendMessage();
        return;
      }
    };
  }

  createFormGroup() {
    this.formGroup = this.fb.group({
      message: ['', Validators.required],
    });
  }

  goBack() {
    this.unSubscribeTipModal.unsubscribe();
    this.unSubscribeAskModal.unsubscribe();
    if (this.previousRouteService?.getPreviousUrl()) {
      this.navCtrl.back();
      return;
    }
    this.router.navigateByUrl('/tabs/chat-list', {replaceUrl: true});
  }

  async controlUsersBlocked() {
    this.isBlocked = await this.defaultUtils.validBlockedUser(this.currentUser.id, this.userId);
    return this.isBlocked;
  }

  async loadData() {
    if (!this.userId) {
      this.goBack();
      return;
    }
    this.userLogged = await this.defaultUtils.getUser();
    if (this.userId === 'truly-admin') {
      this.user = {
        id: 'admin',
        name: environment.adminName,
        photoURL: environment.adminPhotoURL,
      };
      this.analyticsUtil.registerEvent(AnalyticsEvents.openAdminChat, this.analyticsUtil.defaultAnalyticsContent());
    } else if (this.validateUtils.validateEmailRegex(this.userId)) {
      this.user = {
        id: this.userId,
        name: this.defaultUtils.getNameFromUnloggedAsk(this.userId),
        photoURL: '',
        email: this.userId,
      };
      this.unloggedChat = true;
    } else {
      const user: any = await this.userService.getById(this.userId);
      if (!user.exists) {
        this.goBack();
        return;
      }
      this.user = {id: user.id, ...user.data()};
    }
    await this.getChats(true);
    if (this.openTipModalOnload) {
      this.modalSendMoney();
    }
    // setTimeout(() => {
    //   this.scrollToId(`msg-${this.messageId}`);
    // }, 1800);

  }

  async getChats(firstCall, event = null) {
    const limit = 200;
    const chat: any = await this.chatService.getByUserIdAndUserReference(
      this.currentUser.id,
      this.userId === 'truly-admin' ? 'admin' : this.user.id
    );

    if (chat.empty) {
      this.navCtrl.back();
      return;
    }

    this.chatId = chat.docs[0].data().chatId;
    const chatMessages = await this.messageService.getMessagesByChatId2(
      this.chatId,
      limit,
      this.lastVisible
    );

    this.lastVisible = chatMessages.docs[chatMessages.docs.length - 1];
    let normalizedChat = this.normalizeDataUtil.normalizeArrayData(
      chatMessages.docs
    );
    let askVideos: any = await this.feedService.getVideoProofOwner(
      this.currentUser.id
    );

    askVideos = askVideos.docs.map((a) => ({
      id: a?.id,
      ...a?.data(),
    }));

    let ratingVideos: any = await this.ratingService.getChatRating(this.chatId);
    ratingVideos = ratingVideos.docs.map((a) => ({
      id: a?.id,
      ...a?.data(),
    }));

    if (askVideos.length) {
      askVideos = askVideos.sort((a, b) => a.owner - b.owner);
      askVideos = Object.values(
        askVideos.reduce((a, b) => Object.assign(a, {[b.media.id]: b}), {})
      );
    }

    for (let chatMessage of normalizedChat) {
      chatMessage = this.verifyTodayAndYesterdayChat(chatMessage);
      this.messageService.updateMessage(this.chatId, chatMessage.id, {
        readed: true,
      });
    }

    for (const chatItem of normalizedChat) {
      if (chatItem.type === 'ask' || chatItem.type === 'dare') {
        chatItem.shared = askVideos.some(
          (y) => y.media.id === chatItem.id && !y.isPrivate
        );
        chatItem.rated = ratingVideos.find((a) => a.ask.messageId === chatItem.messageId) || null;
        chatItem.actions = this.normalizeAskStatusMessage(
          chatItem.dare.status,
          chatItem.sendBy.name,
          chatItem.sendTo.name,
          chatItem.shared,
          chatItem.rated
        ).actions;
        this.countdown(chatItem);
      }
      if (chatItem?.type === 'video-call') {
        chatItem.actions = this.normalizeAskStatusMessageVideoCall(
          chatItem?.videoCall?.status,
          chatItem.sendBy.name,
          chatItem.sendTo.name,
          chatItem?.shared,
          chatItem?.rated
        ).actions;
      }
      this.enableFooter = true;
    }

    this.scrollHeight = this.getScrollHeightChat();
    if (firstCall) {
      normalizedChat = normalizedChat.sort((x, y) => x.updatedAt - y.updatedAt);
      this.chatToPaginate = normalizedChat;
      await this.initRealtime(this.chatId);
      this.scrollToFirstUnreadMessages(this.chatToPaginate );
      this.typingRealtime(this.chatId);
      this.controlUsersBlocked();
      if (this.requestSendAsk) {
        this.sendAsk(this.paramsMessage);
      }
      if (this.requestSendSession) {
        this.sendRequestSession();
      }
    }
    this.chatToPaginate = await Promise.all(this.chatToPaginate.map(a => this.validUrlText(a)));
    this.enableInfinite = !!normalizedChat.length;
    this.setLocalPagination();
  }

  scrollToFirstUnreadMessages(chats) {
    let firstUnreadedMessage = null;
    chats = chats?.filter(a => !a?.readed);
    if (chats?.length > 8) {
      this.firstUnseenMessages = chats;
      firstUnreadedMessage = this.defaultUtils.getFirstElement(
        chats?.map(a => ({
          ...a,
          target: this.getScrollRef(`msg-${a?.messageId}`)
        }))?.sort((x, y) => x.updatedAt - y.updatedAt)
      );
      this.contentChat.scrollToPoint(null, firstUnreadedMessage?.target, 500);
      return;
    }
    this.scrollToBottom(500);
    console.log('firstUnreadedMessage>>>>>>>', firstUnreadedMessage);
  }

  async validUrlText(chatItem) {
    if (!chatItem?.linkOptions?.linkVerified) {
      const text = chatItem?.dare?.name || chatItem?.text;
      let linkText: any = '';
      let userWithLink: any = '';
      const splitedText = text?.split(' ') || null;
      const onlyLink = splitedText?.filter(a => a)?.length === 1;
      for (const i in splitedText) {
        if (!userWithLink) {
          userWithLink = await this.getInternalUser(splitedText[i]?.toLowerCase());
        }
        splitedText[i] = await this.addHrefLink(chatItem, splitedText[i]?.toLowerCase(), userWithLink);
        linkText += splitedText[i];
      }
      linkText = this.domSanitizer.bypassSecurityTrustHtml(linkText);

      return {
        ...chatItem,
        linkOptions: {
          linkText,
          userWithLink,
          linkVerified: true,
          onlyLink,
          hasLink: splitedText?.some(a => a.match('<a href=')),
        }
      };
    }
    return chatItem;
  }

  async getInternalUser(message) {
    if (this.validateUtils.validUrlRegex(message) && this.validateUtils.validDomainRegex(message)) {
      let user = this.validateUtils.validInternalLinkProfile(message, this.defaultUtils);
      if (user) {
        user = await this.userService.getUserByUsername(user);
        user = user.docs.map((a) => ({id: a?.id, ...a?.data()})).find(a => a?.id);
        return user;
      }
      return null;
    }
    return null;
  }

  async addHrefLink(data, message, isInternalUser) {
    if (this.validateUtils.validUrlRegex(message) && this.validateUtils.validDomainRegex(message)) {
      // const internalUser = this.validateUtils.validInternalLinkProfile(message, this.defaultUtils);
      const color = data?.sendBy?.id === this.currentUser.id ? '#3880ff' : '#fff';
      if (isInternalUser) {
        return `<span class="fake-link" style="color: ${color}; cursor: pointer;text-decoration: underline;" >${message}</span> `;
      }
      return `<a href='${this.defaultUtils.createNewUrl(message)}'
               style="color:${color}"
               target='_blank'
               rel='noopener noreferrer'
               style='cursor: pointer'>${message}</a> `;
    }
    return `${message} `;
  }

  getColSize(actions = []) {
    return actions.length / 12;
  }

  getLocalFeed(message) {
    return {
      sendTo: {
        photo: message?.sendTo?.photo,
        id: message?.sendTo?.id,
        email: message?.sendTo?.email,
        name: message?.sendTo?.name
      },
      sendBy: {
        email: message?.sendBy?.email,
        photo: message?.sendBy?.photo,
        name: message?.sendBy?.name,
        id: message?.sendBy?.id
      },
      user: {
        id: message?.sendTo?.id,
        name: message?.sendTo?.name,
        photo: message?.sendTo?.photo,
      },
      media: {
        type: 'video',
        id: '',
        duration: message?.dare?.dareMedia?.duration,
        url: message?.dare?.dareMedia?.videoUrl || message?.media?.videoUrl,
        name: message?.dare?.name,
        amount: message?.dare?.amount,
      },
      id: 'rFWw7S20QtW9Sk6yBK8l',
      isPrivate: false,
      updatedAt: this.defaultUtils.getDefaultDateFirebase(),
      createdAt: this.defaultUtils.getDefaultDateFirebase(),
      updateDateTime: new Date().getTime(),
      owner: true
    };
  }

  async navigateToPlayer(message) {
    const loading = await this.loadingCtrl.create({message: 'Loading...'});
    try {
      loading.present();
      const post = await this.feedService.getPostFeed(message.sendTo?.id, message?.id);
      if (post?.size) {
        this.router.navigateByUrl(`watch?ref=${post?.docs[0]?.id}&showActions=${false}`);
        return;
      } else {
        this.defaultUtils.storage().set('localFeed', this.getLocalFeed(message));
        this.router.navigateByUrl(`watch?showActions=${false}`);
      }
      loading.dismiss();
    } catch (e) {
      console.log('e', e);
    } finally {
      loading.dismiss();
    }
  }

  async timeout(time) {
    return new Promise((resolve) => setTimeout(() => resolve(true), time));
  }

  verifyEqualDate(index) {
    if (index === 0 || index + 1 === this.chat.length) {
      return false;
    }

    const verifyChat = new Date(this.chat[index + 1].timestamp);
    const currentChat = new Date(this.chat[index].timestamp);

    // eslint-disable-next-line max-len
    if (
      verifyChat.getDate() === currentChat.getDate() &&
      verifyChat.getHours() === currentChat.getHours() &&
      verifyChat.getMinutes() === currentChat.getMinutes()
    ) {
      return true;
    }

    return false;
  }

  async sendAsk(message = null) {
    const modal = await this.modalController.create({
      component: SendAskPage,
      cssClass: 'small-modal',
      componentProps: {
        selectedUser: this.user,
        isChatPage: true,
        question: message || '',
        redirectUrl: `/chat?id=${this.userId}&open=send-ask`,
      },
      backdropDismiss: true,
    });
    modal.onDidDismiss().then(() => this.controlUsersBlocked());
    return await modal.present();
  }

  async sendRequestSession(message = null) {
    const modal = await this.modalController.create({
      component: ModalSendRequestSessionPage,
      cssClass: 'small-modal',
      componentProps: {
        selectedUser: this.user,
        isChatPage: true,
        question: message || '',
        redirectUrl: `/chat?id=${this.userId}&open=send-session`,
      },
      backdropDismiss: true,
    });
    modal.onDidDismiss().then(() => this.controlUsersBlocked());
    return await modal.present();
  }

  verifyTodayAndYesterdayChat(data) {
    data.yesterday = false;
    data.today = false;
    try {
      const now = new Date();
      const date = new Date(data.timestamp);
      const diffInMs = now.valueOf() - date.valueOf();
      const days = Math.round(diffInMs / (1000 * 60 * 60 * 24));

      if (days === 0) {
        if (now?.getDate() === date?.getDate()) {
          data.today = true;
        }
        if (now?.getDate() - 1 === date?.getDate()) {
          data.yesterday = true;
        }
      }

      if (days === 1) {
        if (now.getDate() - 1 === date.getDate()) {
          data.yesterday = true;
        }
      }
      return data;
    } catch (e) {
      console.log('e verifyTodayAndYesterdayChat', e);
      return data;
    }
  }

  verifyTodayLabel(data, index) {
    let result = false;

    if (data.today && !this.showTodayLabel) {
      this.showTodayLabel = true;
      result = true;
    }

    if (index === this.chat.length - 1) {
      this.showTodayLabel = false;
    }

    return result;
  }

  verifyYesterdayLabel(data, index) {
    let result = false;
    if (data.yesterday && !this.showYesterdayLabel) {
      this.showYesterdayLabel = true;
      result = true;
    }

    if (index === this.chat.length - 1) {
      this.showYesterdayLabel = false;
    }

    return result;
  }

  normalizeAskStatusMessage(
    status,
    sendByUserName = null,
    sendToUserName = null,
    shared = null,
    rated = null
  ) {
    return this.askUtil.normalizeAskStatus(
      status,
      sendByUserName,
      sendToUserName,
      shared,
      rated
    );
  }


  normalizeAskStatusMessageVideoCall(
    status,
    sendByUserName = null,
    sendToUserName = null,
    shared = null,
    rated = null
  ) {
    return this.askUtil.normalizeAskStatusVideoCall(
      status,
      sendByUserName,
      sendToUserName,
      shared,
      rated
    );
  }


  getTopStatus(status) {
    return this.askUtil.validateTopStatus(status);
  }

  getContent() {
    return document.querySelector('ion-content');
  }

  enableLoadAsk(askId: any, load: boolean) {
    this.chat = this.chat.map((value) => {
      if (value.id === askId) {
        value.loading = load;
      }
      return value;
    });
  }

  setIsShared(askId: any, shared: boolean) {
    this.chat = this.chat.map((value) => {
      if (value.id === askId) {
        value.shared = shared;
        value.actions = this.normalizeAskStatusMessage(
          value.dare.status,
          value.sendBy.name,
          value.sendTo.name,
          value.shared,
          value.rated
        ).actions;
      }
      return value;
    });
  }

  async executeActionSession({action, data}) {
    this.enableLoadAsk(data?.id, true);
    await this.askHelper.executeActionRequest({
      action,
      data,
      chatId: this.chatId,
    });
    setTimeout(() => this.enableLoadAsk(data?.id, false), 2000);
  }

  async executeAction(action, data) {
    this.enableLoadAsk(data?.id, true);
    await this.askHelper.executeAction({
      action,
      data,
      chatId: this.chatId,
      callBackShare: () => data?.shared ? this.unshareproof(data) : this.shareproof(data),
      callBackRating: () => this.ratingAsk(action, data),
      callBackLoad: (enable) => this.enableLoadAsk(data?.id, enable),
      callBackReviewModal: () => this.sendReview(data?.rated, data),
      callBackScrollToBottom: () => this.scrollToBottom(100)
    });
    setTimeout(() => this.enableLoadAsk(data?.id, false), 2000);
  }

  async sendReview(review, data) {
    const reviewMessage = await this.modalPromptComponent({
      titleModal: `Make your review!`,
      titleButton: 'Send',
      titleInput: 'Review'
    });
    if (reviewMessage) {
      this.ratingService.update(review?.id, {message: reviewMessage});
      const index = this.chat.findIndex(
        (a) => a?.messageId === data?.messageId
      );
      this.chat[index].rated.message = reviewMessage;
    }
  }

  async sendMessage() {
    if (!this.videoPreview && !this.imagePreview && this.message) {
      this.chatMessageInput?.nativeElement?.focus();
    }
    const isBlocked = await this.controlUsersBlocked();
    if (isBlocked) {
      this.message = '';
      await this.defaultUtils.globalToast('You are not allowed to interact with this user!', ColorToast.danger,);
      return;
    }
    if (this.videoPreview || this.imagePreview || this.filePreview) {
      this.sendMediaMessage();
      return;
    }
    const message = this.message;
    if (!message || message.toString().trim().length === 0) {
      return;
    }

    const messageObject: any = {
      messageId: `${this.user.id}X${Date.now()}`,
      text: message,
      readed: false,
      updatedAt: this.defaultUtils.getDefaultDateFirebase(),
      createdAt: this.defaultUtils.getDefaultDateFirebase(),
      timestamp: new Date().getTime(),
      timezone: `${Intl.DateTimeFormat().resolvedOptions().timeZone}`,
      unloggedAsk: this.validateUtils.validateEmailRegex(this.user.id),
      sendBy: {
        id: this.currentUser.id,
        name: this.currentUser.name,
        photo: this.currentUser.photoURL,
        email: this.currentUser.email,
      },
      sendTo: {
        id: this.user?.id,
        name: this.user?.name,
        email: this.user?.email || '',
        photo: this.user?.photoURL,
      },
    };
    this.chatToPaginate.push({...messageObject, loading: true});
    this.message = '';
    this.message = this.message.replace(/\r?\n|\r/g, ' ');
    this.setLocalPagination(false);
    // this.scrollToBottom();
    this.scrollToBottom(100);
    await this.messageService.save(this.chatId, messageObject);
    const index = this.chatToPaginate.findIndex((x) => x.messageId === messageObject.messageId);

    if (index > -1) {
      this.chatToPaginate[this.chatToPaginate.length - 1].loading = false;
    }

    if (this.user?.id !== 'admin') {
      this.notificationService.addNotification({
        username: messageObject.sendBy.name,
        avatar: messageObject.sendBy.photo,
        sendTo: messageObject.sendTo,
        sendBy: messageObject.sendBy,
        msg: messageObject.text,
        type: 'chat',
        urlLink: '',
        readed: false,
        timestamp: this.defaultUtils.getDefaultDateFirebase(),
      });
      this.createNotificationOneSignal(messageObject);

      this.userService.sendEmail({
        to: messageObject.sendTo.email,
        subject: ``,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        template_id: 'd-46c271e3823a4c97b6ccb0e5ab9b8372',
        hideWarning: true,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        dynamic_template_data: {
          title: `${messageObject.sendBy.name} sent you a message!`,
          subtitle: `Message: ${messageObject.text}`,
          username: messageObject.sendBy.name,
          redirectLink: this.validateUtils.validateEmailRegex(messageObject.sendTo.id) ?
            `${window.location.origin}/signup?email=${encodeURIComponent(messageObject.sendTo.email)}` :
            window.location.origin,
        }
      });
    }
    setTimeout(() => this.scrollToBottom(100), 200);
  }

  createNotificationOneSignal(messageObject) {
    this.notificationService.createOnesignalNotification({
      title: messageObject?.sendBy?.name,
      subTitle: messageObject?.text,
      userName: messageObject?.sendTo?.name,
      large_icon: messageObject.sendBy.photo,
      big_picture: '',
      params: {page: 'chat', ref: messageObject?.sendBy?.id}
    });
  }

  // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
  ngOnDestroy() {
    if (this.unSubscribeChat) {
      this.unSubscribeChat.unsubscribe();
    }
    if (this.unSubscribeTyping) {
      this.unSubscribeTyping.unsubscribe();
    }

    this.defaultUtils.storage().delete('chatId');
  }

  initRealtime(chatId) {
    return new Promise(resolve => {
      this.unSubscribeChat = this.messageService
        .subscribeChat(chatId)
        .subscribe(async (values) => {
          this.defaultUtils.showPaidAnimation(values);

          let askVideos: any = await this.feedService.getVideoProofOwner(
            this.currentUser.id
          );
          askVideos = askVideos.docs.map((a) => ({
            id: a?.id,
            ...a?.data(),
          }));

          let ratingVideos: any = await this.ratingService.getChatRating(
            this.chatId
          );
          ratingVideos = ratingVideos.docs.map((a) => ({
            id: a?.id,
            ...a?.data(),
          }));

          if (askVideos.length) {
            askVideos = askVideos.sort((a, b) => a.owner - b.owner);
            askVideos = Object.values(
              askVideos.reduce(
                (a, b) => Object.assign(a, {[b.media.id]: b}),
                {}
              )
            );
          }
          if (!this.isAtTheBottom) {
            this.unseenMessages = [
              ...this.unseenMessages,
              ...(values?.filter(a => !a?.readed && a?.sendBy?.id !== this.currentUser.id))
            ];

            this.unseenMessages = this.unseenMessages.map(a => ({
              ...a,
              target: this.getScrollRef(`msg-${a?.messageId}`)
            }))?.sort((x, y) => x.updatedAt - y.updatedAt);
          }
          for (const value of values) {
            // eslint-disable-next-line @typescript-eslint/no-shadow
            const findIndex = this.chatToPaginate.findIndex(
              (x) => x.messageId === value.messageId
            );
            if (findIndex === -1) {
              this.chatToPaginate.push(value);
              if (value.type === 'ask' || value.type === 'dare') {
                this.countdown(value);
                this.enableLoadAsk(value.id, false);
              }
              if (this.currentUser.id === value.sendTo.id) {
                this.messageService.updateMessage(this.chatId, value.id, {
                  readed: true,
                });
              }
            } else {
              if (value.type === 'ask' || value.type === 'dare' || value.type === 'video' || value.type === 'image' || value.type === 'video-call') {
                this.chatToPaginate[findIndex] = null;
                this.chatToPaginate[findIndex] = value;
              }
            }
          }
          for (const value of this.chatToPaginate) {
            if (value.type === 'ask' || value.type === 'dare') {
              value.shared = askVideos.some(
                (y) => y.media.id === value.id && !y.isPrivate
              );
              value.rated = ratingVideos.find((a) => a.ask.messageId === value.messageId) ||
                null;
              value.actions = this.normalizeAskStatusMessage(
                value.dare.status,
                value.sendBy.name,
                value.sendTo.name,
                value.shared,
                value.rated
              ).actions;
              this.countdown(value);
            }
            if (value?.type === 'video-call') {
              value.actions = this.normalizeAskStatusMessageVideoCall(
                value?.videoCall?.status,
                value.sendBy.name,
                value.sendTo.name,
                value?.shared,
                value?.rated
              ).actions;
            }
            this.verifyTodayAndYesterdayChat(value);
            value.loading = false;
          }
          this.chatToPaginate.sort((x, y) => x.updatedAt - y.updatedAt);
          this.chatToPaginate = await Promise.all(this.chatToPaginate.map(a => this.validUrlText(a)));
          this.setLocalPagination()
          if (this.isAtTheBottom) {
            this.scrollToBottom(500);
          }
          this.controlUsersBlocked();
          resolve(true);
        });
    });
  }

  typingRealtime(chatId) {
    this.unSubscribeTyping = this.messageService
      .subscribeTyping(chatId)
      .subscribe((values) => {
        if (this.currentUser.id === this.chat[0].sendBy.id) {
          this.isTyping = values.isTypingTo;
        } else {
          this.isTyping = values.isTypingBy;
        }
        this.labelTyping = this.isTyping
          ? `${this.user.name} is typing...`
          : '';
        // $('.typing').css('display', this.isTyping ? 'block' : 'none');
      });
  }

  async countdown(data) {
    const timerStatus = ['sent', 'accepted', 'confirm', 'contested'].some(
      (a) => a === data?.dare?.status
    );
    this.interval.forEach((a) => {
      if (a?.id === data?.id) {
        clearInterval(a?.handle);
      }
    });
    if (!timerStatus) {
      return;
    }
    await this.defaultUtils.timeout(1000);
    this.setCountDown(data);
    const interval = setInterval(() => {
      this.setCountDown(data);
    }, 10000);
    this.interval.push({id: data?.messageId, handle: interval});
  }

  setCountDown(data) {
    if (data?.updatedAt?.toDate()) {
      const self = this;
      const countDownDate = new Date(data.updatedAt.toDate()).getTime();
      const now = new Date().getTime();

      const distance =
        countDownDate + self.askUtil.getAskTimer(data.dare.status) - now;

      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      // const seconds = Math.floor((distance % (1000 * 60)) / 1000);

      const spanCountdown = document.getElementById(
        `ask-countdown-${data.messageId}`
      );
      if (distance < 0) {
        if (spanCountdown) {
          document.getElementById(
            `ask-countdown-${data.messageId}`
          ).style.display = 'none';
        }
        if (this.expiredStatus[data?.dare?.status]) {
          this.askHelper.executeAction({
            action: {status: this.expiredStatus[data?.dare?.status]},
            data,
            chatId: this.chatId,
            callBackShare: null,
            callBackRating: null,
            callBackLoad: null
          });
        }
        this.interval.forEach((a) => {
          if (a?.id === data?.id) {
            clearInterval(a?.handle);
          }
        });
      } else {
        if (spanCountdown) {
          document.getElementById(
            `ask-countdown-${data.messageId}`
          ).textContent = `${hours}h ${minutes}m`;
          // $(`#ask-countdown-${data.messageId}`).text(`${hours}h ${minutes}m`);
        }
      }
    }
  }

  persistScroll(top) {
    if (top) {
      this.contentChat.scrollToTop(0);
    } else {
      this.contentChat.scrollToPoint(null, this.scrollHeight, 0);
      // this.contentChat.scrollToPoint(null, 0, 500);
    }
  }

  scrollToBottom(duration = 0) {
    setTimeout(() => {
      if (!this.messageId) {
        this.contentChat.scrollToBottom(duration);
      }
    }, duration);
  }

  controlTyping(event) {
    event.preventDefault();
    if (event.keyCode === 13) {
      this.message = this.message.replace(/\r?\n|\r/g, ' ');

      setTimeout(() => {
        this.message = this.message.replace(/\r?\n|\r/g, ' ');
      }, 100);

      return;
    }
    if (!this.message || this.message === '') {
      this.message = this.message.replace(/\r?\n|\r/g, ' ');
      return;
    }
    this.updateTyping(true);
    clearTimeout(this.timeoutTyping);
    this.timeoutTyping = setTimeout(() => {
      this.updateTyping(false);
    }, 1200);
  }

  updateTyping(isTyping) {
    let updateField = null;
    if (this.currentUser.id === this.chat[0].sendBy.id) {
      updateField = {isTypingBy: isTyping};
    } else {
      updateField = {isTypingTo: isTyping};
    }
    this.messageService.updateTyping(this.chatId, updateField);
  }

  async ratingAsk(action, data) {
    try {
      let rate: Rating = {
        ask: {
          chatId: data?.chatId,
          messageId: data?.messageId,
          name: data?.dare?.name,
        },
        value: action?.value,
        message: '',
        userReference: this.user.id,
        user: this.currentUser.id,
        createdAt: this.defaultUtils.getDefaultDateFirebase(),
      };
      const index = this.chat.findIndex(
        (a) => a?.messageId === data?.messageId
      );
      const response = await this.ratingService.saveRating(rate);
      rate = {
        id: response.id,
        ...rate,
      };
      this.chat[index].rated = rate;
      this.scrollToBottom(100);
    } catch (error) {
      console.log(error);
    }
  }

  async shareProofUnlooged(data) {
    this.enableLoadAsk(data?.id, true);
    await this.feedService.addProof(data, true, false);
    this.setIsShared(data?.id, true);
    this.enableLoadAsk(data?.id, false);
  }

  async unshareproof(data) {
    try {
      this.enableLoadAsk(data?.id, true);
      let post: any = await this.feedService.getPostFeed(
        this.currentUser.id,
        data.id
      );
      post = {id: post?.docs[0]?.id, ...post?.docs[0]?.data()};
      if (post?.id) {
        post.isPrivate = true;
        post.updateDateTime = new Date().valueOf();
        await this.feedService.updatePostFeed(post, post.id);
      }
      this.enableLoadAsk(data?.id, false);
      this.setIsShared(data?.id, !post.isPrivate);
    } catch (error) {
      this.enableLoadAsk(data?.id, false);
      console.log('error shared proof', error);
    }
  }

  async shareproof(data) {
    try {
      if (data?.unloggedAsk) {
        await this.shareProofUnlooged(data);
        return;
      }
      this.enableLoadAsk(data?.id, true);
      let post: any = await this.feedService.getPostFeed(
        this.currentUser.id,
        data.id
      );
      post = {id: post?.docs[0]?.id, ...post?.docs[0]?.data()};
      if (post?.id) {
        post.isPrivate = false;
        post.updateDateTime = new Date().valueOf();
        await this.feedService.updatePostFeed(post, post.id);
      } else {
        await this.feedService.addProof(data, true, false);
        await this.shareproof(data);
        return;
      }
      this.enableLoadAsk(data?.id, false);
      this.setIsShared(data?.id, !post.isPrivate);
    } catch (error) {
      this.enableLoadAsk(data?.id, false);
      console.log('error shared proof', error);
    }
  }

  async isShared(data) {
    try {
      let post: any = await this.feedService.getPostFeed(
        this.currentUser.id,
        data.id
      );
      post = post?.docs[0]?.data() || null;
      if (post) {
        return !post.isPrivate;
      }
      return false;
    } catch (error) {
      return false;
    }
  }

  async shareProfile() {
    const defaultUrl = environment.type === 'dev' ? 'jointruly.app' : 'jointruly.com';
    const shareData = {
      text: 'Ask me on Truly!\n',
      url: `https://${defaultUrl}/${this.user.name}`,
    };
    await this.defaultUtils.share(shareData, null, null, this.user);
  }

  async getIsBlock() {
    const userLogged = await this.defaultUtils.getUser();
    return userLogged?.blockedUsers?.some((a) => a === this.user.id);
  }

  async navigateToProfile(user) {
    // eslint-disable-next-line eqeqeq
    if (this.user.name == 'Truly') {
      return;
    }
    const params: NavigationExtras = {
      queryParams: {
        previousUrl: `/chat?id=${this.userId}`,
      },
    };

    this.router.navigate(['/profile/' + user.name], params);
  }


  async modalPromptComponent(props) {
    try {
      return new Promise(async (resolve) => {
        const modal = await this.modalCtrl.create({
          component: ModalContestPage,
          swipeToClose: true,
          backdropDismiss: true,
          showBackdrop: true,
          mode: 'md',
          cssClass: 'contest-modal',
          componentProps: {
            ...props,
          },
        });
        modal.onDidDismiss().then((response) => resolve(response?.data?.reason));
        await modal.present();
      });
    } catch (e) {
    }
  }

  validateBottomStatus(status) {
    return [
      'confirmed',
      'autoconfirmed',
      'trialconfirmwon',
      'trial',
      'contested',
      'contestedcancel',
    ].some((a) => a === status);
  }

  validateTopStatus(status) {
    return [
      'sent',
      'confirm',
      'confirmed',
      'trial',
      'contested',
      'contestedcancel',
    ].some((a) => a === status);
  }

  validateExpiratedStatus(status) {
    return [
      'taken_time_expired',
      'accept_expired',
      'autoconfirmed',
      'contestedcancel',
    ].some((a) => a === status);
  }

  validateBlurText(status) {
    return [
      'sent',
      'ignore',
      'accept_expired',
      'chicken',
    ].some((a) => a === status);
  }

  validateHasButton(item) {
    const validStatus = [
      'sent',
      'confirm',
      'contestedcancel',
      'contested',
      'trialconfirmwon',
      'trial',
      'autoconfirmed',
      'confirmed'
    ].some((a) => a === item?.dare?.status);
    return item?.dare &&
      this.normalizeAskStatusMessage(item.dare.status).actions &&
      this.normalizeAskStatusMessage(item.dare.status).allowedActionUser === (item.sendBy.id === this.currentUser.id ? 'by' : 'to') ||
      validStatus;
  }

  validateThumbAsk(status) {
    return [
      'sent',
      'accepted',
      'chicken',
      'ignore',
      'accept_expired',
      'contestedcancel',
    ].some((a) => a === status);
  }

  validateThumbAskVideo(status) {
    return [
      'confirm',
      'confirmed',
      'autoconfirmed',
      'trialconfirmwon',
      'trial',
      'contested'
    ].some((a) => a === status);
  }

  validateConfirmMessage(status) {
    return [
      'confirmed',
      'autoconfirmed',
      'trialconfirmwon',
      'contestedcancel',
    ].some((a) => a === status);
  }

  validateAmountChip(status) {
    return [
      'trial',
      'confirmed',
      'autoconfirmed',
      'trialconfirmwon',
      'contestedcancel'
    ].some((a) => a === status);
  }

  deletePreview() {
    this.videoPreview = null;
    this.imagePreview = null;
    this.filePreview = null;
  }

  async setFile(media) {
    this.deletePreview();
    if (!this.defaultUtils.validFileSize(media?.blob)) {
      return;
    }
    if (media) {
      if (media?.blob?.type.includes('video')) {
        media.url = this.domSanitizer.bypassSecurityTrustUrl(media.url);
        this.videoPreview = media;
      } else if (media?.blob?.type.includes('image')) {
        this.imagePreview = media;
      } else {
        this.filePreview = media;
      }
    }
  }

  async sendMediaMessage() {
    let message = this.message;
    if (!message || message.toString().trim().length === 0) {
      message = '';
    }
    const messageObject: any = {
      videoPreview: this.videoPreview,
      imagePreview: this.imagePreview,
      filePreview: this.filePreview,
      chatId: this.chatId,
      messageId: `${this.user.id}X${Date.now()}`,
      text: message,
      readed: false,
      updatedAt: this.defaultUtils.getDefaultDateFirebase(),
      createdAt: this.defaultUtils.getDefaultDateFirebase(),
      timestamp: new Date().getTime(),
      timezone: `${Intl.DateTimeFormat().resolvedOptions().timeZone}`,
      unloggedAsk: this.validateUtils.validateEmailRegex(this.user.id),
      type: 'upload',
      sendBy: {
        id: this.currentUser.id,
        name: this.currentUser.name,
        photo: this.currentUser.photoURL,
        email: this.currentUser.email,
      },
      sendTo: {
        id: this.user?.id,
        name: this.user?.name,
        email: this.user?.email || '',
        photo: this.user?.photoURL,
      },
    };

    this.chatToPaginate.push({...messageObject, loading: true});
    this.setLocalPagination(false);
    this.message = '';
    this.message = this.message.replace(/\r?\n|\r/g, ' ');
    this.scrollToBottom(100);
    this.deletePreview();
  }

  async modalOptions() {
    try {
      let listButtonFilter = [];
      if (!this.unloggedChat) {
        listButtonFilter = [
          {
            text: OptionsChat.sendAsk,
            selected: false,
            icon: 'chatbox-ellipses-outline',
            disable: false,
            iconType: OptionIconType.ionicIcon
          },
          {
            text: OptionsChat.tipYourCoach,
            selected: false,
            icon: 'logo-usd',
            disable: false,
            iconType: OptionIconType.ionicIcon
          },
          ...listButtonFilter
        ];
        if (this.userLogged?.role === 1) {
          listButtonFilter.push({
            text: OptionsChat.resquestVideoCall,
            selected: false,
            icon: 'people-circle-outline',
            disable: false,
            iconType: OptionIconType.ionicIcon
          });
        }
      }

      const response = await this.defaultUtils.modalOptionsDefault({
        listButtons: listButtonFilter,
        titleCloseLabel: OptionModal.close,
        title: 'Options',
      });
      this.onSelectOption(response);
    } catch (e) {
      console.log('e', e);
    }
  }

  onSelectOption(response) {
    switch (response) {
      case OptionsChat.sendAsk:
      case OptionsIconButton.sendAsk:
        return this.sendAsk(null);
      case OptionsChat.sendFile:
        return this.getFile();
      case OptionsChat.resquestVideoCall:
      case OptionsIconButton.resquestVideoCall:
        return this.sendRequestSession();
      case OptionsChat.tipYourCoach:
      case OptionsIconButton.tipYourCoach:
        return this.modalSendMoney();
    }
  }

  getScrollRef(id) {
    return document.getElementById(id)?.offsetTop;
  }

  scrollToId(id) {
    const elem = document.getElementById(id);
    const elementPostion = elem?.getBoundingClientRect();
    this.contentChat.scrollToPoint(elementPostion?.x, elementPostion?.y - 100, 100);
  }

  async getFile() {
    const file: any = await this.mediaUtil.getAllFiles();
    if (file) {
      let filePreview = null;
      if (file) {
        filePreview = {
          url: await this.mediaUtil.blobToBase64(file),
          blob: file
        };
        this.setFile(filePreview);
      } else {
        this.defaultUtils.globalToast('Error: Invalid file!', ColorToast.danger);
      }
    }
  }


  async sendVideoCall() {
    const object: any = {
      chatId: this.chatId,
      messageId: `${this.user.id}X${Date.now()}`,
      readed: false,
      updatedAt: this.defaultUtils.getDefaultDateFirebase(),
      createdAt: this.defaultUtils.getDefaultDateFirebase(),
      timestamp: new Date().getTime(),
      timezone: `${Intl.DateTimeFormat().resolvedOptions().timeZone}`,
      unloggedAsk: this.validateUtils.validateEmailRegex(this.user.id),
      type: 'video-call',
      videoCall: {
        status: 'sent',
      },
      sendBy: {
        id: this.currentUser.id,
        name: this.currentUser.name,
        photo: this.currentUser.photoURL,
        email: this.currentUser.email,
      },
      sendTo: {
        id: this.user.id,
        name: this.user.name,
        email: this.user.email,
        photo: this.user.photoURL,
      },
    };
    this.chatToPaginate.push({...object, loading: true});
    this.setLocalPagination(false);
    this.scrollToBottom(100);
    await this.messageService.save(this.chatId, object);
  }

  async modalCameraVideoCall(event) {
    window.open(event?.data?.videoCall?.joinUrl);
    // const modal = await this.modalCtrl.create({
    //   component: VideoCallPage,
    //   cssClass: 'fullscreen-modal',
    //   swipeToClose: true,
    //   backdropDismiss: false,
    //   componentProps: {
    //     chatId: this.chatId,
    //     userId: this.currentUser?.id
    //   },
    // });
    // await modal.present();
    // const response: any = await modal.onDidDismiss();
    // await this.defaultUtils.timeout(200);
    // await this.modalCtrl.dismiss(response?.data);
  }

  async modalSendMoney() {
    const modal = await this.modalCtrl.create({
      component: ModalSendMoneyPage,
      mode: 'md',
      cssClass: 'send-money-modal',
      swipeToClose: true,
      backdropDismiss: true,
      componentProps: {
        userLogged: this.userLogged,
        userChat: this.user,
        redirectUrl: `/chat?id=${this.userId}&open=tip-modal`,
      },
    });
    await modal.present();
    const response: any = await modal.onDidDismiss();
    await this.defaultUtils.timeout(200);
    if (response?.data) {
      this.sendTip(response?.data);
    }
  }

  async sendTip(amount) {
    const object: any = {
      chatId: this.chatId,
      messageId: `${this.user.id}X${Date.now()}`,
      readed: false,
      updatedAt: this.defaultUtils.getDefaultDateFirebase(),
      createdAt: this.defaultUtils.getDefaultDateFirebase(),
      timestamp: new Date().getTime(),
      timezone: `${Intl.DateTimeFormat().resolvedOptions().timeZone}`,
      unloggedAsk: this.validateUtils.validateEmailRegex(this.user.id),
      type: 'tip',
      sendBy: {
        id: this.currentUser.id,
        name: this.currentUser.name,
        photo: this.currentUser.photoURL,
        email: this.currentUser.email,
      },
      sendTo: {
        id: this.user?.id,
        name: this.user?.name,
        email: this.user?.email || '',
        photo: this.user?.photoURL,
      },
      amount,
    };
    this.chatToPaginate.push({...object, loading: true});
    this.setLocalPagination(false);
    this.scrollToBottom(100);
    await this.messageService.save(this.chatId, object);
  }

  listernerOpenTipModal() {
    this.unSubscribeTipModal = this.emitEventService.getObservableOpenTipModal()
      .subscribe((data) => {
        if (data) {
          this.modalSendMoney();
        }
      });
  }

  listernerOpenSendAskModal() {
    this.unSubscribeAskModal = this.emitEventService.getObservableOpenSendAskModal()
      .subscribe((value) => {
        if (value?.isSession) {
          this.sendAsk(value?.data);
        } else {
          this.sendRequestSession()
        }
      });
  }

  async modalOptionsChat() {
    const blocked = await this.getIsBlock();
    let listButtonFilter = [{
      text: OptionsChatUser.shareProfile,
      selected: false,
      icon: 'share-social-outline',
      disable: false,
      iconType: OptionIconType.ionicIcon
    }];
    if (!this.unloggedChat && !(this.userId === 'truly-admin')) {
      listButtonFilter = [
        {
          text: blocked ? OptionsChatUser.unblock : OptionsChatUser.block,
          icon: 'ban',
          disable: false,
          selected: false,
          iconType: OptionIconType.ionicIcon
        },
        ...listButtonFilter
      ];
    }
    const response = await this.defaultUtils.modalOptionsDefault({
      listButtons: listButtonFilter,
      titleCloseLabel: OptionModal.close,
      title: 'Options',
    });
    this.onSelectOptionUser(response, blocked);
  }

  onSelectOptionUser(response, blocked) {
    switch (response) {
      case OptionsChatUser.unblock:
      case OptionsChatUser.block:
        return this.actionBlock(blocked);
      case OptionsChatUser.shareProfile:
        return this.shareProfile();
    }
  }


  async actionBlock(blocked) {
    if (blocked) {
      await this.defaultUtils.unBlockUser(this.user.id);
    } else {
      await this.defaultUtils.blockUser(this.user.id);
    }
    await this.controlUsersBlocked();
  }

  validPhoto(photo) {
    if (this.userId === 'truly-admin') {
      return 'https://storage.googleapis.com/truly-production-24c15.appspot.com/truly.png';
    }
    return photo;
  }

  async downloadFile(media) {
    const [name, extension] = media?.name?.split('.');
    const loading = await this.loadingCtrl.create({message: 'Downloading...'});
    loading.present();
    this.platformUtil.isCordova() ?
      await this.httpHelper.downloadFileAndStore(media?.url, name, extension) :
      await this.mediaUtil.downloadFile(media?.url, name, extension);
    loading.dismiss();
  }


  handleScroll(event) {
    this.scrollTopChat = event?.detail?.scrollTop;
    this.contentChat.getScrollElement()?.then(a => {
      this.isAtTheBottom = ((event?.detail?.scrollTop + 780) >= a?.scrollHeight);
      if (this.isAtTheBottom) {
        this.unseenMessages = [];
      }
    });

    this.hideHeader = event?.detail?.scrollTop <= 200;
  }

  async scrollToReceivedMessage() {
    const firstMessage = this.defaultUtils.getFirstElement(this.unseenMessages);
    if (firstMessage) {
      this.contentChat.scrollToPoint(null, firstMessage?.target, 400);
      this.unseenMessages = [];
    }
  }

  async scrollToFirstReceivedMessage() {
    this.firstUnseenMessages = [];
    this.scrollToBottom(300);
  }

  trackByItem(index, item) {
    return item.id;
  }

  validGoBack() {
    if (this.previousRouteService?.getPreviousUrl()) {
      this.navCtrl.back();
      return;
    }
    this.router.navigateByUrl('/tabs/chat-list', {replaceUrl: true});
  }

  setLocalPagination(receiveData = true) {
    const chat = this.chatToPaginate?.filter((a, index) =>
      index >= this.pagination.start - 1 &&
      index <= this.pagination.end - 1);
    if (receiveData){
      const duplicatedObject = this.defaultUtils.removeDuplicatedObject([...chat,...this.chat]);
      this.chat = this.defaultUtils.removeDuplicatedObjectEspecificParam(duplicatedObject,'messageId');
    }else{
      const duplicatedObject = this.defaultUtils.removeDuplicatedObject([...this.chat,...chat]);
      this.chat = this.defaultUtils.removeDuplicatedObjectEspecificParam(duplicatedObject,'messageId');
    }
    setTimeout(() => {
      this.pagination.enable = (this.chat?.length < this.chatToPaginate?.length);
    }, 2000);
  }

  ionInfinite(event) {
    console.log('evennt',event)
    this.pagination.start += this.pagination.limit;
    this.pagination.end += this.pagination.limit;
    setTimeout(() => {
      event?.target.complete();
      this.setLocalPagination();
    }, 1000);
  }

}
