<ion-img *ngIf="photoUrl && useIonicComponent" class="profile-photo__thumb"
         [ngClass]="{'profile-photo--border':border,'profile-photo--border-black':borderBlack,'profile-photo--border-light-gray':borderLightGray}"
         [style]="size"
         [alt]="userName"
         [src]="validPhoto(photoUrl)"
         lazy
></ion-img>
<div *ngIf="photoUrl && !useIonicComponent" class="profile-photo__thumb"
     [ngClass]="{'profile-photo--border':border,'profile-photo--border-black':borderBlack,'profile-photo--border-light-gray':borderLightGray}"
     [style.background-image]="'url('+validPhoto(photoUrl)+')'"
     [style]="size">
</div>
<div *ngIf="!photoUrl"
     class="profile-photo__thumb-color"
     [style.background-image]="'linear-gradient('+letter?.firstColor+','+letter?.lastColor+')'"
     [ngClass]="{'profile-photo__border':border}"
     [style]="size">
  <div [style]="{'font-size':fontSize}">{{ letter?.value }}</div>
</div>

