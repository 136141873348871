import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {NavigationExtras, Router} from '@angular/router';
import {IonContent, ModalController} from '@ionic/angular';
import {User} from 'src/app/models/user';
import {ValueOption} from 'src/app/models/value-option';
import {AskService} from 'src/app/services/ask.service';
import {UserService} from 'src/app/services/user.service';
import {ValueOptionService} from 'src/app/services/value-option.service';
import {WalletService} from 'src/app/services/wallet.service';
import {NormalizeDataUtil} from 'src/app/utils/normalize-data.util';
import {CustomAmountPage} from '../custom-amount/custom-amount.page';
import {DefaultUtil} from '../../utils/default.util';
import {AnalyticsContent, AnalyticsUtil} from '../../utils/analytics.util';
import {AnalyticsEvents} from '../../enums/events';
import {environment} from '../../../environments/environment';
import {StripeService} from '../../services/stripe.service';
import {ValidatesUtil} from '../../utils/validates.util';
import {ColorToast} from '../../enums/color-toast';
import {PlatformUtil} from '../../utils/platform.util';


@Component({
  selector: 'app-send-ask',
  templateUrl: './send-ask.page.html',
  styleUrls: ['./send-ask.page.scss'],
})

export class SendAskPage implements OnInit {

  // @ViewChild('contentChat', {static: false}) contentSendAsk: IonContent;
  @ViewChild('contentSendAsk', {static: false}) contentSendAsk: IonContent;

  @Input()
  selectedUser = {} as User;

  @Input()
  isChatPage = false;

  @Input()
  externalAsk = false;

  @Input()
  fromProfile = false;

  @Input()
  unloggedUser = false;

  @Input()
  redirectUrl = null;

  currentUser = {} as User;

  email = '';

  valueOptions: ValueOption[] = [];

  isCordova = false;

  @ViewChild('ask', {read: ElementRef}) ask: ElementRef;
  slideConfirm = 0;
  userBalance = 0;
  selectedValue = 0;

  loading = false;
  sended = false;
  range = 0;

  question: string = null;
  enableFade = false;
  typesFade = ['fade_ask', 'fade_email', 'fade_price'];
  currentFade = '';

  constructor(
    private modalCtrl: ModalController,
    private valueOptionService: ValueOptionService,
    private userService: UserService,
    private askService: AskService,
    private walletService: WalletService,
    private normalizeDataUtil: NormalizeDataUtil,
    private router: Router,
    private defaultUtils: DefaultUtil,
    private analyticsUtil: AnalyticsUtil,
    private stripeService: StripeService,
    private validatesUtil: ValidatesUtil,
    private platformUtil: PlatformUtil,
  ) {
  }

  ngOnInit() {
    this.loadData();
  }

  async loadData() {
    try {
      this.isCordova = this.platformUtil.isCordova();
      const currentUser: any = await this.userService.getCurrentUser();
      if (currentUser && !currentUser.empty) {
        this.currentUser = {
          id: currentUser.docs[0].id,
          ...currentUser.docs[0].data(),
        };

        const wallet: any = await this.walletService.getWalletByUser(
          this.currentUser.id
        );

        if (wallet.exists) {
          this.userBalance = parseInt(wallet.data().depositBalance);
        }
      }
      const newValue = {
        id: '',
        // value: this.selectedUser?.price?.value || 0,
        value: this.selectedUser?.priceAsk?.value || 0,
        selected: true,
      };
      this.selectedValue = newValue.value;
      this.valueOptions.push(newValue);
      await this.defaultUtils.timeout(300);
      if (this.unloggedUser) {
        await this.defaultUtils.timeout(300);
        const completeTutorial = this.defaultUtils.storage().get('completeTutorialProfile');
        if (!completeTutorial) {
          this.setFade(true);
          this.setFadeType(0);
        }
      } else {
        this.ask.nativeElement.focus();
      }
    } catch (error) {
      console.error(error);
    }
  }

  // confirmSlideButton(event) {
  //   const value = event.detail.value;

  //   if (value >= 0 && value < 100) {
  //     this.slideConfirm = 0;

  //     const slideConfirmButtonProps: any = document.getElementById(
  //       'slide-confirm-button'
  //     );

  //     slideConfirmButtonProps.value = 0;

  //     return;
  //   }
  // }
  resetSlide() {
    const input: any = document.getElementById('confirm');
    input.value = 0;
  }

  async goToDeposit() {
    await this.modalCtrl.dismiss(null);

    const defaultUrl = window.location.href.split('/')[2];

    const navigationExtras: NavigationExtras = {
      queryParams: {
        redirectUrl: `http://${defaultUrl}${this.redirectUrl}&message=${this.question}`,
        backToSendAsk: this.isChatPage,
        question: this.question
      },
    };

    this.router.navigate(['deposit'], navigationExtras);
  }

  async goToSignup() {
    this.modalCtrl.dismiss();
    this.router.navigate(['signup']);

  }

  removeFocus() {
    this.ask.nativeElement.blur();
    document.getElementById('text').focus();
  }

  async sendAsk() {
    this.loading = true;
    if (this.unloggedUser) {
      await this.sendUnloggedAsk();
      return;
    }
    if (this.externalAsk) {
      await this.sendExternalAsk();
      return;
    }
    const isBlocked = await this.defaultUtils.validBlockedUser(this.currentUser.id, this.selectedUser.id);
    if (isBlocked) {
      await this.defaultUtils.globalToast('You are not allowed to interact with this user!', ColorToast.danger);
      this.modalCtrl.dismiss();
      return;
    }
    const verifyBalance = await this.verifyBalance(this.selectedValue);
    if (!this.question) {
      this.loading = false;
      this.sended = false;
      await this.defaultUtils.globalToast('Type your question.', ColorToast.primary);
      this.resetSlide();
      return;
    }
    if (this.selectedValue < (this.selectedUser?.priceAsk?.value || 0)) {
      this.loading = false;
      this.sended = false;
      await this.defaultUtils.globalToast(
        `${this.selectedUser.name} requested a minimum of $${this.selectedUser?.priceAsk?.value} to answer!`,
        ColorToast.primary,
        5000
      );
      this.resetSlide();
      return;
    }

    if (!verifyBalance) {
      this.loading = false;
      this.sended = false;
      await this.defaultUtils.globalToast('Insufficient balance.', ColorToast.primary);
      this.resetSlide();
      await this.goToDeposit();
      return;
    }

    const askObject = {
      messageId: `${this.currentUser.id}X${Date.now()}`,
      type: 'ask',
      sendBy: {
        id: this.currentUser.id,
        name: this.currentUser.name,
        photo: this.currentUser.photoURL,
        email: this.currentUser.email,
      },
      sendTo: {
        id: this.selectedUser.id,
        name: this.selectedUser.name,
        email: this.selectedUser.email,
        photo: this.selectedUser.photoURL,
      },
      dare: {
        name: this.question,
        amount: this.selectedValue,
        status: 'sent',
      },
      readed: true,
      unloggedAsk: false,
      timezone: `${Intl.DateTimeFormat().resolvedOptions().timeZone}`,
      updatedAt: new Date(),
      createdAt: new Date(),
      timestamp: new Date().getTime(),
    };

    try {
      const result = await this.askService.send(askObject);
    } catch (error) {
      console.log('error ', error);
    }

    this.loading = false;
    this.sended = true;
    this.analyticsPurchaseAsk(askObject);
    setTimeout(() => {
      if (!this.isChatPage) {
        this.goToChatPage(this.selectedUser.id);
      }
      this.closeModal(askObject);
    }, 2000);

  }

  analyticsPurchaseAsk(data) {
    const object: AnalyticsContent = {
      content_type: 'ask',
      content_id: data?.messageId,
      value: parseInt(data?.dare?.amount),
      currency: 'USD',
    };
    this.analyticsUtil.registerEvent(AnalyticsEvents.purchaseAsk, object);
  }

  async openCustomPage() {
    const modal = await this.modalCtrl.create({
      component: CustomAmountPage,
      componentProps: {},
      backdropDismiss: true,
    });
    modal.onDidDismiss().then((response) => this.setCustomValue(response?.data));
    return await modal.present();
  }


  setCustomValue(response: any) {
    const value = parseInt(response.value, 10);
    this.selectedValue = value;
    if (this.valueOptions.some(a => a.value === value)) {
      return;
    }
    this.valueOptions = this.valueOptions.map(a => {
      a.selected = false;
      return a;
    });
    const newValue = {
      id: 'custom',
      value,
      selected: true,
    };
    if (this.valueOptions.some(a => a.id === 'custom')) {
      this.valueOptions[this.valueOptions.length - 1] = newValue;
    } else {
      this.valueOptions.push(newValue);
    }
  }

  validButton() {
    return !this.question || !this.valueOptions.some(a => a?.selected === true);
  }


  goToChatPage(userId) {
    if (this.fromProfile) {
      this.defaultUtils.storage().set('fromProfile', true);
    }
    const navigationExtras: NavigationExtras = {
      queryParams: {
        id: userId,
      },
    };

    this.router.navigate(['chat'], navigationExtras);
  }

  async verifyBalance(value) {
    const wallet: any = await this.walletService.getWalletByUser(
      this.currentUser.id
    );

    if (!wallet.exists) {
      return false;
    }

    return parseInt(wallet.data().depositBalance) >= value;
  }

  selectedOption(index) {
    const selectedIndex = this.valueOptions.findIndex((x) => x.selected);
    if (selectedIndex !== -1) {
      this.valueOptions[selectedIndex].selected = false;
    }

    if (selectedIndex !== index) {
      this.valueOptions[index].selected = !this.valueOptions[index].selected;

      if (this.valueOptions[index].selected) {
        this.selectedValue = this.valueOptions[index].value;
      }
    }
  }

  closeModal(ask = null) {
    this.modalCtrl.dismiss(ask);
  }

  async sendUnloggedAsk() {
    if(!this.isValidForm()){
      return;
    }
    const email = this.email.toLowerCase();
    const userExist: any = await this.userService.getUserByEmailorUsername(email);
    if (userExist?.payload) {
      this.loading = false;
      this.sended = false;
      await this.defaultUtils.globalToast('This email is already being used. Log in to your account to proceed.', ColorToast.danger, 3000);
      this.resetSlide();
      return;
    }

    const ask = {
      messageId: `${email}X${Date.now()}`,
      type: 'ask',
      sendBy: {
        id: email,
        name: email,
        photo: '',
        email,
      },
      sendTo: {
        id: this.selectedUser.id,
        name: this.selectedUser.name,
        email: this.selectedUser.email,
        photo: this.selectedUser.photoURL,
      },
      dare: {
        name: this.question,
        amount: this.selectedValue,
        status: 'sent',
      },
      redirectLink: `${window.location.origin}/modal-signup?email=${encodeURIComponent(email)}`,
      unloggedAsk: true,
      readed: true,
      paymentIntent: '',
      timezone: `${Intl.DateTimeFormat().resolvedOptions().timeZone}`,
      updatedAt: new Date(),
      createdAt: new Date(),
      timestamp: new Date().getTime(),
    };
    if (this.selectedValue > 0) {
      const price = (this.selectedValue) * 100;
      const resProduct = await this.stripeService.createNewPrice(environment?.customDepositId, price);
      if (resProduct.success) {
        const resCheckout = await this.stripeService.getUnloggedCheckoutSession(
          this.selectedUser.id,
          resProduct?.payload?.id,
          'payment',
          `${window.location.origin}/confirm-unlogged-ask`,
          ask
        );
        this.defaultUtils.storage().set('hasUnloggedCheckout', {
            checkoutSessionId: resCheckout.checkout_session_id,
            userName: this.selectedUser.name,
            freeAsk: false,
            email,
          }
        );
        this.defaultUtils.storage().set('emailRedirectTrending', email);
        this.defaultUtils.storage().set('completeTutorialProfile', true);
        if (resCheckout) {
          window.location.href = resCheckout.createCheckoutSession.url;
        } else {
          this.defaultUtils.globalToast('Error deposit', ColorToast.danger, 3000);
        }
      }
    } else {
      await this.stripeService.getUnloggedCheckoutSession(
        this.selectedUser.id,
        null,
        'payment',
        null,
        ask
      );
      this.defaultUtils.storage().set('emailRedirectTrending', email);
      this.defaultUtils.storage().set('hasUnloggedCheckout', {
          checkoutSessionId: null,
          userName: this.selectedUser.name,
          freeAsk: true,
          email,
        }
      );
      this.loading = false;
      this.sended = true;
      this.router.navigate(['/confirm-unlogged-ask']);
      this.modalCtrl.dismiss();

    }
  }

  setFade(enable) {
    this.enableFade = enable;
  };

  setFadeType(index) {
    this.currentFade = this.typesFade[index];
  };

  controlFade() {
    let currentIndex = this.typesFade.findIndex(a => a === this.currentFade);
    currentIndex++;
    if (this.typesFade.length >= (currentIndex + 1)) {
      this.setFadeType(currentIndex);
    } else {
      this.setFade(false);
    }
  };

  async sendExternalAsk() {
    if(!this.isValidForm()){
      return;
    }
    const email = this.email.toLowerCase();
    const message = {
      to: email,
      subject: ``,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      template_id: 'd-8a992480ce704865b88e7f52f266b6a1',
      hideWarning: true,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      dynamic_template_data: {
        user: this.currentUser?.name,
        ask: this.question,
        price: 'FREE',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        redirect_link: `https://jointruly.com/signup?email=${email}`
      },
    };
    this.userService.sendEmail(message);
    this.loading = false;
   await this.defaultUtils.timeout(400);
    this.modalCtrl.dismiss();
    this.defaultUtils.globalToast('Email successfully sent');
  }

  isValidForm() {
    if (!this.question) {
      this.loading = false;
      this.sended = false;
      this.defaultUtils.globalToast('Type your question.', ColorToast.danger);
      this.resetSlide();
      return false;
    }

    if (!this.email) {
      this.loading = false;
      this.sended = false;
      this.defaultUtils.globalToast('Type your email.', ColorToast.danger);
      this.resetSlide();
      return false;
    }

    if (!this.validatesUtil.validateEmailRegex(this.email)) {
      this.loading = false;
      this.sended = false;
      this.defaultUtils.globalToast('Invalid email.', ColorToast.danger);
      this.resetSlide();
      return false;
    }
    return true;
  }
  onfocus(){
    setTimeout(()=>{
      this.contentSendAsk.scrollToTop();
    },5000);
  }

  checkKey(e) {
    if(e?.keyCode === 13) {
      this.ask.nativeElement.blur();
    }
  }
}
