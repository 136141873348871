<div class="confirmation-slider">
  <div id="status">
    <input [(ngModel)]="range" placeholder="holder"   [disabled]="disabled" [ngClass]="{'disabled': disabled}"  (input)="slideButton()" id="confirm" type="range"
      min="0" max="100" />
    <lottie-player *ngIf="loading && !complete" loop autoplay src="https://assets10.lottiefiles.com/packages/lf20_xdz4wqzd.json">
    </lottie-player>
    <lottie-player *ngIf="!loading && complete" autoplay src="https://assets8.lottiefiles.com/private_files/lf30_yo2zavgg.json">
    </lottie-player>
  </div>
</div>
