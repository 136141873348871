import {Injectable} from '@angular/core';
import firebase from 'firebase/app';
import 'firebase/analytics';
import {User} from '../models/user';
import {WalletService} from '../services/wallet.service';
import {DefaultUtil} from './default.util';
import {AnalyticsEvents} from '../enums/events';

export interface AnalyticsContent {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  content_type: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  content_id: string;
  value: number;
  currency?: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ask_detail?: any;
}

@Injectable({
  providedIn: 'root',
})

export class AnalyticsUtil {

  private analytics = firebase.analytics();
  private unSubscribeWallet = null;

  constructor(
    private defaultUtil: DefaultUtil,
    private walletService: WalletService
  ) {
  }

  async eventDepositRequest() {
    const currentUser = await this.defaultUtil.getUser();
    if (currentUser) {
      if (this.unSubscribeWallet) {
        this.unSubscribeWallet.unsubscribe();
      }
      this.unSubscribeWallet = this.walletService.subscribeWallet(currentUser.id).subscribe(async (value) => {
        const lastDepositBalance = this.defaultUtil.storage().get('lastDepositBalance');
        if (lastDepositBalance && (lastDepositBalance < value.depositBalance)) {
          const depositValue = value.depositBalance - lastDepositBalance;
          const object = {
            content_type: 'success_deposit',
            content_id: '',
            currency: 'USD',
            value: depositValue
          };
          this.registerEvent(AnalyticsEvents.purchase, object);
        }
        this.defaultUtil.storage().set('lastDepositBalance', value.depositBalance);
      });
    }
  }

  registerEvent(event, data: AnalyticsContent) {
    this.analytics.logEvent(event, data);
  }

  defaultAnalyticsContent() {
    return {
      content_type: '',
      content_id: '',
      currency: 'USD',
      value: 0,
    };
  }

  setUserAnalytics(user: User) {
    try {
      this.analytics.setUserProperties({
        name: user.name,
        email: user.email,
        isVerified: user.isVerified
      });
      this.analytics.setUserId(user.id);
    } catch (e) {
      console.log('setUserAnalyticsError>>>>>',e);
    }
  };


  logoutAnalytics() {
    this.analytics.setUserProperties({});
    this.analytics.setUserId(null);
  };


}


