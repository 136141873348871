<ion-content scroll="false">
  <app-header-camera [isNativeCamera]="true" [count]="this.durationText" (close)="onBack()"></app-header-camera>
    <div class="play-pause-preview" *ngIf="step === 'choiceThumb'" (click)="playPausePreview()">
      <ion-icon *ngIf="playPreviewStatus === 'play'" style="zoom:2.0; color: #fff" name="play-circle"></ion-icon>
      <ion-icon *ngIf="playPreviewStatus === 'pause'" style="zoom:2.0; color: #fff" name="pause-circle"></ion-icon>
    </div>
  <canvas #canvas class="canvas-thumb"></canvas>

  <div class="ctr-top"></div>
  <video class="container-camera" #videoArea playsinline webkit-playsinline poster="assets/background/Black.jpg"></video>
  <div class="ctr-play">
    <div class="ctr-interacts">
      <ion-grid>
        <ion-row *ngIf="step === 'recordVideo'" class="default-row">
          <ion-col>
          </ion-col>
          <ion-col>
            <div  *ngIf="!isLoad" class="container-play" (click)="actionRecord()" >
              <div [ngClass]="isRecording ? 'pause' : 'play'"></div>
            </div>
            <ion-spinner *ngIf="isLoad" color="light"></ion-spinner>
          </ion-col>
          <ion-col>
<!--            <div class="container-btn" (click)="switchCamera()" *ngIf="!isLoad && !isRecording">-->
<!--              <ion-icon class="icon" name="camera-reverse"></ion-icon>-->
<!--            </div>-->
          </ion-col>
        </ion-row>
        <ion-row *ngIf="step === 'choiceThumb'" class="default-row">
          <ion-row style="width: 100%">
            <div class="container-scroll">
              <div class="scroll">
                <div class="thumb-choice"
                     *ngFor="let thumb of thumbs"
                     (click)="setThumb(thumb)"
                     [ngClass]="{'thumb-choiced': thumb.valid}"
                     [ngStyle]="{'background-image': 'url('+ thumb.image +')'}">
                </div>
              </div>
            </div>
          </ion-row>
        </ion-row>
        <ion-row *ngIf="step === 'choiceThumb'" class="default-row">
          <ion-col size="4">
            <ion-button class="button-default outline" (click)="reTake()">
              Re-take
            </ion-button>
          </ion-col>
          <ion-col size="8">
            <ion-button class="button-default "  (click)="choiceThumb()">
              Edit Thumbnail
            </ion-button>
          </ion-col>
        </ion-row>
        <ion-row *ngIf="step === 'confirmVideo'" class="default-row">
          <ion-col>
            <ion-button class="button-default"  (click)="confirmVideo()">
              Post
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </div>
</ion-content>

