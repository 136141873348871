import {AlertController, ModalController, NavController, Platform} from '@ionic/angular';
import {MenuController} from '@ionic/angular';
import {AngularFirestore} from '@angular/fire/firestore';
import {SwUpdate, VersionEvent} from '@angular/service-worker';
import {ChatService} from './services/chat.service';
import {Component, Input} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {DefaultUtil} from './utils/default.util';
import {UserService} from './services/user.service';
import {AuthService} from './services/auth.service';
import {Pages, routes} from './app-routing.module';
import {AnalyticsUtil} from './utils/analytics.util';
import {AnalyticsEvents} from './enums/events';
import {PlatformUtil} from './utils/platform.util';
import {PushHelper} from './helpers/push.helper';
import {PreviousRouteService} from './services/previous-route.service';
import {AppVersionHelper} from './helpers/appVersion.helper';
import {DeeplinkHelper} from './helpers/deeplink.helper';
import {EmitEventService} from './services/emit-event.service';
import {NetworkHelper} from './helpers/network.helper';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  @Input() shareUrl: string;

  public showAnimation = false;

  fullSize = true;

  isMobile = false;

  isOnline = true;

  constructor(
    private router: Router,
    private defaultUtils: DefaultUtil,
    private userService: UserService,
    private authService: AuthService,
    private chatService: ChatService,
    private alertController: AlertController,
    private menuController: MenuController,
    private firestore: AngularFirestore,
    private readonly updates: SwUpdate,
    private route: ActivatedRoute,
    private modalCtrl: ModalController,
    private analyticsUtil: AnalyticsUtil,
    private navController: NavController,
    private platformUtil: PlatformUtil,
    private appVersionHelper: AppVersionHelper,
    platform: Platform,
    private pushHelper: PushHelper,
    private previousRouteService: PreviousRouteService,
    private activatedRoute: ActivatedRoute,
    private navCtrl: NavController,
    private deeplinkHelper: DeeplinkHelper,
    private networkHelper: NetworkHelper,
    private emitService: EmitEventService
  ) {
    this.updates.checkForUpdate().then((update) => {
      console.log('update ', update);
    });
    this.updates.versionUpdates.subscribe((event: VersionEvent) => {
      if (event?.type === 'VERSION_READY') {
        this.showAppUpdateAlert();
      }
    });
    platform.ready().then(() => {
      setTimeout(async () => {
        this.pushHelper.initOneSignal();
        this.appVersionHelper.validAppVersion();
        this.deeplinkHelper.initDeeplink();
        this.networkHelper.initNetworkCheck();
        if (!!this.userService?.currentUser?.id) {
          this.pushHelper.addUserInfo(this.userService?.currentUser?.name);
        } else {
          this.pushHelper.removeUserInfo();
        }
      }, 1000);
    });
    this.previousRouteService.getPreviousUrlEvent();
  }

  ngOnInit() {
    this.validSecondAccess();
    this.validCustomRoute();
    this.defaultUtils.updateUserOnline(true);
    document.addEventListener(
      'visibilitychange',
      () => this.defaultUtils.updateUserOnline(),
      false
    );
    this.resetValues();
    this.controlAnimation();
    this.eventScreens();
    this.isMobile = this.platformUtil.isAndroid() || this.platformUtil.isIos();
    this.defaultUtils.openSideMenu();
  }

  resetValues() {
    this.defaultUtils.storage().delete('chatId');
  }

  async validCustomRoute() {
    if (this.platformUtil.isCordova()) {
      return;
    }
    if (document?.URL?.split('/').some((a) => a === 'profile')) {
      return;
    }
    let params = this.defaultUtils.getLastElement(document.URL.split('/'));
    params = this.defaultUtils.getFirstElement(params?.split('?'));
    if (!params) {
      return;
    }
    if (routes.some((a) => a?.path === params)) {
      return;
    }

    const urlFacebookAd = this.defaultUtils.getLastElement(document?.URL?.split('?'));
    if (urlFacebookAd?.includes('fbclid')) {
      const splitUrl = this.defaultUtils.getFirstElement(document?.URL?.split('?'));
      params = this.defaultUtils.getLastElement(splitUrl?.split('/'));
    }
    const {payload} = await this.userService.getUserByEmailorUsername(params);
    if (payload) {
      this.router.navigate([`profile/${payload?.name}`]);
      return;
    }
    window.location.href = window.location.origin;
  }

  async showAppUpdateAlert() {
    const header = 'App Update available';
    const message = 'Choose Ok to update';

    const alert = await this.alertController.create({
      header,
      message,
      buttons: ['OK'],
    });

    await alert.present();

    await alert.onDidDismiss();
    this.doAppUpdate();
  }

  doAppUpdate() {
    this.updates.activateUpdate().then(() => document.location.reload());
  }

  async controlAnimation() {
    if (window.location.pathname.match('login')) {
      this.showAnimation = false;
      return;
    }
    if (!this.platformUtil.isCordova()) {
      this.showAnimation = true;
      await this.defaultUtils.timeout(700);
      this.showAnimation = true;
      await this.defaultUtils.timeout(2000);
      this.showAnimation = false;
      this.showAnimation = false;
    }
  }

  validSecondAccess() {
    const secondAccess = this.defaultUtils.storage().get('secondAccessRequired');
    if (secondAccess) {
      setTimeout(() => {
        this.defaultUtils.storage().set('secondAccessRequired', false);
        this.analyticsUtil.registerEvent(AnalyticsEvents.secondAccess, this.analyticsUtil.defaultAnalyticsContent());
      }, 4000);
    }
  }

  eventScreens() {
    this.router.events.subscribe(value => {
      if (value instanceof NavigationEnd) {
        this.validateTitleHeader(value?.url);
      }
    });
  }

  getScreenName(originalUrl) {
    let url = this.defaultUtils.getLastElement(originalUrl?.split('/')) || originalUrl;
    url = this.defaultUtils.getFirstElement(url?.split('?')) || url;
    if (!originalUrl?.match(Pages.myProfile) && !originalUrl?.match(Pages.editProfile)) {
      if (originalUrl?.match(Pages.profile)) {
        url = Pages.profile;
      }
    }
    return url;
  }

  validateTitleHeader(url) {
    switch (this.getScreenName(url)) {
      case Pages.watch:
      case Pages.profile:
        break;
      case Pages.chat:
        this.defaultUtils.setTitlePage('Chat | Truly App');
        break;
      case Pages.home:
        this.defaultUtils.setTitlePage('Home | Truly App');
        break;
      case Pages.myProfile:
        this.defaultUtils.setTitlePage('My Profile | Truly App');
        break;
      case Pages.signup:
        this.defaultUtils.setTitlePage('Sign Up | Truly App');
        break;
      case Pages.withdraw:
        this.defaultUtils.setTitlePage('Withdraw | Truly App');
        break;
      case Pages.deposit:
        this.defaultUtils.setTitlePage('Deposit | Truly App');
        break;
      case Pages.editProfile:
        this.defaultUtils.setTitlePage('Edit Profile | Truly App');
        break;
      case Pages.trending:
        this.defaultUtils.setTitlePage('Featured Users | Truly App');
        break;
      case Pages.watchList:
        this.defaultUtils.setTitlePage('Watch List | Truly App');
        break;
      case Pages.videoList:
        this.defaultUtils.setTitlePage('Video List | Truly App');
        break;
      case Pages.editVideo:
        this.defaultUtils.setTitlePage('Edit Video | Truly App');
        break;
      default:
        this.defaultUtils.setTitlePage('Truly Coaches App: Find Top Coaches for Various Topics | Sign Up Today');
        break;
    }
  }

  validScreenHeaderIos() {
    if (this.platformUtil.isCordovaIos()) {
      const currentPageTree = window.location.href?.split('/');
      return currentPageTree?.some(a => a === Pages.profile || a === Pages.myProfile);
    }
    return false;
  }



}
