import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss'],
})
export class StarRatingComponent implements OnInit {

  @Input()
  public avaliation: number;

  @Input()
  public rated: boolean;

  @Output()
  public onClick = new EventEmitter();

  quantity: any;

  constructor() {
    this.quantity = new Array(5);
  }

  ngOnInit() {

    // this.rated = false;
  }

  onChoice(value) {
    this.onClick.emit(value);
  }

}
