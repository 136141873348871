import {Injectable} from '@angular/core';
import {PlatformUtil} from './platform.util';
import {CaptureError, MediaFile} from '@awesome-cordova-plugins/media-capture/ngx';
import {Chooser, ChooserResult} from '@awesome-cordova-plugins/chooser/ngx';
import {DefaultUtil} from './default.util';
import {MediaCapture} from '@awesome-cordova-plugins/media-capture/ngx/';
import {File as FileSystem, IFile} from '@awesome-cordova-plugins/file/ngx';
import {ModalController} from '@ionic/angular';
import {NativeCameraPage} from '../pages/native-camera/native-camera.page';
import {Camera, CameraOptions} from '@awesome-cordova-plugins/camera/ngx';
import {MediaService} from '../services/media.service';


@Injectable({
  providedIn: 'root',
})

export class MediaUtil {

  constructor(private platformUtil: PlatformUtil,
              private fileChooser: Chooser,
              private mediaCapture: MediaCapture,
              public defaultUtil: DefaultUtil,
              private file: FileSystem,
              private modalCtrl: ModalController,
              private camera: Camera,
              private mediaService: MediaService) {
  }

  async nativeGallery(accept = '') {
    const result: ChooserResult = await this.fileChooser.getFile(accept);
    return new Blob([result?.data], {type: result.mediaType});
  }

  async nativeCameraPreview() {
    const modal = await this.modalCtrl.create({
      component: NativeCameraPage,
      swipeToClose: true,
      backdropDismiss: false,
    });
    await modal.present();
    const response: any = await modal.onDidDismiss();
    return response?.data;
  }

  cameraPicture(appendInput = null) {
    const picture: any = document.getElementById('picture');
    picture?.remove();
    return new Promise((resolve, reject) => {
      document.body.append(appendInput || this.createInputFileImage());
      const myfile: any = document.getElementById('picture');
      myfile.addEventListener('change', async (event) => {
        const blob = event.target.files[0];
        const base64 = await this.blobToBase64(blob);
        setTimeout(() => {
          picture?.remove();
        }, 5000);
        resolve({blob, base64});
      });
      myfile.click();
    });
  }

  cameraVideoImage(onlyVideo = false) {
    try {
      const recorder: any = document.getElementById('recorder');
      recorder?.remove();
      return new Promise((resolve, reject) => {
        document.body.append(this.createInputFileVideoImage(onlyVideo));
        const myfile: any = document.getElementById('recorder');
        myfile.addEventListener('change', (event) => {
          const file = event.target.files[0];
          setTimeout(() => {
            recorder?.remove();
          }, 5000);
          resolve(file);
        });
        myfile.click();
      });
    } catch (e) {
      console.log('e', e);
    }
  }

  getAllFiles() {
    try {
      const recorder: any = document.getElementById('recorder');
      recorder?.remove();
      return new Promise((resolve, reject) => {
        document.body.append(this.createInputFile());
        const myfile: any = document.getElementById('recorder');
        myfile.addEventListener('change', (event) => {
          const file = event.target.files[0];
          setTimeout(() => {
            recorder?.remove();
          }, 5000);
          resolve(file);
        });
        myfile.click();
      });
    } catch (e) {
      console.log('e', e);
    }
  }

  cameraVideo() {
    const recorder: any = document.getElementById('recorder');
    recorder?.remove();
    return new Promise((resolve, reject) => {
      document.body.append(this.createInputFileVideo());
      const myfile: any = document.getElementById('recorder');
      myfile.addEventListener('change', (event) => {
        console.log('change', event);
        const file = event.target.files[0];
        setTimeout(() => {
          recorder?.remove();
        }, 5000);
        resolve(file);
      });
      myfile.click();
    });

  }

  createInputFileImage() {
    const input = document.createElement('input');
    input.setAttribute('id', 'picture');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.setAttribute('style', 'visibility: hidden');
    return input;
  }

  createInputFileImageCamera() {
    const input = document.createElement('input');
    input.setAttribute('id', 'picture');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.setAttribute('style', 'visibility: hidden');
    input.setAttribute('capture', 'camera');
    return input;
  }

  createInputFileVideo() {
    const input = document.createElement('input');
    input.setAttribute('id', 'recorder');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'video/*');
    input.setAttribute('capture', '');
    input.setAttribute('style', 'visibility: hidden');
    return input;
  }

  createInputFileVideoImage(onlyVideo) {
    const input = document.createElement('input');
    input.setAttribute('id', 'recorder');
    input.setAttribute('type', 'file');
    input.setAttribute('style', 'visibility: hidden');
    if (this.platformUtil.isDesktop() || this.platformUtil.isIos()) {
      if (onlyVideo) {
        input.setAttribute('accept', 'video/*');
      } else {
        input.setAttribute('accept', 'image/*,video/*');
      }
    }
    return input;
  }

  createInputFile() {
    const input = document.createElement('input');
    input.setAttribute('id', 'recorder');
    input.setAttribute('type', 'file');
    input.setAttribute('style', 'visibility: hidden');
    return input;
  }

  resizeImage(file, maxWidth, maxHeight) {
    try {
      return new Promise((resolve, reject) => {
        // Create an image
        const img: any = new Image();
        // Create a file reader
        const reader: any = new FileReader();
        // Set the image once loaded into file reader
        reader.onload = (b) => {
          img.src = b.currentTarget.result;
          const canvas = document?.createElement('canvas');
          let ctx = canvas?.getContext('2d');
          img.onload = async (a) => {
            const selfOnload: any = a?.target;
            let width = selfOnload.width;
            let height = selfOnload.height;
            if (width > height) {
              if (width > maxWidth) {
                height *= maxWidth / width;
                width = maxWidth;
              }
            } else {
              if (height > maxHeight) {
                width *= maxHeight / height;
                height = maxHeight;
              }
            }
            canvas.width = width;
            canvas.height = height;
            ctx = canvas?.getContext('2d');
            ctx?.drawImage(img, 0, 0, width, height);
            const base64 = canvas?.toDataURL('image/png');
            const blob = await this.base64ToBlob(base64, 'image/jpeg', 512);
            resolve({base64, blob});
          };
          ctx.drawImage(img, 0, 0);
        };
        // Load files into file reader
        reader.readAsDataURL(file);
      });
    } catch (e) {
      console.log('e', e);
    }
  }

  async base64ToBlob(base64, contentType, sliceSize) {
    const byteCharacters = atob(base64.split(',')[1]);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, {type: contentType});
  }

  blobToBase64(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });
  }

  async getNativeCameraVideo(options): Promise<any> { //IS THE BETTER AWAY BUT IT'S NOT WORK VERY WELL ON ANDROID
    return new Promise(resolve => {
      this.mediaCapture.captureVideo(options).then(
        async (data: MediaFile[]) => {
          const video = await this.getFileFromStorageSystem(data[0]?.fullPath);
          resolve({
            video,
            image: null
          });
        },
        (err: CaptureError) => {
          resolve(null);
        }
      );
    });
  }

  async getFileFromStorageSystem(fullPath) {
    try {
      return new Promise<File>(async (resolve, reject) => {
        const splitedUrl = fullPath.split('/');
        const fileName = splitedUrl[splitedUrl.length - 1];
        let filePath = fullPath.replace(fileName, '');
        if(!filePath.match('file://')){
          filePath = `file://${filePath}`;
        }
        const directoryEntry = await this.file.resolveDirectoryUrl(filePath);
        const fileEntry = await this.file.getFile(directoryEntry, fileName, {create: true});
        fileEntry.file(async a => {
            resolve(await this.convertCordovaFileToJavascriptFile(a));
          },
          error => {
            resolve(null);
          });
      });
    } catch (e) {
      console.log('error>>>>>>>>>>>>>>>>>', e);
    }
  }

  convertCordovaFileToJavascriptFile(cordovaFile: IFile): Promise<File> {
    return new Promise<File>((resolve, reject) => {
      try {
        const reader = new FileReader();
        reader.onloadend = () => {
          if (reader.error) {
            resolve(null);
          } else {
            const blob: any = new Blob([reader.result], {type: cordovaFile.type});
            blob.lastModifiedDate = new Date();
            blob.name = cordovaFile.name;
            resolve(blob as File);
          }
        };
        reader.readAsArrayBuffer(cordovaFile);
      } catch (e) {
        console.log('erro convert', e);
      }
    });

  }

  async copyfileDeviceStorage(path: string, fileName: string, newPath: string) {
    try {
      const copyResult = await this.file.moveFile(path, fileName, newPath, fileName);
    } catch (e) {
      console.log('e', e);
    }
  }

  getNativeCamera(sourceType) {
    return new Promise(resolve => {
      const options: CameraOptions = {
        quality: 100,
        destinationType: this.camera.DestinationType.DATA_URL,
        encodingType: this.camera.EncodingType.JPEG,
        mediaType: this.camera.MediaType.PICTURE,
        correctOrientation: true,
        saveToPhotoAlbum: false,
        sourceType,
      };
      this.camera.getPicture(options).then(async (imageData) => {
        const base64 = 'data:image/jpeg;base64,' + imageData;
        const blob = await this.base64ToBlob(base64, 'image/jpeg', 512);
        resolve({blob, base64});
      }, (err) => {
        // Handle error
      });
    });
  }
  async downloadFile(url, fileName, extension) {
    const blob = await this.mediaService.getBLobUrl(url);
    const elem = window.document.createElement('a');
    elem.href = window.URL.createObjectURL(blob);
    elem.setAttribute('class', 'external');
    elem.download = `${fileName}.${extension}`;
    document.body.appendChild(elem);
    elem.click();
    document.body.removeChild(elem);
    elem.remove();
    return true;
  }

  getNativeGalleryVideo() {
    return new Promise(resolve => {
      const options: CameraOptions = {
        quality: 100,
        destinationType: this.camera.DestinationType.NATIVE_URI,
        sourceType: this.camera.PictureSourceType.SAVEDPHOTOALBUM,
        mediaType: this.camera.MediaType.VIDEO,
      };
      this.camera.getPicture(options).then(async (urlData) => {
        const video = await this.getFileFromStorageSystem(urlData);
        resolve({
          video,
          image: null
        });
      }, (err) => {
        // Handle error
        console.log('getNativeGalleryVideo>>>>>>>>>>',err)
      });
    });
  }


}
