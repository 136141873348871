import { Injectable, NgZone } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from './services/auth.service';
import { UserService } from './services/user.service';
import { StorageService } from './services/storage.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private storageService: StorageService,
  ) {}

  async canActivate(): Promise<boolean> {
    const result: any = await new Promise((resolve, reject) => {
      // this.authService.verifyUserLogged().onAuthStateChanged(async (user) => {
      //   if (!user) {
      //     this.router.navigate(['login']);
      //     resolve(false);
      //     return;
      //   }
      //   const displayName = user?.displayName || '';

      //   const { payload }: any = await this.userService.getUserByEmailorUsername(displayName);
      //   if (!payload) {
      //     this.analyticsUtil.logoutAnalytics();
      //     resolve(false);
      //   }
      //   const currentUser = payload;
      //   if(currentUser.isEnabled === false) {
      //     this.router.navigate(['login']);
      //     Swal.fire('Error', 'Blocked account', 'error');
      //     resolve(false);
      //     return;
      //   }

      //   const update = {
      //     updatedAt: firebase.firestore.Timestamp.fromDate(new Date()),
      //     online: true
      //   };

      //   await this.userService.update(currentUser.id, {...update});

      const currentUser = JSON.parse(localStorage.getItem('current-user'));

      this.userService.currentUser = {
        ...currentUser,
      };

      const token = this.storageService.accessToken;

      const error: boolean = false;

      this.authService
        .validateToken(token)
        .catch((error) => {
          // console.log('error validate token ', error);

          const uid = localStorage.getItem('user-uid');

          if (error.error?.code === 'auth/id-token-expired') {
            this.refreshToken(uid);
          } else {
            error = true;
            this.router.navigate(['login']);
          }

        })
        .finally(async () => {
          if (error) return;

          const result = await this.userService.getById(currentUser.id);

          const user = {
            id: result.id,
            ...result.data(),
          };

          localStorage.setItem('current-user', JSON.stringify(user));

          this.userService.currentUser = {
            ...user,
          };

          // console.log('user get id', user);
        });

      resolve(true);
    });
    // console.log('RESULT ', result);
    return result;
  }

  async refreshToken(uid) {
    const resultToken: any = await this.authService.getToken(uid);

    // console.log('resultToken ', resultToken);

    const verifyAuth = await this.authService.signWithCustomToken(
      resultToken.token
    );

    // console.log('verifyAuth ', verifyAuth);

    const idToken = await verifyAuth.user.getIdToken(true);

    localStorage.setItem('access-token', idToken);
  }
}
