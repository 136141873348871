<ion-content #contentChat [scrollEvents]="true"
             (ionScroll)="handleScroll($event)"
             id="chat" class="cont">

  <app-home-view
    pageName="Chat"
    [backButtonMobile]="true"
    [simpleHeader]="true"
    [showSideMenu]="false"
    [customBack]="true"
    (onClickBack)="validGoBack()"
    [hasCateg]="false">
    <app-header-default
      backgroundColor="#fff"
      colorIcon="#000"
      [user]="user"
      headerHeight="72px"
      [hideAnimated]="hideHeader"
      [marginHeader]="true"
      [showIconOptions]="true"
      (onOptions)="modalOptionsChat()"
      [showIcon]="false"
      [showTitle]="true"></app-header-default>
    <ion-grid  slot="fixed" class="header-grid ion-align-items-center ion-align-self-center">
      <ion-row style="padding-top: 4px">
        <!--        <ion-col class="header-column ion-align-items-center ion-align-self-center" size="2">-->
        <!--          <ion-icon (click)="goBack()" name="arrow-back-outline"></ion-icon>-->
        <!--        </ion-col>-->
        <ion-col class="header-column" (click)="navigateToProfile(user)">
          <ion-item class="item-user-header" lines="none">
            <app-profile-photo fontSize="20px" *ngIf="user?.id" [size]="{width:'36px',height:'36px'}"
                               [isVerified]="user?.isVerified" [photoUrl]="validPhoto(user?.photoURL)"
                               [userName]="user?.name"></app-profile-photo>
            <ion-label class="user-name">{{ user.name }}</ion-label>
          </ion-item>
        </ion-col>
        <ion-col class="header-column ion-align-items-center ion-align-self-center" size="2">
          <ion-icon (click)="modalOptionsChat()" name="ellipsis-horizontal"></ion-icon>
        </ion-col>
      </ion-row>
    </ion-grid>
    <ion-infinite-scroll *ngIf="pagination?.enable" position="top" threshold="10px" (ionInfinite)="ionInfinite($event)">
      <ion-infinite-scroll-content loadingSpinner="lines">
      </ion-infinite-scroll-content>
    </ion-infinite-scroll>
    <div class="rounded-top">
      <div class="container-list">
        <ion-list class="chat-list" id="messages">
          <div [id]="'msg-'+item?.messageId" *ngFor="let item of chat; let i = index; trackBy: trackByItem">
            <ion-item class="today-item" *ngIf="verifyTodayLabel(item, i)" lines="none">
              <ion-label>
                Today
              </ion-label>
            </ion-item>

            <ion-item class="today-item" *ngIf="verifyYesterdayLabel(item, i)" lines="none">
              <ion-label>
                Yesterday
              </ion-label>
            </ion-item>

            <app-thumb-media
              *ngIf="item?.type === 'image' || item?.type === 'video' || item?.type === 'file' ||  item?.type === 'upload' "
              [message]="item"
              [chatId]="chatId"
              [sendBy]="item.sendBy.id === currentUser.id"
              (onClickPlayVideo)="navigateToPlayer($event)"
              (onClickFile)="downloadFile($event)"
              (onClickInternalLink)="navigateToProfile($event)"
            ></app-thumb-media>

            <app-thumb-ask
              *ngIf="validateThumbAsk(item?.dare?.status)"
              [hasTopStatus]="true"
              [enableBlurText]="validateBlurText(item?.dare?.status || '')"
              [sendBy]="item.sendBy.id === currentUser.id"
              [showButton]="validateHasButton(item)"
              [normalizedInfo]="normalizeAskStatusMessage(item?.dare?.status,   item.sendBy.name,item?.sendTo.name,item?.shared,item?.rated)"
              [showConfirmMessage]="validateConfirmMessage(item?.dare?.status)"
              [showAmountChip]="!validateAmountChip(item?.dare?.status)"
              [message]="item"
              (executeAction)="executeAction($event.action, $event.data)"
              (onClickInternalLink)="navigateToProfile($event)"
            ></app-thumb-ask>

            <app-thumb-video-call
              *ngIf="item?.type  === 'video-call'"
              [hasTopStatus]="true"
              [sendBy]="item.sendBy.id === currentUser.id"
              [showButton]="true"
              [normalizedInfo]="normalizeAskStatusMessageVideoCall(item?.videoCall?.status,   item.sendBy.name, item?.sendTo.name,item?.shared,item?.rated)"
              [showConfirmMessage]="false"
              [showAmountChip]="false"
              [message]="item"
              (executeAction)="executeActionSession($event)"
            ></app-thumb-video-call>

            <app-thumb-tip
              *ngIf="item?.type  === 'tip'"
              [sendBy]="item.sendBy.id === currentUser.id"
              [message]="item"
            ></app-thumb-tip>

            <app-thumb-ask-video
              *ngIf="validateThumbAskVideo(item?.dare?.status)"
              [hasTopStatus]="true"
              [sendBy]="item.sendBy.id === currentUser.id"
              [showButton]="validateHasButton(item)"
              [normalizedInfo]="normalizeAskStatusMessage(item?.dare?.status,   item.sendBy.name,item?.sendTo.name,item?.shared,item?.rated)"
              [showConfirmMessage]="validateConfirmMessage(item?.dare?.status)"
              [showAmountChip]="!validateAmountChip(item?.dare?.status)"
              [message]="item"
              (executeAction)="executeAction($event.action, $event.data)"
              (onClickPlayVideo)="navigateToPlayer($event)"
              (onClickInternalLink)="navigateToProfile($event)"
            ></app-thumb-ask-video>

            <app-thumb-message
              *ngIf="!item?.type && (!item?.linkOptions?.onlyLink || !item?.linkOptions?.userWithLink)"
              [message]="item"
              [sendBy]="item.sendBy.id === currentUser.id"
              (onClickInternalLink)="navigateToProfile($event)"
            ></app-thumb-message>

            <app-thumb-user-with-url
              *ngIf="!item?.type && item?.linkOptions?.onlyLink && item?.linkOptions?.userWithLink"
              [message]="item"
              [sendBy]="item.sendBy.id === currentUser.id"
              (onClickInternalLink)="navigateToProfile($event)"
            >
            </app-thumb-user-with-url>

            <p class="message-timer"
               [ngClass]="{'message-timer-sended': item.sendBy.id === currentUser.id, 'message-timer-received': item.sendBy.id === user.id}">
          <span class="position-timer" *ngIf="!item.loading && !verifyEqualDate(i)">
            {{item.today ? (item.timestamp | date:'h:mm a') : ''}}
          </span>
              <span class="position-timer" *ngIf="!item.loading && !verifyEqualDate(i)">
            {{item.yesterday ? (item.timestamp | date:'MM/dd h:mm a') : ''}}
          </span>
              <span [ngStyle]="{visibility : item.loading ? 'visible' : 'hidden' }">
            <ion-icon style="font-size: 14px" name="time-outline"></ion-icon>
          </span>
            </p>
            <div class="center"
                 *ngIf="item.sendTo.id === currentUser.id && (item?.dare?.status === 'confirmed' || item?.dare?.status === 'autoconfirmed' ||  item?.dare?.status === 'trialconfirmwon')">
              <ion-chip class="ask-amount-chip"
                        style="background-color: #0095cf42; height: 60px; border-radius: 60px; padding: 40px;"
                        *ngIf="item?.dare?.amount > 0">
                <ion-label class="amount"
                           style="color:#0f163d;font-weight: 700;font-size: 14px;text-align: center;line-height: 24px;">
                  ${{ item?.dare?.amount }} ADDED TO YOUR BALANCE 🎉
                </ion-label>
              </ion-chip>
            </div>
            <div class="divider-message"></div>
          </div>
        </ion-list>
      </div>
    </div>
  </app-home-view>
</ion-content>

<div class="container-fab-chat" *ngIf="firstUnseenMessages.length">
  <div class="wrapper-fab-chat">
    <ion-fab (click)="scrollToFirstReceivedMessage()">
      <ion-badge color="warning">{{firstUnseenMessages?.length}}</ion-badge>
      <ion-fab-button>
        <ion-icon name="chevron-down-circle"></ion-icon>
      </ion-fab-button>
    </ion-fab>
  </div>
</div>

<div class="container-fab-chat" *ngIf="!firstUnseenMessages.length && unseenMessages.length">
  <div class="wrapper-fab-chat">
    <ion-fab (click)="scrollToReceivedMessage()">
      <ion-badge color="warning">{{unseenMessages?.length}}</ion-badge>
      <ion-fab-button>
        <ion-icon name="chevron-down-circle"></ion-icon>
      </ion-fab-button>
    </ion-fab>
  </div>
</div>
<ion-footer *ngIf="enableFooter" class="footer-input ion-no-border no-border">
  <div class="typing">{{labelTyping}}</div>
  <div class="ctrl-widgets-chat" *ngIf="this.userId !== 'truly-admin'">
    <app-icon-button
      *ngFor="let button of iconButtons; let i = index"
      [icon]="button.icon"
      [color]="button.color"
      [text]="button.name"
      (onClickButton)="onSelectOption($event)"
      style="margin-left: 10px;"></app-icon-button>
  </div>
  <div lines="none" *ngIf="!isBlocked" class="ctrl-footer" style="margin: 9px">
    <textarea class="input-textarea item-input-message" rows="1" #chatMessageInput (keyup)="controlTyping($event)"
              [(ngModel)]="message"
              placeholder="Type your message!"
              autosize></textarea>
    <ion-icon (click)="getFile()" class="icon-send-file"
              name="document-outline"></ion-icon>
    <div class="ctrl-plus" style="padding: 9px;" (click)="sendMessage()">
      <img alt="" class="icon-send" ngSrc="assets/icon/send.svg" height="20" width="17"/>
    </div>
  </div>
  <app-media-preview (onCancelMedia)="deletePreview()"
                     [urlFile]="filePreview"
                     [urlImage]="imagePreview?.url"
                     [urlVideo]="videoPreview?.url"></app-media-preview>
</ion-footer>

