import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-thumb-ask',
  templateUrl: './thumb-ask.component.html',
  styleUrls: ['./thumb-ask.component.scss'],
})
export class ThumbAskComponent implements OnInit {
  @Input()
  hasTopStatus: boolean;

  @Input()
  enableBlurText: boolean;

  @Input()
  sendBy: boolean;

  @Input()
  showButton: boolean;

  @Input()
  normalizedInfo: any;

  @Input()
  message: any;

  @Input()
  showConfirmMessage: boolean;

  @Input()
  showAmountChip: boolean;


  @Output()
  public executeAction = new EventEmitter();

  @Output()
  public onClickInternalLink = new EventEmitter();

  constructor() { }

  ngOnInit() {}

  execute(action,data){
    this.executeAction.emit({action,data});
  }

  onClickLink(event, message) {
    if(event?.target?.className === 'fake-link' && message?.linkOptions?.userWithLink?.id){
      this.onClickInternalLink.emit(message?.linkOptions?.userWithLink);
    }
  }

}
