<div class="container-star" *ngIf="mode === 'STARS'">
  <ion-icon class="star rated"
            *ngFor="let q of  this.quantity; let i = index"
            [name]="avaliation < (i+1) ? 'star-outline': 'star'"></ion-icon>
  <div class="review-info">{{ textAvaliation }}</div>
</div>

<div class="container-star" *ngIf="mode === 'TEXT'">
  <ion-icon class="star rated" [name]="'star'"></ion-icon>
  <div class="review-info">
    <span class="review-bold">{{ avaliation }} </span>
    from {{ textAvaliation }} Ratings
  </div>
</div>
