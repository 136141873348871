import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {

  collectionName = 'config';

  constructor(private firestore: AngularFirestore) {}

  getTopUsers(): any {
    return this.firestore.collection(this.collectionName).doc('top-users').ref.get();
  }

  getStoreVersionApp(): any {
    return this.firestore.collection(this.collectionName).doc('app-version').ref.get();
  }
}
