import {Injectable} from '@angular/core';
import {PlatformUtil} from '../utils/platform.util';
import {NavigationExtras, Router} from '@angular/router';
import {UserService} from '../services/user.service';
import {AppVersion} from '@awesome-cordova-plugins/app-version/ngx';
import {DefaultUtil} from '../utils/default.util';
import {ConfigService} from '../services/config.service';
import {Deeplinks} from '@awesome-cordova-plugins/deeplinks/ngx';

@Injectable({
  providedIn: 'root',
})

export class DeeplinkHelper {

  constructor(private platformUtil: PlatformUtil,
              private router: Router,
              private userService: UserService,
              private appVersion: AppVersion,
              private defaultUtils: DefaultUtil,
              private configService: ConfigService,
              private deeplinks: Deeplinks
  ) {}

   initDeeplink() {
    if (this.platformUtil.isCordova()) {
      this.deeplinks.route({}).subscribe(match => {
        console.log('match>>>>>>', match);
      }, nomatch => {
        console.log('nomatch>>>>>>>>', nomatch);
      });
    }
  }
}
