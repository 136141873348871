
export enum AnalyticsEvents  {
  /* ask events */
  acceptedAsk = 'accepted_ask',
  rejectedAsk = 'rejected_ask',
  confirmAsk = 'confirm_ask',
  confirmedAsk = 'confirmed_ask',
  trialAsk = 'trial_ask',
  cancelAsk = 'cancel_ask',
  expiredAsk = 'expired_ask',
  openCamToAsk = 'open_cam_to_ask',

  acceptedAdminAsk = 'accepted_admin_ask',
  confirmAdminAsk = 'confirm_admin_ask',
  openCamToAdminAsk = 'open_cam_to_admin_ask',
  expiredAdminAsk = 'expired_admin_ask',
  rejectedAdminAsk = 'rejected_admin_ask',
  cancelAskAdminAsk = 'cancel_admin_ask',

  /* core events */
  signIn = 'sign_in',
  signUp = 'sign_up',
  howLongOnlineFirstAccess = 'how_long_online_first_access',
  openEditProfile = 'open_edit_profile',
  openAdminChat = 'open_admin_chat',
  secondAccess = 'second_access',
  robertaPersonalLinkClicked = 'roberta_personal_link_clicked',

  /* purchase events */
  depositRequest = 'deposit_request', //
  withdrawRequest = 'withdraw_request', //
  purchase = 'purchase', // purchase credit
  purchaseAsk = 'purchase_ask'
};

