import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class NormalizeDataUtil {
  normalizeArrayData(data) {
    const newData = [];
    for (const item of data) {
      if (item.exists) {
        newData.push({
          center:false,
          id: item.id,
          ...item.data(),
        });
      }
    }

    return newData;
  }
}
