import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { MyProfilePageRoutingModule } from './my-profile-routing.module';

import { MyProfilePage } from './my-profile.page';
import {ProfilePageRoutingModule} from '../profile/profile-routing.module';
import {ComponentsModule} from '../../components/components.module';
import {HeaderProfileComponent} from '../../components/header-profile/header-profile.component';
import {ProfileInfoComponent} from '../../components/profile-info/profile-info.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    MyProfilePageRoutingModule,
    ProfilePageRoutingModule,
    ComponentsModule
  ],
  exports: [
    HeaderProfileComponent,
    ProfileInfoComponent,
  ],
    declarations: [MyProfilePage, HeaderProfileComponent, ProfileInfoComponent]
})
export class MyProfilePageModule {}
