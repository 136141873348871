<div *ngIf="!rated" class="container-star">
  <ion-icon class="star"  (click)="onChoice(i+1)" name="star-outline" *ngFor="let q of  this.quantity; let i = index"> </ion-icon>
</div>

<div *ngIf="rated" class="container-star">
  <ion-icon class="star rated"
            [name]="avaliation < (i+1) ? 'star-outline': 'star'"
            *ngFor="let q of  this.quantity; let i = index"> </ion-icon>
</div>

