import {Component, ViewChild} from '@angular/core';
import {User} from '../../models/user';
import {Tag} from '../../models/tag';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../services/user.service';
import {ActionSheetController, IonContent, LoadingController, NavController,} from '@ionic/angular';
import {FeedService} from '../../services/feed.service';
import {TagService} from '../../services/tag.service';
import {NormalizeDataUtil} from '../../utils/normalize-data.util';
import {DefaultUtil} from '../../utils/default.util';
import {ColorToast} from '../../enums/color-toast';
import {environment} from '../../../environments/environment';
import {PlatformUtil} from '../../utils/platform.util';
import {Feed} from '../../models/feed.model';
import {MediaService} from '../../services/media.service';
import {MediaUtil} from '../../utils/media.util';
import {PathStorage} from '../../enums/path-storage';
import {EmitEventService} from '../../services/emit-event.service';

declare let jwplayer;

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.page.html',
  styleUrls: ['./my-profile.page.scss'],
})
export class MyProfilePage {

  @ViewChild('contentMyProfile', {static: false}) contentMyProfile: IonContent;

  userId: string;
  user = {} as User;
  feed: Feed[] = [];
  feedToPaginate: Feed[] = [];
  tags: Tag[] = [];
  targets: any = [];
  chunkedtags: any = [];
  isLoading = true;
  ratingValue = '0.0';
  pagination = {start: 1, end: 10, limit: 10, enable: true};
  showFab = false;
  isMobile = false;
  constructor(
    private actRouter: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private navCtrl: NavController,
    private feedService: FeedService,
    private tagService: TagService,
    private normalizeDataUtil: NormalizeDataUtil,
    public defaultUtils: DefaultUtil,
    public actionSheetController: ActionSheetController,
    private platformUtil: PlatformUtil,
    private mediaService: MediaService,
    private mediaUtil: MediaUtil,
    private loadingCtrl: LoadingController,
    private emitEventService: EmitEventService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.isMobile = this.platformUtil.isMobile()
  }

  async ngOnInit() {
    setTimeout(() => {
      this.onNavigateBackMyProfile();
    }, 2000);
    this.onUpdateMyProfile();
    await this.defaultUtils.addJWPlayerScript();
    this.isLoading = true;
    await this.loadData();
    this.isLoading = false;
    this.defaultUtils?.callInfoOnboarding();
  }

  clearCachePage() {
    const noCache = this.activatedRoute.snapshot.data?.noCache;
    if (noCache) {
      // add cache-control header to HTTP response
      const headers = {'Cache-Control': 'no-cache, no-store, must-revalidate'};
      Object.entries(headers).forEach(([key, value]) => {
        document.querySelector('ion-header').setAttribute(key, value);
      });
    }
  }
  ngOnDestroy() {
    this.defaultUtils.removeJWPlayerScript();
    this.clearCachePage();
  }

  ionViewWillEnter() {
    this.defaultUtils.changeTabSelected.emit('my-profile');
    this.updateFeed();
  }

  async updateFeed() {
    const hasUpdateMyProfile = this.defaultUtils.storage().get('hasUpdateMyProfile');
    if (hasUpdateMyProfile) {
      this.isLoading = true;
      this.feed = [];
      this.feedToPaginate = [];
      this.pagination = {start: 1, end: 10, limit: 10, enable: true};
      await this.getFeeds();
      this.isLoading = false;
      this.defaultUtils.storage().delete('hasUpdateMyProfile');
    }
  }

  onNavigateBackMyProfile() {
    this.emitEventService.getObservableIonViewDidEnterMyProfile().subscribe(a => {
      this.updateFeed();
    });
  }

  onUpdateMyProfile() {
    this.emitEventService.getObservableUpdateMyprofile()
      .subscribe(async (data) => {
        const user = await this.defaultUtils.getUser();
        this.user.id = null;
        this.user.photoURL = null;
        await this.defaultUtils.timeout(100);
        this.user = user;
        await this.getTags();
      });
  }

  async goBack() {
    const fromOnboarding = this.defaultUtils.storage().get('fromOboarding');
    if (fromOnboarding) {
      this.defaultUtils.storage().delete('fromOboarding');
      this.navCtrl.navigateRoot('tabs/home', {animationDirection: 'back'});
    } else {
      this.navCtrl.back();
    }
  }

  goToReviewList() {
    this.router.navigate(['review-list'],{queryParams: {userId: this.user.id}});
  }

  async getCamera() {
    await this.defaultUtils.cameraComponent(
      () => this.getFeeds(),
      'Post a video to your profile.',
      null,
    );
  }

  async reuploadVideo({feed, index}) {
    await this.defaultUtils.cameraComponent(
      () => this.getFeeds(),
      'Re-upload',
      feed,
    );
  }

  async getTags() {
    this.tags = await this.loadTags(this.user.tags);
    this.tags = this.normalizeDataUtil.normalizeArrayData(this.tags);
    let quantityGroup = 3;
    if (this.tags.length < 15) {
      quantityGroup = 1;
    } else if (this.tags.length < 40) {
      quantityGroup = 2;
    } else {
      quantityGroup = 3;
    }
    this.chunkedtags = this.defaultUtils.chunkArray(this.tags, quantityGroup);
  }

  async getFeeds() {
    const data = {
      isPrivate: false,
      userId: this.user.id,
    };
    const feed: any = await this.feedService.getPublicFeed(
      data.userId,
      data.isPrivate
    );
    this.feed = [];
    this.feedToPaginate = [];
    this.feedToPaginate = feed.payload?.map(x => ({...x,} as Feed)) || [];
    this.feedToPaginate = this.sortVideos(this.feedToPaginate);
    this.setLocalPagination();
  }

  sortVideos(videos) {
    let pinnedVideos = videos;
    let allVideos = videos;
    allVideos = allVideos.filter(a => !a?.pinnedAt).map(a => {
      try {
        // eslint-disable-next-line no-underscore-dangle
        if (a?.createdAt._seconds && a.createdAt._nanoseconds) {
          // eslint-disable-next-line no-underscore-dangle
          a.createdAt = new Date(a.createdAt._seconds * 1000 + a.createdAt._nanoseconds / 1000000)?.getTime();
        } else {
          a.createdAt = new Date(a.createdAt?.toDate())?.getTime();
        }
        return a;
      } catch (e) {
        return a;
      }
    }).sort((a, b) => b.createdAt - a.createdAt);

    pinnedVideos = pinnedVideos.filter(a => a?.pinnedAt).map(a => {
      try {
        a.pinnedAt = new Date(a.pinnedAt?.toDate())?.getTime();
        return a;
      } catch (e) {
        return a;
      }
    }).sort((a, b) => b.pinnedAt - a.pinnedAt);
    return [...pinnedVideos, ...allVideos];
  }

  async loadData() {
    this.user = await this.defaultUtils.getUser();
    await Promise.all([
      await this.getTags(),
      await this.getFeeds(),
    ]);
    await this.defaultUtils.timeout(1000);
  }

  loadTags(tags) {
    const tagsTemp = [];
    for (const tag of tags) {
      const tagData = this.tagService.getById(tag);
      tagsTemp.push(tagData);
    }
    return Promise.all(tagsTemp);
  }

  getThumbnailByVideo(hasThumb, url) {
    if (hasThumb) {
      return url.toString().replace('.mp4', '_thumb.jpg');
    } else {
      return url.toString().replace('.mp4', '.jpg');
    }
  }

  async shareProfile() {
    const defaultUrl = environment.type === 'dev' ? 'jointruly.app' : 'jointruly.com';
    const shareData = {
      text: 'Ask me on Truly!\n',
      url: `https://${defaultUrl}/${this.user?.name}`,
    };
    await this.defaultUtils.share(shareData, null, null, this.user);
  }

  async presentActionSheet() {
    const actionSheet = await this.actionSheetController.create({
      header: 'Options',
      cssClass: 'my-custom-class',
      buttons: [
        {
          text: 'Share Profile',
          icon: 'share-social-outline',
          handler: () => {
            this.shareProfile();
          },
        },
        {
          text: 'Cancel',
          icon: 'close',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          },
        },
      ],
    });
    await actionSheet.present();

    const {role} = await actionSheet.onDidDismiss();
  }

  actionShareVideo(index) {
    this.feed[index].isPrivate = true;
    const post = this.feed[index];
    this.feedService.updatePostFeed({isPrivate: post.isPrivate}, post.id);
    this.defaultUtils.globalToast('Unshared. Please refresh.', ColorToast.primary);
  }

  goToEditProfile(scrollToTags) {
    if (scrollToTags) {
      this.defaultUtils.storage().set('scrollToTags', true);
    }
    this.router.navigate(['edit-profile']);
  }

  verifyAccount() {
    this.defaultUtils.verifyIdentity(window.location.href);
  }

  async editBackground() {
    let loading = null;
    let imageUrl = null;
    try {
      const image: any = await this.mediaUtil.cameraPicture();
      loading = await this.loadingCtrl.create({
        message: 'Saving image...',
      });
      loading.present();
      const user = await this.defaultUtils.getUser();
      console.log('editBackground user >>>>>>>>>', user);
      imageUrl = await this.uploadImage(`${PathStorage.profilePics}/${user?.id}_background.jpg`, image.blob);
      console.log('editBackground imageUrl >>>>>>>>>', imageUrl);
      await this.updateUser(user?.id, imageUrl);
      this.user = await this.defaultUtils.getUser();
    } catch (e) {
      console.log('editBackground error >>>>>>>>>', e);
      loading?.dismiss();
      await this.defaultUtils.globalToast(
        `An error has occured while uploading the photo:${e?.toString()}`,
        ColorToast.danger,
        5000,
      );
    } finally {
      loading?.dismiss();
    }
  }

  async uploadImage(name, file) {
    return await this.mediaService.uploadFileFirebase(name, file);
  }

  async updateUser(userId, photoBackground) {
    await this.userService.update(userId, {photoBackground});
  }

  setLocalPagination() {
    const feed = this.feedToPaginate?.filter((a, index) =>
      index >= this.pagination.start - 1 &&
      index <= this.pagination.end - 1);
    this.feed = [...this.feed, ...feed];
    this.pagination.enable = (this.feed?.length < this.feedToPaginate?.length);
  }

  ionInfinite(event) {
    this.pagination.start += this.pagination.limit;
    this.pagination.end += this.pagination.limit;
    setTimeout(() => {
      event?.target.complete();
      this.setLocalPagination();
    }, 1000);
  }

  scrollToTop() {
    this.contentMyProfile.scrollToTop(500);
  }

  onScroll(event) {
    if (event?.detail?.scrollTop > 1000) {
      this.showFab = true;
      return;
    }
    this.showFab = false;
  }

   modalGeneralSearch(tag) {
      this.router.navigate(['/trending'], {queryParams: {ref: tag.id}});
  }

}
