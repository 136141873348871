import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor() {}

  get accessToken() {
    return localStorage.getItem('access-token');
  }

  get userData() {
    return localStorage.getItem('current-user');
  }
}
